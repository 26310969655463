import Input from "../../presentational/Input";
import { useStoreState } from "easy-peasy";

function OEMListDropdown({ onSelect, includeAll = false, value, ...props }) {
	const {
		leap: { uploadType },
		file: { oemList },
	} = useStoreState((state) => state);

	const dropdownChoices = includeAll
		? [{ OEMID: "all", OEM: "All" }, ...oemList]
		: oemList;

	let defaultValue = "";
	if (includeAll && uploadType === "") {
		defaultValue = "All";
	} else if (value !== undefined) {
		defaultValue = value;
	} else {
		const foundItem = dropdownChoices.find((e) => e.OEMID == uploadType);
		defaultValue = foundItem ? foundItem.OEM : "";
	}

	return (
		<Input
			{...props}
			width="99%"
			label="OEM"
			placeholder="Enter OEM"
			value={defaultValue}
			onSelect={onSelect}
			required
			editable={true}
			dropdown
			dropdownChoices={dropdownChoices}
			selectedItem={(item) => item.OEM}
			rowTextForSelection={(item) => item.OEM}
		/>
	);
}

export default OEMListDropdown;
