import Col from "../../../presentational/Col";
import Row from "../../../presentational/Row";
import Input from "../../../presentational/Input";
import { useStoreActions, useStoreState } from "easy-peasy";
import Switch from "../../../presentational/Switch";
import Form from "../Form";
import OEMListDropdown from "../../Dropdown/OEMListDropdown";
import EngineFamilyDropdown from "../../Dropdown/EngineFamilyDropdown";

function ToolStatusForm({ buttons = [], ...props }) {
	const {
		file: { engineFamilyList, oemList },
		tool: { toolStatusInput },
	} = useStoreState((state) => state);

	const {
		setToolStatusInputAction,
		setUploadTypeAction,
		setLeapEngineModelAction,
		setUploadEngineModelAction,
	} = useStoreActions((actions) => actions);

	return (
		<Form {...props}>
			<Row>
				<Col xs={12} md={6} lg={6}>
					<OEMListDropdown
						value={
							toolStatusInput.OEMid !== "" &&
							oemList.find((e) => e.OEMID == toolStatusInput.OEMid)
								? oemList.find((e) => e.OEMID == toolStatusInput.OEMid).OEM
								: ""
						}
						onSelect={(name) => {
							setUploadTypeAction(name.OEMID);
							setToolStatusInputAction({ OEMid: name.OEMID });
						}}
					/>
				</Col>

				<Col xs={12} md={6} lg={6}>
					<EngineFamilyDropdown
						value={
							toolStatusInput.engineFamilyId !== "" &&
							engineFamilyList.find(
								(e) => e.EngineFamilyID == toolStatusInput.engineFamilyId
							)
								? engineFamilyList.find(
										(e) => e.EngineFamilyID == toolStatusInput.engineFamilyId
								  ).EngineFamily
								: ""
						}
						onSelect={(name) => {
							setLeapEngineModelAction(name.EngineFamily);
							setUploadEngineModelAction(name.EngineFamilyID);
							setToolStatusInputAction({ engineFamilyId: name.EngineFamilyID });
						}}
					/>
				</Col>
			</Row>
			<Row>
				<Col xs={12} md={6} lg={4}>
					<Input
						label="TS Code"
						placeholder="Enter Tool Status Code"
						onChangeText={(TSCode) => setToolStatusInputAction({ TSCode })}
						value={toolStatusInput.TSCode}
						required
					/>
				</Col>

				<Col xs={12} md={8}>
					<Input
						label="TS Description"
						placeholder="Enter Tool Status Description"
						onChangeText={(TSDescription) =>
							setToolStatusInputAction({ TSDescription })
						}
						value={toolStatusInput.TSDescription}
						required
					/>
				</Col>

				{buttons.map((button, index) => (
					<Col xs={12} md={12 / buttons.length} key={index}>
						{button}
					</Col>
				))}
			</Row>
		</Form>
	);
}

export default ToolStatusForm;
