import GroupedList from "./GroupedList";
import { useStoreActions, useStoreState } from "easy-peasy";
import { formatStringForComparison } from "../../../utilities/string";
import { useTableFilter } from "../../../hooks/filter";
import { useEffect, useState } from "react";
import Text from "../../presentational/Text";
import { TouchableOpacity } from "react-native";

function useTable(setToolModalVisible) {
	const firstPageNum = 1;

	const {
		list,
		pushNotificationPayload,
		listSearchInput,
		headers,
		currListPageNumber,
	} = useStoreState((state) => state.integration);

	const {
		removeToolFromIntegrationPayloadAction,
		addToolToIntegrationPayloadAction,
		updatePushNotificationHeaderAction,
		setCurrentListPageNumberAction,
	} = useStoreActions((actions) => actions);

	function reloadListByPageNumber(pageNumber) {
		setFilteredCopyList(filterList());
		setCurrentListPageNumberAction(pageNumber);
	}

	const { filteredList: sortedList, ...tableProps } = useTableFilter({
		headers,
		list: list.map((pn) => ({ ...pn, numofpages: totalPageNum })),
		updateHeaderFunction: updatePushNotificationHeaderAction,
		reloadListByPageNumberFunction: reloadListByPageNumber,
		currPageNumber: currListPageNumber,
		setPageNumberFunction: setCurrentListPageNumberAction,
	});

	const [filteredCopyList, setFilteredCopyList] = useState(sortedList.slice());
	const [isFiltering, setIsFiltering] = useState(false);

	const pageSize = 9;
	const totalPageNum = Math.ceil(filteredCopyList.length / pageSize) || 1;

	function filterList() {
		return sortedList
			.filter((pn) =>
				headers
					.flatMap((header) => header.subheaders || header)
					.every((header) => {
						const value = (pn[header.propertyName] || "").toLowerCase();

						if (header.isCheckingEqual) {
							return value === header.filterValue.toLowerCase();
						} else if (header.isCheckingContains) {
							return value.includes(header.filterValue.toLowerCase());
						}

						return true;
					})
			)
			.slice();
	}

	useEffect(() => {
		if (isFiltering) {
			setFilteredCopyList(filterList());
			setIsFiltering(false);
		}
	}, [isFiltering]);

	useEffect(() => {
		setFilteredCopyList(filterList());
	}, [
		JSON.stringify(
			headers
				.flatMap((header) => header.subheaders || header)
				.map((header) => ({
					isSortAscending: header.isSortAscending,
					isSortDescending: header.isSortDescending,
					isCheckingEqual: header.isCheckingEqual,
					isCheckingContains: header.isCheckingContains,
				}))
		),
		list,
	]);

	function isSelected(item) {
		return (
			pushNotificationPayload &&
			pushNotificationPayload.integrationMessages.find(
				(pn) => pn.partNumber === item.partNumber
			)
		);
	}

	return {
		list: filteredCopyList
			.filter(
				(pn) =>
					formatStringForComparison(pn.partNumber).includes(
						formatStringForComparison(listSearchInput)
					) ||
					formatStringForComparison(pn.partDescription).includes(
						formatStringForComparison(listSearchInput)
					) ||
					formatStringForComparison(pn.statusCode).includes(
						formatStringForComparison(listSearchInput)
					)
			)
			.slice(
				0 + currListPageNumber * pageSize - pageSize,
				0 + currListPageNumber * pageSize
			)
			.map((item) => ({
				...item,
				partNumber: (
					<TouchableOpacity
						onPress={() => setToolModalVisible(item.partNumber)}
					>
						<Text style={{ fontWeight: "bold" }}>{item.partNumber}</Text>
					</TouchableOpacity>
				),
				checked: isSelected(item),
				numofpages: totalPageNum,
			})),
		...tableProps,
		totalPageNum,
		onPressOk: (evt) => {
			setIsFiltering(true);
			tableProps.filterEvents.onPressOk(evt);
		},
		onPressLastPage: () => reloadListByPageNumber(totalPageNum),
		onChangePageNum: (text) => {
			const currPageNum = Math.min(
				parseInt(text) || firstPageNum,
				totalPageNum
			);
			setCurrentListPageNumberAction(Math.max(firstPageNum, currPageNum));
		},
		onPressCheckbox: (pn) => () => {
			if (isSelected(pn)) {
				removeToolFromIntegrationPayloadAction(pn);
			} else {
				addToolToIntegrationPayloadAction(pn);
			}
		},
	};
}

function PushNotificationList({ setToolModalVisible, ...props }) {
	const listProps = useTable(setToolModalVisible);
	return <GroupedList {...listProps} {...props} />;
}

export default PushNotificationList;
