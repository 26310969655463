import { useEffect } from "react";
import Form from "../../containers/Form";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useIsFocused } from "@react-navigation/native";
import styled from "styled-components/native";
import { vhToDp, vwToDp } from "../../../utilities/responsive";
import Page from "../../containers/Page";
import Button from "../../presentational/Button";

const Container = styled.View`
	padding: ${vhToDp(2)}px ${vwToDp(4)}px;
`;

function AddEngineVariantPage({ navigation, ...props }) {
	const { upsertEngineVariantInput } = useStoreState((state) => state.file);
	const { upsertEngineVariantThunk, setUpsertEngineVariantInputAction } =
		useStoreActions((actions) => actions);

	const isFocused = useIsFocused();
	useEffect(() => {
		if (isFocused) {
			setUpsertEngineVariantInputAction({
				// id: null,
				enginevariant: "",
			});
		}
	}, [isFocused]);

	return (
		<Page.Protected>
			<Container>
				<Form.AddEngineVariant
					buttons={[
						<Button
							disabled={upsertEngineVariantInput.enginevariant === ""}
							fullWidth
							onPress={async () => {
								await upsertEngineVariantThunk({ navigation });
								navigation.goBack();
							}}
						>
							Add Engine Variant
						</Button>,
					]}
					{...props}
				/>
			</Container>
		</Page.Protected>
	);
}

export default AddEngineVariantPage;
