import { useStoreActions, useStoreState } from "easy-peasy";
import List from "../../presentational/List";
import { FontAwesome5, Ionicons } from "@expo/vector-icons";
import Icon from "../../presentational/Icon";
import Card from "../../presentational/Card";
import { useNavigation } from "@react-navigation/native";
import { formatStringForComparison } from "../../../utilities/string";
import Text from "../../presentational/Text";
import Td from "../../presentational/Table/Td";
import { View } from "react-native";
import { CheckBox } from "@rneui/themed";
import { remToDp } from "../../../utilities/responsive";
import GroupedList from "../../presentational/GroupedList";
import ToolDeltaGroupedList from "../../presentational/ToolDeltaGroupedList";

function ToolDeltaList(props) {
	const {
		deltaList,
		deltaListSearchInput,
		searchInput,
		configFilter,
		reviewList,
		groupedList,
		chosenColumnToSortIndexDelta,
		filterDeltaValues,
		chosenDeltaComparisonIndexes,
		isComparingColumnFlagsDelta,
	} = useStoreState((state) => state.tool);
	const {
		addToolToReviewListAction,
		removeToolFromReviewListAction,
		setChosenColumnToSortIndexDeltaAction,
		setChosenDeltaComparisonIndexesAction,
		setFilterDeltaValuesAction,
		setIsComparingColumnFlagsDeltaAction,
	} = useStoreActions((actions) => actions);
	return (
		<Card>
			<ToolDeltaGroupedList
				chosenComparisonIndexes={chosenDeltaComparisonIndexes}
				setChosenComparisonIndexes={setChosenDeltaComparisonIndexesAction}
				filterValues={filterDeltaValues}
				setFilterValues={setFilterDeltaValuesAction}
				setChosenColumnToSortIndex={setChosenColumnToSortIndexDeltaAction}
				chosenColumnToSortIndex={chosenColumnToSortIndexDelta}
				isComparingColumnFlags={isComparingColumnFlagsDelta}
				setIsComparingColumnFlags={setIsComparingColumnFlagsDeltaAction}
				pageSize={6}
				width={["14%", "16%", "15%", "33%", "9%", "10%", "4%"]}
				tableHeaders={[
					"Part Number",
					"Record Type",
					"Engine",
					"Part Description",
					"Status",
					"Replaced By",
					"Select",
				]}
				headers={[
					{ columnName: "Part Number", propertyName: "partNumber" },
					{ columnName: "Record Type", propertyName: "RecordType" },
					{ columnName: "Engine", propertyName: "ENGINE" },
					{ columnName: "Part Description", propertyName: "partDescription" },
					{ columnName: "Status", propertyName: "PartStatusCode" },
					{ columnName: "Replaced By", propertyName: "ReplacedBy" },
					{ columnName: "Select", propertyName: "Select" },
				]}
				list={groupedList.filter((user) =>
					formatStringForComparison(user.partNumber).includes(
						formatStringForComparison(deltaListSearchInput)
					)
				)}
				tableRows={deltaList.map((item) => [
					item.RecordType == "MASTER RECORD"
						? "MASTER"
						: item.RecordType == "NEW RECORD"
						? "NEW"
						: item.RecordType,

					item.ENGINE,
					item.partNumber,
					item.partDescription,
					item.PartStatusCode,
					item.ReplacedBy,
					item.LastRevision,
					<View style={{ flex: 1, flexDirection: "row", bottom: 10 }}>
						<CheckBox
							checked={reviewList && reviewList.includes(item)}
							checkedColor="#007AFF"
							onPress={() => {
								if (reviewList && reviewList.includes(item)) {
									removeToolFromReviewListAction(item);
								} else {
									addToolToReviewListAction(item);
								}
							}}
							checkedIcon={
								<FontAwesome5
									name="check-square"
									size={remToDp(1.5)}
									color="#007AFF"
								/>
							}
							iconType="material-community"
							uncheckedIcon={"checkbox-blank-outline"}
							containerStyle={{ backgroundColor: "none" }}
						/>
					</View>,
				])}
				{...props}
			/>
		</Card>
	);
}

export default ToolDeltaList;
