import { axios } from '.';

const roleAxios = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}/role`,
});

function getRoleApiCall(id) {
  return roleAxios({ method: 'get', url: `/${id}` });
}

function upsertRoleApiCall(data) {
  return roleAxios({ method: 'put', url: '/upsert', data });
}

export { roleAxios, getRoleApiCall, upsertRoleApiCall };
