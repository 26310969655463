import { useStoreActions, useStoreState } from "easy-peasy";
import List from "../../presentational/List";
import { FontAwesome5 } from "@expo/vector-icons";
import Icon from "../../presentational/Icon";
import Card from "../../presentational/Card";
import { useNavigation } from "@react-navigation/native";
import { formatStringForComparison } from "../../../utilities/string";
import { remToDp } from "../../../utilities/responsive";
import { CheckBox } from "@rneui/themed";
import { View } from "react-native";
import PaginatedList from "../../presentational/PaginatedList";

function OEMToEngineFamilyList(props) {
	const {
		oemToEngineFamilyList,
		oemToEngineFamilySearchInput,

		isComparingColumnFlagsOemEngine,
		chosenColumnToSortIndexOemEngine,
		filterOemEngineValues,
		chosenOemEngineComparisonIndexes,
	} = useStoreState((state) => state.file);
	const {
		setUpsertOEMInputAction,
		setOEMToFamilyInputAction,

		setIsComparingColumnFlagsOemEngineAction,
		setChosenColumnToSortIndexOemEngineAction,
		setFilterOemEngineValuesAction,
		setChosenOemEngineComparisonIndexesAction,
	} = useStoreActions((actions) => actions);
	return (
		<Card>
			<PaginatedList
				chosenComparisonIndexes={chosenOemEngineComparisonIndexes}
				setChosenComparisonIndexes={setChosenOemEngineComparisonIndexesAction}
				filterValues={filterOemEngineValues}
				setFilterValues={setFilterOemEngineValuesAction}
				setChosenColumnToSortIndex={setChosenColumnToSortIndexOemEngineAction}
				chosenColumnToSortIndex={chosenColumnToSortIndexOemEngine}
				isComparingColumnFlags={isComparingColumnFlagsOemEngine}
				setIsComparingColumnFlags={setIsComparingColumnFlagsOemEngineAction}
				headers={[
					{ columnName: "OEM", propertyName: "oem" },
					{ columnName: "OEM Description", propertyName: "oemdescription" },
					{ columnName: "Engine Family", propertyName: "EngineFamily" },
					{ columnName: "Engine Variant", propertyName: "EngineVariant" },
					{ columnName: "Military Flag", propertyName: "ismilitaryFlag" },
					{ columnName: "Edit", propertyName: "oem" },
				]}
				list={oemToEngineFamilyList
					.filter(
						(user) =>
							formatStringForComparison(user.EngineFamily).includes(
								formatStringForComparison(oemToEngineFamilySearchInput)
							) ||
							formatStringForComparison(user.EngineVariant).includes(
								formatStringForComparison(oemToEngineFamilySearchInput)
							) ||
							formatStringForComparison(user.oem).includes(
								formatStringForComparison(oemToEngineFamilySearchInput)
							)
					)
					.map((role) => ({
						oem: role.oem,
						oemdescription: role.oemdescription,
						EngineFamily: role.EngineFamily,
						EngineVariant: role.EngineVariant,
						ismilitaryFlag: (
							<View style={{ flex: 1, flexDirection: "row" }}>
								<CheckBox
									checked={role.ismilitaryFlag}
									checkedColor="#007AFF"
									checkedIcon={
										<FontAwesome5
											name="check-square"
											size={remToDp(1.5)}
											color="#007AFF"
										/>
									}
									iconType="material-community"
									uncheckedIcon={"checkbox-blank-outline"}
									containerStyle={{ backgroundColor: "none" }}
								/>
							</View>
						),
						edit: (
							<Icon
								Component={FontAwesome5}
								name="edit"
								onPress={() => {
									setUpsertOEMInputAction({
										OEMid: role.OEMID,
										OEM: role.oem,
										OEMDescription: role.oemdescription,
									});

									setOEMToFamilyInputAction({
										id: role.id,
										oemid: role.OEMID,
										enginefamilyid: role.EngineFamilyID,
									});

									props.navigation.navigate("Edit OEM to Engine Family");
								}}
							/>
						),
					}))}
				{...props}
			/>
		</Card>
	);
}

export default OEMToEngineFamilyList;
