const defaultState = {
	list: [],
	searchInput: "",
	input: {
		id: 0,
		name: "",
		description: "",
	},
	allowEdit: false,

	isComparingColumnFlagsRole: [false, false],
	chosenColumnToSortIndexRole: -1,
	filteredRoleList: [],
	filterRoleValues: ["", "", ""],
	chosenRoleComparisonIndexes: [-1, -1, -1],
};

const state = {
	role: defaultState,
};

export { state as default, defaultState };
