import { Modal } from "@cfbs/cfbsstrap-native";
import { useGetTenantInfoByPartNumberModal } from "../../../hooks/tenant-info";
import TenantInfoByPartNumberList from "../List/TenantInfoByPartNumberList";
import Text from "../../presentational/Text";

function TenantInfoByPartNumberModal(props) {
	const { title, list, ...modalProps } = useGetTenantInfoByPartNumberModal(
		props.partNumber
	);

	return (
		<Modal {...modalProps} {...props}>
			<Modal.Header closeButton>{title}</Modal.Header>
			<Modal.Body>
				{list.length ? (
					<TenantInfoByPartNumberList />
				) : (
					<Text
						style={{
							textAlign: "center",
							marginTop: 20,
							fontSize: 16,
						}}
					>
						No tenants found with part number: {props.partNumber}
					</Text>
				)}
			</Modal.Body>
		</Modal>
	);
}

export default TenantInfoByPartNumberModal;
