import { useEffect } from "react";
import Form from "../../containers/Form";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useIsFocused } from "@react-navigation/native";
import styled from "styled-components/native";
import { vhToDp, vwToDp } from "../../../utilities/responsive";
import Page from "../../containers/Page";
import Button from "../../presentational/Button";

const Container = styled.View`
	padding: ${vhToDp(2)}px ${vwToDp(4)}px;
`;

function AddEngineFamilyPage({ navigation, ...props }) {
	const { upsertEngineFamilyInput } = useStoreState((state) => state.file);
	const { upsertEngineFamilyThunk, setUpsertEngineFamilyInputAction } =
		useStoreActions((actions) => actions);

	const isFocused = useIsFocused();

	useEffect(() => {
		if (isFocused) {
			setUpsertEngineFamilyInputAction({
				id: null,
				enginefamilydesc: "",
			});
		}
	}, [isFocused]);

	return (
		<Page.Protected>
			<Container>
				<Form.AddEngineFamily
					buttons={[
						<Button
							disabled={upsertEngineFamilyInput.enginefamilydesc === ""}
							fullWidth
							onPress={async () => {
								await upsertEngineFamilyThunk({ navigation });
								navigation.goBack();
							}}
						>
							Add Engine Family
						</Button>,
					]}
					{...props}
				/>
			</Container>
		</Page.Protected>
	);
}

export default AddEngineFamilyPage;
