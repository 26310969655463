import moment from "moment";

import React, { useEffect, useRef, useState } from "react";
import {
	StyleSheet,
	ScrollView,
	TouchableOpacity,
	Platform,
} from "react-native";
import { AntDesign } from "@expo/vector-icons";
import styled from "styled-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { Divider } from "@rneui/themed";
import * as DocumentPicker from "expo-document-picker";

import { xorBy } from "lodash";

import Card from "../../../presentational/Card";
import Text from "../../../presentational/Text";

import Gap from "../../../presentational/Gap";

import { remToDp } from "../../../../utilities/responsive";
import Col from "../../../presentational/Col";
import Row from "../../../presentational/Row";
import Input from "../../../presentational/Input";
import Button from "../../../presentational/Button";
import CombinedInputDropdown from "../../../presentational/SelectInputField";
import { View } from "react-native";
import { useIsFocused, useNavigation } from "@react-navigation/native";

const ColSwitch = styled(Col)`
	padding-left: ${remToDp(1.5)}px;
`;

const EditOEMToFamilyStepForm = ({ ...props }) => {
	const navigation = useNavigation();
	const [currentStep, setCurrentStep] = useState(1);
	const ref = useRef(null);

	const {
		engineFamilyList,
		engineVariantList,
		oemToEngineFamilyInput,
		upsertOEMInput,
		oemToFamilyEditCurrentStep,
		upsertOEMToFamilyInput,
		updateOEMToEngineFamilyInput,
	} = useStoreState((state) => state.file);

	const {
		upsertToolsThunk,
		upsertExtraDocumentThunk,
		setOEMToEngineFamilyInputAction,
		setUpsertOEMInputAction,
		setEditOemToFamilyCurrentStepAction,
		upsertFileOEMThunk,
		setOEMToFamilyInputAction,
		upsertOEMToFamilyThunk,
		upsertEditFileOEMThunk,
	} = useStoreActions((actions) => actions);

	const isFocused = useIsFocused();

	const handleNextStep = () => {
		setEditOemToFamilyCurrentStepAction(oemToFamilyEditCurrentStep + 1);
	};

	const handlePreviousStep = () => {
		setEditOemToFamilyCurrentStepAction(oemToFamilyEditCurrentStep - 1);
	};

	const renderFormStep = () => {
		switch (oemToFamilyEditCurrentStep) {
			case 1:
				return (
					<>
						<Row>
							<Col xs={12} md={6}>
								<Input
									label="OEM Name"
									onChangeText={(OEM) => setUpsertOEMInputAction({ OEM })}
									value={upsertOEMInput.OEM}
									required
									placeholder="Type OEM Name"
								/>
							</Col>

							<Col xs={12} md={6}>
								<Input
									label="OEM Description"
									onChangeText={(OEMDescription) =>
										setUpsertOEMInputAction({ OEMDescription })
									}
									required
									value={upsertOEMInput.OEMDescription}
									placeholder="Type OEM Description"
								/>
							</Col>
						</Row>
						<Button
							disabled={false}
							fullWidth
							title="Next Step"
							onPress={() => {
								upsertEditFileOEMThunk();
							}}
						/>
					</>
				);

			case 2:
				return (
					<>
						<Row>
							<Col xs={12} md={8}>
								<Input
									label={"Engine Family"}
									dropdownChoices={engineFamilyList}
									onSelect={(value) =>
										setOEMToFamilyInputAction({
											enginefamilyid: value.EngineFamilyID,
										})
									}
									dropdown
									// use engineFamilyList and the id to get the name
									// and display it in the input
									defaultButtonText={
										engineFamilyList.find(
											(item) =>
												item.EngineFamilyID ==
												upsertOEMToFamilyInput.enginefamilyid
										)?.EngineFamily
									}
									// defaultButtonText={upsertOEMToFamilyInput.enginefamilyid}
									selectedItem={(item) => item.EngineFamily}
									value={
										// engineFamilyList.find(
										// 	(item) =>
										// 		item.EngineFamilyID ==
										// 		upsertOEMToFamilyInput.enginefamilyid
										// )?.EngineFamily
										engineFamilyList.find(
											(item) =>
												item.EngineFamilyID ==
												upsertOEMToFamilyInput.enginefamilyid
										).EngineFamily
									}
									rowTextForSelection={(item) => item.EngineFamily}
									search
								/>
							</Col>

							<Col xs={12} md={4}>
								<Button
									title="Add New Family"
									fullWidth
									color="#0088CE"
									onPress={() => {
										navigation.push("Add Engine Family");
									}}
								/>
							</Col>
						</Row>
						<Row>
							<Col>
								<Button fullWidth title="Next Step" onPress={handleNextStep} />
							</Col>
						</Row>
					</>
				);
			case 3:
				return (
					<>
						<Row>
							<Col xs={12} md={8}>
								<Input
									placeholder={`Select Engine Variant`}
									label={"Engine Variant"}
									dropdownChoices={engineVariantList}
									onSelect={(value) =>
										setOEMToEngineFamilyInputAction({
											engineVariant: value.EngineVariant,
										})
									}
									//
									dropdown
									selectedItem={(item) => item.EngineVariant}
									value={updateOEMToEngineFamilyInput.engineVariant}
									rowTextForSelection={(item) => item.EngineVariant}
									search
								/>
							</Col>

							<Col xs={12} md={4}>
								<Button
									title="Add New Variant"
									fullWidth
									color="#0088CE"
									onPress={() => {
										navigation.push("Add Engine Variant");
									}}
								/>
							</Col>
						</Row>
						<Row>
							<Col>
								<Button
									fullWidth
									title="Save"
									onPress={() => {
										upsertOEMToFamilyThunk(navigation);
										setEditOemToFamilyCurrentStepAction(1);
									}}
								/>
							</Col>
						</Row>
					</>
				);
		}
	};

	const renderStepIndicators = () => {
		const steps = ["Name", "Engine Family", "Engine Variant"];

		return steps.map((step, index) => (
			<View
				key={index}
				onPress={() => setEditOemToFamilyCurrentStepAction(index + 1)}
				style={[
					styles.stepIndicator,
					oemToFamilyEditCurrentStep === index + 1
						? styles.currentStepIndicator
						: oemToFamilyEditCurrentStep > index + 1 && {
								backgroundColor: "#cfd2d4",
						  },
					,
				]}
			>
				<Text
					key={index}
					style={[
						styles.stepIndicator,
						oemToFamilyEditCurrentStep === index + 1 &&
							styles.currentStepIndicator,
					]}
				>
					{step}
				</Text>
				{oemToFamilyEditCurrentStep > index + 1 && (
					<AntDesign
						name="check"
						size={20}
						color="green"
						style={{
							alignSelf: "center",
						}}
					/>
				)}
			</View>
		));
	};

	const handleSubmit = () => {
		extraDocument &&
			extraDocument.body !== "" &&
			upsertExtraDocumentThunk({
				navigation: props.navigation,
				document,
				message: "Document Upserted",
			});

		upsertToolsThunk({
			navigation: props.navigation,
			oemToEngineFamilyInput,
			message: "Tool created successfully!",
		});
	};

	return (
		<Card style={styles.container}>
			<ScrollView
				horizontal
				contentContainerStyle={styles.stepIndicatorsContainer}
			>
				{renderStepIndicators()}
			</ScrollView>
			<Divider />
			<Gap />
			{renderFormStep()}
		</Card>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		padding: 16,
	},
	stepIndicatorsContainer: {
		flexDirection: "row",
		alignSelf: "center",
		marginBottom: 16,
		width: "100%",
	},
	stepIndicator: {
		fontFamily: "Barlow_600SemiBold",
		flex: 1,
		fontSize: 18,
		textAlign: "center",
		paddingHorizontal: 8,
		paddingVertical: 4,
		borderRadius: 8,
		marginRight: 8,
		flexDirection: "row",
	},
	currentStepIndicator: {
		backgroundColor: "#e8e9ea",
	},
	stepText: {
		fontSize: 20,
		marginBottom: 16,
	},
});

export default EditOEMToFamilyStepForm;
