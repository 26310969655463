import Page from "../../containers/Page";
import React, { useEffect } from "react";

import { View } from "react-native";
import styled from "styled-components/native";
import { useIsFocused, useNavigation } from "@react-navigation/native";
import { useStoreActions, useStoreState } from "easy-peasy";
import Row from "../../presentational/Row";
import Gap from "../../presentational/Gap";
import SearchBar from "../../presentational/SearchBar";
import Col from "../../presentational/Col";
import ToolStatusList from "../../containers/List/ToolStatusList";
import Button from "../../presentational/Button";

const Container = styled(View)`
	padding: 2vh 4vw;
`;

const SRow = styled(Row)`
	align-items: center;
`;

function ToolStatusListPage(props) {
	const { statusListSearchInput } = useStoreState((state) => state.tool);
	const { getToolStatusListThunk, setToolStatusListSearchInputAction } =
		useStoreActions((actions) => actions);
	const isFocused = useIsFocused();
	const navigation = useNavigation();

	useEffect(() => {
		if (isFocused) {
			getToolStatusListThunk();
		}
	}, [isFocused]);

	return (
		<Page.Protected {...props}>
			<Container>
				<SRow>
					<Col xs={12} md={4}>
						<Button
							fullWidth
							onPress={() => {
								navigation.push("Add Tool Status");
							}}
						>
							Add Status
						</Button>
					</Col>

					<Col xs={12} md={8}>
						<SearchBar
							onChangeText={(searchInput) => {
								setToolStatusListSearchInputAction(searchInput);
							}}
							value={statusListSearchInput}
							placeholder="Search status by name"
						/>
					</Col>
				</SRow>
				<Gap />
				<ToolStatusList />
				<Gap />
			</Container>
		</Page.Protected>
	);
}

export default ToolStatusListPage;
