import { useStoreActions } from "easy-peasy";
import Button from "../../../presentational/Button";

function LogoutButton({ children = "Logout", navigation, ...props }) {
  const { logoutThunk } = useStoreActions((actions) => actions);
  return (
    <Button onPress={() => logoutThunk(navigation)} fullWidth {...props}>
      {children}
    </Button>
  );
}

export default LogoutButton;
