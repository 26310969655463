import { useStoreActions, useStoreState } from "easy-peasy";
import { formatStringForComparison } from "../utilities/string";
import { useIsFocused, useNavigation } from "@react-navigation/native";
import { useTableFilter } from "./filter";
import { useEffect } from "react";
import { removeDuplicateChoices } from "../utilities/input";

function useMasterGroupedToolTable() {
  const {
    tool: {
      masterToolHeaders,
      masterToolGroupedList,
      masterListSearchInput,
      masterToolGroupedPage,
    },
    file: { engineVariantList },
    user: { authenticatedUser },
  } = useStoreState((state) => state);

  const navigation = useNavigation();

  const {
    updateMasterToolHeaderAction,
    getMasterToolListThunk,
    setMasterListGroupedPageAction,
    setSelectedMasterToolAction,
    setMasterToolInputAction,
    setSelectedEngineVariantsAction,
    setMasterToolEnginesToRemoveAction,
  } = useStoreActions((actions) => actions);

  function reloadListByPageNumber(pageNumber) {
    getMasterToolListThunk({ PageNumber: pageNumber });
    setMasterListGroupedPageAction(pageNumber);
  }

  const { filteredList, ...tableProps } = useTableFilter({
    headers: masterToolHeaders,
    list: masterToolGroupedList,
    updateHeaderFunction: updateMasterToolHeaderAction,
    reloadListByPageNumberFunction: reloadListByPageNumber,
    currPageNumber: masterToolGroupedPage,
    setPageNumberFunction: setMasterListGroupedPageAction,
  });

  return {
    list: filteredList.filter((tool) =>
      formatStringForComparison(tool.partNumber).includes(
        formatStringForComparison(masterListSearchInput)
      )
    ),
    ...tableProps,
    onPressEdit:
      authenticatedUser.role !== "user"
        ? (item) => () => {
            setSelectedMasterToolAction(item);
            setMasterToolInputAction({
              id: item.id,
              OEMID: item.OEMID,
              EngineFamilyID: item.EngineFamilyID,
              partNumber: item.partNumber,
              partDescription: item.partDescription,
              TSCode: item.TSCode || "",
              QualifiedRevision: item.QualifiedRevision || "",
              SupersededReplaceBy: item.SupersededReplacedby || "",
              FandFCode: item.FandFCode || "",
              LastRevision: item.LastRevision || "",
              Comments: item.Comments || "",
              ServiceBulletin: item.ServiceBulletin || "",
              internalComments: item.internalComments || "",
              EngineVariant: item.EngineVariant || "",
              overrideTSPartNumber: item.overridePartNumber || "",
              overrideTSCode: item.overrideTSCode || "",
              basepartNumber: item.basepartNumber || "",
            });
            setMasterToolEnginesToRemoveAction([]);

            if (item.ENGINE) {
              const variantIds = item.ENGINE.split(",");

              const selectedVariants = engineVariantList
                .filter((item) =>
                  variantIds.includes(item.EngineVariant.toString())
                )
                .map((item) => ({
                  item: item.EngineVariant,
                  id: item.EngineVariantID,
                }));

              setSelectedEngineVariantsAction(selectedVariants);
            }

            navigation.navigate("Edit Master Tool");
          }
        : null,
  };
}

function useMasterToolListControlForm(navigation) {
  const {
    leap: { uploadEngineModel, uploadType },
    user: { accessControlList },
    tool: { masterListSearchInput },
  } = useStoreState((state) => state);

  const {
    getOEMToEngineFamilyListThunk,
    setUploadEngineModelAction,
    setUploadTypeAction,
    setMasterListGroupedPageAction,
    getMasterToolListThunk,
    setMasterToolListSearchInputAction,
    setEnteredMasterToolListSearchInputAction,
    setToolConfigFilterAction,
  } = useStoreActions((actions) => actions);

  const isFocused = useIsFocused();

  const defaultItem = "All";

  useEffect(() => {
    isFocused && getOEMToEngineFamilyListThunk();
  }, [isFocused]);

  function onSelect(callback) {
    return (option) => {
      callback(option);
      setMasterListGroupedPageAction(1);
      getMasterToolListThunk({ PageNumber: 1 });
    };
  }

  function value(input) {
    return input || defaultItem;
  }

  return {
    engineModelInput: {
      label: "Engine Model",
      onSelect: onSelect((option) => {
        setUploadEngineModelAction(option.EngineFamilyID);
        setToolConfigFilterAction("");
        setUploadTypeAction("");
      }),
      value: value(
        accessControlList.find(
          (map) => map.EngineFamilyID === uploadEngineModel
        )?.EngineFamily
      ),
      dropdownChoices: [
        { EngineFamilyID: "", EngineFamily: "All" },
        ...removeDuplicateChoices(accessControlList, "EngineFamily"),
      ],
      selectedItem: (option) => option.EngineFamily,
      rowTextForSelection: (option) => option.EngineFamily,
    },
    oemInput: {
      label: "OEM",
      placeholder: "Enter OEM",
      onSelect: onSelect((option) => setUploadTypeAction(option.OEMID)),
      value: value(
        accessControlList.find((map) => map.OEMID === uploadType)?.oem
      ),
      dropdownChoices: [
        { OEMID: "", oem: "All" },
        ...removeDuplicateChoices(
          accessControlList.filter(
            (map) =>
              !uploadEngineModel || map.EngineFamilyID === uploadEngineModel
          ),
          "oem"
        ),
      ],
      selectedItem: (option) => option.oem,
      rowTextForSelection: (option) => option.oem,
    },
    button: {
      fullWidth: true,
      onPress: () => navigation.navigate("Add Master Tool"),
      children: "Add Tool",
    },
    searchBar: {
      onChangeText: (searchInput) =>
        setMasterToolListSearchInputAction(searchInput),
      value: masterListSearchInput,
      placeholder: "Search tools by part number",
      onSubmitEditing: () => {
        setEnteredMasterToolListSearchInputAction(masterListSearchInput);
        setMasterListGroupedPageAction(1);
        getMasterToolListThunk({
          PageNumber: 1,
          searchpartnumber: masterListSearchInput,
        });
      },
    },
    required: true,
    dropdown: true,
    editable: true,
    width: "99%",
  };
}

export { useMasterGroupedToolTable, useMasterToolListControlForm };
