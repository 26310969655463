import { thunk } from "easy-peasy";
import { loginApiCall, logoutApiCall } from "../../api/auth";
import { removeAuthToken, setAuthToken } from "../../utilities/api";

const thunks = {
  loginThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);

    try {
      const response = await loginApiCall(helpers.getState().auth.loginInput);
      await setAuthToken(response.data);
      await actions.getAuthenticatedUserThunk();
      actions.getUsersThunk();
    } catch (err) {
      actions.setAlertThunk({
        type: "error",
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
  logoutThunk: thunk(async (actions, navigation) => {
    await logoutApiCall();
    await removeAuthToken();
    actions.resetAuthenticatedUserAction();
    actions.resetLoginInputAction();
    navigation.closeDrawer();
  }),
};

export default thunks;
