import { action } from "easy-peasy";
import { defaultState } from "./state";

const actions = {
  setAuthenticatedUserAction: action((state, user) => {
    state.user.authenticatedUser = {
      ...state.user.authenticatedUser,
      ...user,
    };
  }),
  resetAuthenticatedUserAction: action((state) => {
    state.user.authenticatedUser = defaultState.authenticatedUser;
  }),
  setUserListAction: action((state, list) => {
    state.user.list = list;
  }),
  setUserSearchInputAction: action((state, input) => {
    state.user.searchInput = input;
  }),
  setUserInputAction: action((state, input) => {
    state.user.input = { ...state.user.input, ...input };
    state.user.updatedOEMList =
      input.oemaccess ||
      state.user.updatedOEMList ||
      defaultState.updatedOEMList;
    state.user.updatedEngineFamilyList =
      input.enginefamilyaccess ||
      state.user.updatedEngineFamilyList ||
      defaultState.updatedEngineFamilyList;
    state.user.updatedEngineVariantList =
      input.enginevariantaccess ||
      state.user.updatedEngineVariantList ||
      defaultState.updatedEngineVariantList;
  }),
  resetUserInputAction: action((state) => {
    state.user.input = defaultState.input;
  }),
  setAllowEditUserAction: action((state, allowEditUser) => {
    state.user.allowEditUser = allowEditUser;
  }),
  //
  setIsComparingColumnFlagsUserAction: action((state, flags) => {
    state.user.isComparingColumnFlagsUser = flags;
  }),
  setChosenColumnToSortIndexUserAction: action((state, index) => {
    state.user.chosenColumnToSortIndexUser = index;
  }),
  setFilteredUserListAction: action((state, list) => {
    state.user.filteredUserList = list;
  }),

  setFilterUserValuesAction: action((state, list) => {
    state.user.filterUserValues = list;
  }),

  setChosenUserComparisonIndexesAction: action((state, list) => {
    state.user.chosenUserComparisonIndexes = list;
  }),
  setUpdatedOEMListAction: action((state, list) => {
    state.user.updatedOEMList = list;
  }),

  setUpdatedEngineFamilyListAction: action((state, list) => {
    state.user.updatedEngineFamilyList = list;
  }),
  setUpdatedEngineVariantListAction: action((state, list) => {
    state.user.updatedEngineVariantList = list;
  }),
  setAccessControlListAction: action((state, list) => {
    state.user.accessControlList = list;
  }),
};

export default actions;
