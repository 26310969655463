import React from "react";
import SelectDropdown from "react-native-select-dropdown";
import styled from "styled-components/native";
import Text from "./Text";
import { Input as RNInput } from "@rneui/themed";
import { Entypo, MaterialIcons } from "@expo/vector-icons";
import { TouchableOpacity, View, Platform } from "react-native";
import { pxToDp } from "../../utilities/responsive";
import { EntypoIcon } from "react-native-vector-icons/Entypo";

const SInput = styled(RNInput)`
	background-color: ${(props) => (props.editable ? "none" : "lightgray")};
	padding: 0 10px;
	font-family: Barlow_400Regular;
	width: 100%;
	color: #00205b;
`;

const StyledSelectDropdown = styled(SelectDropdown)`
	background-color: ${(props) => (props.editable ? "none" : "lightgray")};
	padding: 0 10px;
	font-family: Barlow_400Regular;
	width: 100%;
	color: #00205b;
`;

const Label = styled(Text)`
	color: #00205b;
`;

const RequiredLabel = styled(Label)`
	color: #ab2328;
`;

const SearchIcon = styled(MaterialIcons).attrs({
	name: "keyboard-arrow-down",
	size: 20,
	color: "#00205b",
	alignSelf: "center",
})`
	margin-left: 10px;
`;

function Input({
	label,
	required = false,
	dropdown = false,
	dropdownChoices,
	rowTextForSelection,
	selectedItem,
	onSelect,
	width = "98%",
	editable = true,
	...props
}) {
	return (
		<>
			{label && (
				<Label>
					{label} {required && <RequiredLabel>*</RequiredLabel>}
				</Label>
			)}
			{dropdown ? (
				<StyledSelectDropdown
					{...props}
					disabled={!editable}
					rowTextForSelection={(item) => rowTextForSelection(item)}
					defaultButtonText={props.value}
					selectedItem={props.value}
					data={dropdownChoices}
					onSelect={(value) => onSelect(value)}
					buttonTextAfterSelection={(item) => selectedItem(item)}
					buttonStyle={{
						backgroundColor: editable == true ? "none" : "lightgray",
						fontFamily: "Barlow_400Regular",
						width: width,
						alignSelf: "center",
						alignContent: "flex-start",
						alignText: "flex-start",
						alignItems: "center",
						justifyContent: "center",
						color: "#00205b",
						borderBottomWidth: Platform.OS == "web" ? 0.1 : 0.3,
						paddingLeft: 0,
						borderColor: "#00205b",
						marginBottom: Platform.OS == "web" ? 10 : 25,
						height: 41,
					}}
					renderSearchInputRightIcon={() => (
						<Entypo name="magnifying-glass" size={24} color="#00205b" />
					)}
					searchInputStyle={{
						backgroundColor: "#fbfbfb",
					}}
					buttonTextStyle={{
						color: props.value == "" ? "gray" : "#00205b",
						fontFamily: "Barlow_400Regular",
						flexDirection: "row",
						textAlign: "left",
						alignText: "left",
						padding: Platform.OS == "web" ? 10 : 0,
						alignSelf: "center",
					}}
					rowStyle={{ padding: pxToDp(16) }}
					rowTextStyle={{ color: "#00205b", fontFamily: "Barlow_400Regular" }}
					dropdownTextStyle={{
						color: "#00205b",
						fontFamily: "Barlow_400Regular",
					}}
					renderDropdownIcon={() => <SearchIcon />}
					ref={props.ref}
				/>
			) : (
				<SInput
					placeholderTextColor="#76787A"
					editable={true}
					{...props}
					rightIconContainerStyle={{ position: "absolute", right: 0 }}
					style={
						props.style
							? props.style
							: {
									color: "#00205b",
									backgroundColor: editable == true ? "none" : "lightgray",
							  }
					}
					rightIcon={props.rightIcon ? props.rightIcon : null}
				/>
			)}
		</>
	);
}

export default Input;
