const defaultState = {
  loginInput: {
    email: "",
    password: "",
  },
};

const state = {
  auth: defaultState,
};

export { state as default, defaultState };
