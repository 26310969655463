import Page from "../../containers/Page";
import React from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import Row from "../../presentational/Row";
import EditOEMToFamilyStepForm from "../../containers/Form/EngineFamilyForm/EditOEMStepForm";

const Container = styled(View)`
	padding: 2vh 4vw;
`;

const SRow = styled(Row)`
	align-items: center;
`;

function EditOEMFamilyManagementPage({ ...props }) {
	return (
		<Page.Protected>
			<Container>
				<EditOEMToFamilyStepForm />
			</Container>
		</Page.Protected>
	);
}

export default EditOEMFamilyManagementPage;
