import { useStoreActions, useStoreState } from "easy-peasy";
import CustomModal from "../../presentational/Modal";
import Button from "../Button";
import Text from "../../presentational/Text";
import Row from "../../presentational/Row";
import Col from "../../presentational/Col";
import { vwToDp } from "../../../utilities/responsive";

export default function ExitFileModal({}) {
	const { fileExitWarningModalVisible } = useStoreState((state) => state.file);

	const {
		setFileExitConfirmationModalVisibleAction,
		setAddNewConfigurationModalVisibleAction,
	} = useStoreActions((actions) => actions);

	const handleOnSubmit = () => {
		setFileExitConfirmationModalVisibleAction(false);
		setAddNewConfigurationModalVisibleAction(false);
	};

	return (
		<CustomModal
			// style={{
			// 	width: vwToDp(60),
			// }}
			width="50%"
			height="50%"
			title="Confirmation"
			onClose={() => setFileExitConfirmationModalVisibleAction(false)}
			isVisible={fileExitWarningModalVisible}
		>
			<Col>
				<Text style={{ fontSize: 20, fontWeight: "bold", alignSelf: "center" }}>
					{"Are you sure you want to exit? You will lose all unsaved changes."}
				</Text>
			</Col>

			<Row>
				<Col xs={12} sm={6}>
					<Button
						color="#ab2328"
						fullWidth
						style={{ marginTop: 20 }}
						onPress={() => {
							setFileExitConfirmationModalVisibleAction(false);
						}}
					>
						Cancel
					</Button>
				</Col>
				<Col xs={12} sm={6}>
					<Button fullWidth style={{ marginTop: 20 }} onPress={handleOnSubmit}>
						Confirm
					</Button>
				</Col>
			</Row>
		</CustomModal>
	);
}
