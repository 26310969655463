import { useStoreActions, useStoreState } from "easy-peasy";
import LoginPage from "./LoginPage";
import Page from "./Page";
import { useEffect } from "react";
import { useIsFocused } from "@react-navigation/native";

function ProtectedPage({ children, ...props }) {
	const { email } = useStoreState((state) => state.user.authenticatedUser);

	const isFocused = useIsFocused();

	const { getAuthenticatedUserThunk } = useStoreActions((actions) => actions);

	useEffect(() => {
		isFocused && getAuthenticatedUserThunk();
	}, [isFocused]);

	return <Page {...props}>{email ? children : <LoginPage />}</Page>;
}

export default ProtectedPage;
