import { useStoreActions, useStoreState } from "easy-peasy";
import Button from "../../../presentational/Button";
import ToolStatusForm from "./ToolStatusForm";
import { useNavigation } from "@react-navigation/native";

function EditToolStatusForm(props) {
	const { upsertToolStatusThunk } = useStoreActions((actions) => actions);
	const navigation = useNavigation();
	return (
		<ToolStatusForm
			buttons={[
				<Button
					fullWidth
					onPress={async () => {
						await upsertToolStatusThunk();
						navigation.goBack();
					}}
				>
					Save
				</Button>,
			]}
			{...props}
		/>
	);
}

export default EditToolStatusForm;
