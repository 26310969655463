import { remToDp } from "../../../utilities/responsive";
import Button from "../Button";
import Icon from "../Icon";
import { AntDesign } from "@expo/vector-icons";

function DropdownToggle({ children, ...props }) {
  return (
    <Button {...props}>
      {children || (
        <Icon
          color="white"
          Component={AntDesign}
          size={remToDp(1.25)}
          name="caretdown"
        />
      )}
    </Button>
  );
}

export default DropdownToggle;
