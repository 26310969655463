import { useStoreActions, useStoreState } from "easy-peasy";
import List from "../../presentational/List";
import { FontAwesome5 } from "@expo/vector-icons";
import Icon from "../../presentational/Icon";
import Card from "../../presentational/Card";
import { useNavigation } from "@react-navigation/native";
import { formatStringForComparison } from "../../../utilities/string";
import PaginatedList from "../../presentational/PaginatedList";
import { View } from "react-native";

function MasterToolEngineVariantList(props) {
	const {
		statusList,
		statusListSearchInput,
		isComparingColumnFlagsToolStatus,
		chosenColumnToSortIndexToolStatus,
		filterToolStatusValues,
		chosenToolStatusComparisonIndexes,
		selectedMasterTool,
	} = useStoreState((state) => state.tool);
	const {
		setToolStatusInputAction,

		setIsComparingColumnFlagsToolStatusAction,
		setChosenColumnToSortIndexToolStatusAction,
		setFilteredToolStatusListAction,
		setFilterToolStatusValuesAction,
		setChosenToolStatusComparisonIndexesAction,
		setEditMasterToolEngineModalVisibleAction,
		setUpdateMasterToolEngineInputAction,
		setEditEngineIndexAction,
	} = useStoreActions((actions) => actions);
	const navigation = useNavigation();

	return (
		// <View style={{ width: "100%" }}>
		<PaginatedList
			pagination={false}
			chosenComparisonIndexes={chosenToolStatusComparisonIndexes}
			setChosenComparisonIndexes={setChosenToolStatusComparisonIndexesAction}
			filterValues={filterToolStatusValues}
			setFilterValues={setFilterToolStatusValuesAction}
			setChosenColumnToSortIndex={setChosenColumnToSortIndexToolStatusAction}
			chosenColumnToSortIndex={chosenColumnToSortIndexToolStatus}
			isComparingColumnFlags={isComparingColumnFlagsToolStatus}
			setIsComparingColumnFlags={setIsComparingColumnFlagsToolStatusAction}
			pageSize={8}
			headers={[
				{ columnName: "Engine", propertyName: "engine" },
				{ columnName: "Override Part Number", propertyName: "tscode" },
				{ columnName: "Override Part Status", propertyName: "tsdescription" },
				{ columnName: "Edit", propertyName: "tscode" },
			]}
			list={selectedMasterTool?.engines?.map((user, index) => ({
				engine: user.ENGINE,
				tscode: user.overridePartNumber,
				tsdescription: user.overrideTSCode,
				edit: (
					<Icon
						Component={FontAwesome5}
						name="edit"
						onPress={() => {
							setEditEngineIndexAction(index);
							setUpdateMasterToolEngineInputAction({
								id: user.engineID,
								engine: user.ENGINE,
								overrideTSPartNumber: user.overridePartNumber,
								overrideTSCode: user.overrideTSCode,
							});
							setEditMasterToolEngineModalVisibleAction(true);
						}}
					/>
				), // Store the ID needed for the edit action
			}))}
			{...props}
		/>
		// </View>
	);
}

export default MasterToolEngineVariantList;
