import { useStoreActions } from "easy-peasy";
import Button from "../../../presentational/Button";

function AllowEditRoleButton({ children = "Edit", ...props }) {
  const { setAllowEditRoleAction } = useStoreActions((actions) => actions);
  return (
    <Button fullWidth onPress={() => setAllowEditRoleAction(true)} {...props}>
      {children}
    </Button>
  );
}

export default AllowEditRoleButton;
