import { thunk } from "easy-peasy";
import { v4 } from "uuid";
import { getIntegrationItemsAPICall } from "../../api/integration";
import { sendPushNotificationMessagesAPICall } from "../../api/messages";

const thunks = {
	getIntegrationItemsThunk: thunk(async (actions, payload) => {
		actions.setLoadingAction(true);

		try {
			const response = await getIntegrationItemsAPICall();
			actions.setIntegrationListAction(response.data);
		} catch (err) {
			actions.setAlertThunk({
				type: "error",
				message: "Error fetching integration items",
			});
		}

		actions.setLoadingAction(false);
	}),

	sendPushNotificationThunk: thunk(async (actions, payload, helpers) => {
		actions.setLoadingAction(true);

		try {
			await sendPushNotificationMessagesAPICall(
				helpers.getState().integration.pushNotificationPayload
			);
			actions.setPushNotificationPayloadAction({
				integrationMessages: [],
			});
			actions.getIntegrationItemsThunk();
			actions.setAlertThunk({
				type: "success",
				message: "Push notification sent successfully",
			});
		} catch (err) {
			actions.setAlertThunk({
				type: "error",
				message: "Error sending push notification",
			});
		}

		actions.setLoadingAction(false);
	}),
};
export default thunks;
