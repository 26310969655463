import Col from "../../../presentational/Col";
import Row from "../../../presentational/Row";
import Input from "../../../presentational/Input";
import { useStoreActions, useStoreState } from "easy-peasy";
import Switch from "../../../presentational/Switch";
import Form from "../Form";
import OEMListDropdown from "../../Dropdown/OEMListDropdown";
import EngineFamilyDropdown from "../../Dropdown/EngineFamilyDropdown";

function WorkScopeForm({ buttons = [], ...props }) {
	const {
		file: { engineFamilyList, oemList },
		workscope: { input },
	} = useStoreState((state) => state);

	const {
		setWorkScopeInputAction,
		setUploadTypeAction,
		setLeapEngineModelAction,
		setUploadEngineModelAction,
	} = useStoreActions((actions) => actions);

	return (
		<Form {...props}>
			<Row>
				<Col xs={12} md={6} lg={6}>
					<OEMListDropdown
						value={
							input.OEMid !== "" && oemList.find((e) => e.OEMID == input.OEMid)
								? oemList.find((e) => e.OEMID == input.OEMid).OEM
								: ""
						}
						onSelect={(name) => {
							setUploadTypeAction(name.OEMID);
							setWorkScopeInputAction({ OEMid: name.OEMID });
						}}
					/>
				</Col>

				<Col xs={12} md={6} lg={6}>
					<EngineFamilyDropdown
						value={
							input.engineFamilyId !== "" &&
							engineFamilyList.find(
								(e) => e.EngineFamilyID == input.engineFamilyId
							)
								? engineFamilyList.find(
										(e) => e.EngineFamilyID == input.engineFamilyId
								  ).EngineFamily
								: ""
						}
						onSelect={(name) => {
							setLeapEngineModelAction(name.EngineFamily);
							setUploadEngineModelAction(name.EngineFamilyID);
							setWorkScopeInputAction({
								engineFamilyId: name.EngineFamilyID,
							});
						}}
					/>
				</Col>
			</Row>
			<Row>
				<Col xs={12} md={6}>
					<Input
						label="Work Scope Description"
						placeholder="Enter Work Scope Description"
						onChangeText={(workscopedescription) =>
							setWorkScopeInputAction({ workscopedescription })
						}
						value={input.workscopedescription}
						required
					/>
				</Col>
				<Col xs={12} md={6}>
					<Input
						label="Engine"
						placeholder="Enter Engine"
						onChangeText={(engine) => setWorkScopeInputAction({ engine })}
						value={input.engine}
						required
					/>
				</Col>
				<Col xs={12} md={6}>
					<Input
						label="Engine Model"
						placeholder="Enter Engine Model"
						onChangeText={(enginemodel) =>
							setWorkScopeInputAction({ enginemodel })
						}
						value={input.enginemodel}
						required
					/>
				</Col>
				<Col xs={12} md={6}>
					<Input
						label="Technical Manual Reference"
						placeholder="Enter Technical Manual Reference"
						onChangeText={(technicalmanualreference) =>
							setWorkScopeInputAction({ technicalmanualreference })
						}
						value={input.technicalmanualreference}
						required
					/>
				</Col>
				<Col xs={12} md={6}>
					<Input
						label="Work Package Description"
						placeholder="Enter Work Package Description"
						onChangeText={(workpackagedescription) =>
							setWorkScopeInputAction({ workpackagedescription })
						}
						value={input.workpackagedescription}
						required
					/>
				</Col>
				<Col xs={12} md={6}>
					<Input
						label="Part Number"
						placeholder="Enter Part Number"
						onChangeText={(partnumber) =>
							setWorkScopeInputAction({ partnumber })
						}
						value={input.partnumber}
						required
					/>
				</Col>
				<Col xs={12} md={6}>
					<Input
						label="Tool Nomenclature"
						placeholder="Enter Tool Nomenclature"
						onChangeText={(toolnomenclature) =>
							setWorkScopeInputAction({ toolnomenclature })
						}
						value={input.toolnomenclature}
						required
					/>
				</Col>
				<Col xs={12} md={6}>
					<Input
						label="Quantity"
						placeholder="Enter Quantity"
						onChangeText={(quantity) => setWorkScopeInputAction({ quantity })}
						value={input.quantity}
						required
					/>
				</Col>
				<Col xs={12} md={6}>
					<Input
						label="WIP Tool"
						placeholder="Enter WIP Tool"
						onChangeText={(wiptool) => setWorkScopeInputAction({ wiptool })}
						value={input.wiptool}
					/>
				</Col>
				<Col xs={12} md={6}>
					<Input
						label="Use With"
						placeholder="Enter Use With"
						onChangeText={(usewith) => setWorkScopeInputAction({ usewith })}
						value={input.usewith}
					/>
				</Col>
				<Col xs={12} md={6}>
					<Input
						label="Tool Code"
						placeholder="Enter Tool Code"
						onChangeText={(toolcode) => setWorkScopeInputAction({ toolcode })}
						value={input.toolcode}
						required
					/>
				</Col>
				<Col xs={12} md={6}>
					<Input
						label="Qualified Revision"
						placeholder="Enter Qualified Revision"
						onChangeText={(qualifiedrevision) =>
							setWorkScopeInputAction({ qualifiedrevision })
						}
						value={input.qualifiedrevision}
						required
					/>
				</Col>
				<Col xs={12} md={6}>
					<Switch
						label="CE UKCA"
						value={input.ceukca}
						onValueChange={(ceukca) => setWorkScopeInputAction({ ceukca })}
					/>
				</Col>
				<Col xs={12} md={6}>
					<Input
						label="Tool's Function"
						placeholder="Enter Tool's Function"
						onChangeText={(toolsfunction) =>
							setWorkScopeInputAction({ toolsfunction })
						}
						value={input.toolsfunction}
					/>
				</Col>
				<Col xs={12} md={6}>
					<Input
						label="Line"
						placeholder="Enter Line"
						onChangeText={(line) => setWorkScopeInputAction({ line })}
						value={input.line}
					/>
				</Col>
				<Col xs={12} md={6}>
					<Input
						label="Module"
						placeholder="Enter Module"
						onChangeText={(module) => setWorkScopeInputAction({ module })}
						value={input.module}
						required
					/>
				</Col>
				<Col xs={12} md={6}>
					<Input
						label="Shop"
						placeholder="Enter Shop"
						onChangeText={(shop) => setWorkScopeInputAction({ shop })}
						value={input.shop}
					/>
				</Col>
				<Col xs={12} md={6}>
					<Input
						label="Repair"
						placeholder="Enter Repair"
						onChangeText={(repair) => setWorkScopeInputAction({ repair })}
						value={input.repair}
					/>
				</Col>
				<Col xs={12} md={6}>
					<Input
						label="LMT Other"
						placeholder="Enter LMT Other"
						onChangeText={(lmtother) => setWorkScopeInputAction({ lmtother })}
						value={input.lmtother}
					/>
				</Col>
				<Col xs={12} md={6}>
					<Input
						label="Duplicate"
						placeholder="Enter Duplicate"
						onChangeText={(duplicate) => setWorkScopeInputAction({ duplicate })}
						value={input.duplicate}
					/>
				</Col>
				<Col xs={12} md={6}>
					<Input
						label="TPM Comments"
						placeholder="Enter TPM Comments"
						onChangeText={(tpmcomments) =>
							setWorkScopeInputAction({ tpmcomments })
						}
						value={input.tpmcomments}
					/>
				</Col>
				<Col xs={12} md={6}>
					<Input
						label="Dimensions"
						placeholder="Enter Dimensions"
						onChangeText={(dimensions) =>
							setWorkScopeInputAction({ dimensions })
						}
						value={input.dimensions}
					/>
				</Col>
				<Col xs={12} md={6}>
					<Input
						label="Weight"
						placeholder="Enter Weight"
						onChangeText={(weight) => setWorkScopeInputAction({ weight })}
						value={input.weight}
						required
					/>
				</Col>
				<Col xs={12} md={6}>
					<Switch
						label="Load Test"
						value={input.loadtest}
						onValueChange={(loadtest) => setWorkScopeInputAction({ loadtest })}
					/>
				</Col>
				<Col xs={12} md={6}>
					<Switch
						label="Calibration"
						value={input.calibration}
						onValueChange={(calibration) =>
							setWorkScopeInputAction({ calibration })
						}
					/>
				</Col>
				<Col xs={12} md={6}>
					<Input
						label="WS File Name"
						placeholder="Enter WS File Name"
						onChangeText={(wsfilename) =>
							setWorkScopeInputAction({ wsfilename })
						}
						value={input.wsfilename}
						required
					/>
				</Col>
			</Row>
			<Row>
				{buttons.map((button, index) => (
					<Col xs={12} md={12 / buttons.length} key={index}>
						{button}
					</Col>
				))}
			</Row>
		</Form>
	);
}

export default WorkScopeForm;
