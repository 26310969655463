import Col from "../../../presentational/Col";
import Row from "../../../presentational/Row";
import Input from "../../../presentational/Input";
import { useStoreActions, useStoreState } from "easy-peasy";
import Switch from "../../../presentational/Switch";
import Form from "../Form";
import EngineFamilyDropdown from "../../Dropdown/EngineFamilyDropdown";

function AddEngineVariantForm({ buttons = [], ...props }) {
	const {
		file: { upsertEngineVariantInput, engineFamilyList },
	} = useStoreState((state) => state);

	const {
		setUpsertEngineFamilyInputAction,
		setUpsertEngineVariantInputAction,
		setLeapEngineModelAction,
		setUploadEngineModelAction,
	} = useStoreActions((actions) => actions);

	return (
		<Form {...props}>
			<Row>
				<Col xs={12} md={8}>
					<EngineFamilyDropdown
						onSelect={(value) => {
							setLeapEngineModelAction(value.EngineFamily);
							setUploadEngineModelAction(value.EngineFamilyID);
							setUpsertEngineVariantInputAction({
								enginefamilyid: value.EngineFamilyID,
							});
						}}
					/>
				</Col>
				<Col xs={12} md={4}>
					<Input
						label="Engine Variant"
						placeholder="Type Engine Variant"
						onChangeText={(enginevariant) =>
							setUpsertEngineVariantInputAction({ enginevariant })
						}
						value={upsertEngineVariantInput.enginevariant}
						required
					/>
				</Col>
				<Col xs={12}>
					<Switch
						label="Is Military"
						onValueChange={(ismilitary) =>
							setUpsertEngineVariantInputAction({ ismilitary })
						}
						value={upsertEngineVariantInput.ismilitary}
						disabled={false}
					/>
				</Col>

				{buttons.map((button, index) => (
					<Col xs={12} md={12 / buttons.length} key={index}>
						{button}
					</Col>
				))}
			</Row>
		</Form>
	);
}

export default AddEngineVariantForm;
