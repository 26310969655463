import { Input, Menu, Row, Pressable } from "@cfbs/cfbsstrap-native";
import React from "react";
import { FontAwesome5 } from "@expo/vector-icons";
import { View } from "react-native";
import Table from "../Table";
import { vhToDp } from "../../../utilities/responsive";
import Button from "../Button";

const TableColumn = ({
	headers,
	propertyName,
	isComparingColumn,
	isSortingColumn,
	columnIndex,
	columnName,
	showMenus,
	showMenu,
	hideMenu,
	setIsComparingColumnFlags,
	chosenComparisonIndexes,
	setChosenComparisonIndexes,
	filterValues,
	setFilterValues,
	setChosenColumnToSortIndex,
	setChosenSortIndex,
	setCurrentPage,
	setFilteredList,
	filterList,
	isComparingColumnFlags,
	chosenSortIndex,
	flex,
	children,
	list,
	showFilter = true,
}) => {
	return (
		<View
			style={{
				flex: flex ?? 1,
				width: headers.length ? `${100 / headers.length}%` : "auto",
				// height: 60,
			}}
			key={columnIndex}
		>
			<View
				key={columnName}
				style={{
					flex: flex ?? 1,

					// alignItems: "center",
					justifyContent: "center",
					borderColor: "#dee2e6",
					borderWidth: 0.1,
					// borderRightWidth: 0,
					borderBottomWidth: 0,
				}}
			>
				<View
					style={{
						position: "absolute",
						top: vhToDp(1),
						right: vhToDp(1),
						zIndex: 1000,
					}}
				>
					{showFilter && (
						<Menu.Toggle
							key={propertyName}
							Toggle={
								<Row key={propertyName}>
									<FontAwesome5
										key={propertyName}
										name="filter"
										color={
											isComparingColumn(columnIndex) ? "default" : "lightgrey"
										}
										size={isComparingColumn(columnIndex) ? 13 : 10}
									/>
									{isSortingColumn(columnIndex) &&
										chosenSortIndex === 0 &&
										" ↑"}
									{isSortingColumn(columnIndex) &&
										chosenSortIndex === 1 &&
										" ↓"}
								</Row>
							}
							showMenu={showMenus[columnIndex]}
							onShowMenu={() => showMenu(columnIndex)}
							onHideMenu={() => hideMenu(columnIndex)}
						>
							<Menu.Item
								key={propertyName}
								style={{
									fontFamily: "Barlow_500Medium",
								}}
								fontFamily="Barlow_500Medium"
								onPress={() => {
									setIsComparingColumnFlags(
										Array.isArray(isComparingColumnFlags)
											? isComparingColumnFlags.map((value, index) =>
													columnIndex === index ? false : value
											  )
											: []
									);

									setChosenComparisonIndexes(
										chosenComparisonIndexes.map((chosenIndex, index) =>
											columnIndex === index ? -1 : chosenIndex
										)
									);
									setFilterValues(
										filterValues.map((value, index) =>
											columnIndex === index ? "" : value
										)
									);
									if (isSortingColumn(columnIndex)) {
										setChosenColumnToSortIndex(-1);
										setChosenSortIndex(-1);
									}
								}}
							>
								Clear Filter
							</Menu.Item>

							{["Sort Ascending", "Sort Descending"].map((children, index) => (
								<Menu.Item
									key={index}
									style={{
										fontFamily: "Barlow_500Medium",
									}}
									fontFamily="Barlow_500Medium"
								>
									<Pressable
										key={index}
										onPress={() => {
											setChosenColumnToSortIndex(columnIndex);
											setChosenSortIndex(index);
											setCurrentPage(0);
											hideMenu(columnIndex);
											// setChosenComparisonIndexes(
											// 	chosenComparisonIndexes.map((value, ci) =>
											// 		ci === columnIndex ? index : value
											// 	)
											// );
											setFilteredList(filterList());
										}}
									>
										{isSortingColumn(columnIndex) &&
											index === chosenSortIndex &&
											"-> "}
										{children}
									</Pressable>
								</Menu.Item>
							))}

							{["Equals", "Contains"].map((children, optionIndex) => (
								<Menu.Item
									key={optionIndex}
									style={{
										fontFamily: "Barlow_500Medium",
									}}
									fontFamily="Barlow_500Medium"
								>
									<Pressable
										key={optionIndex}
										onPress={() => {
											console.log(
												chosenComparisonIndexes.map((value, ci) =>
													ci === columnIndex ? optionIndex : value
												)
											);
											setChosenComparisonIndexes(
												chosenComparisonIndexes.map((value, ci) =>
													ci === columnIndex ? optionIndex : value
												)
											);
											setCurrentPage(0);
										}}
									>
										{chosenComparisonIndexes[columnIndex] === optionIndex &&
											"-> "}
										{children}
									</Pressable>
								</Menu.Item>
							))}

							<Menu.Item>
								<Input.Search
									placeholder="Type filter value here"
									value={filterValues && filterValues[columnIndex]}
									onChangeText={(text) => {
										setFilterValues(
											filterValues.map((value, index) =>
												index === columnIndex ? text : value
											)
										);
										setCurrentPage(0);
									}}
								/>
							</Menu.Item>

							<Menu.Item onPress={() => {}}>
								<Row style={{ justifyContent: "flex-end" }}>
									<Button
										style={{
											marginRight: 8,
											fontFamily: "Barlow_600SemiBold",
										}}
										color="#0088CE"
										onPress={() => {
											setFilteredList(filterList());
											setIsComparingColumnFlags(
												chosenComparisonIndexes.map((value) => value > -1)
											);
											hideMenu(columnIndex);
										}}
									>
										OK
									</Button>

									<Button
										onPress={() => hideMenu(columnIndex)}
										style={{
											fontFamily: "Barlow_600SemiBold",
										}}
										fontFamily="Barlow_600SemiBold"
									>
										CANCEL
									</Button>
								</Row>
							</Menu.Item>
						</Menu.Toggle>
					)}
				</View>
				<View>
					<Table.th
						// border
						key={columnIndex}
						fontSize={15}
						fontFamily="Barlow_600SemiBold"
						width={headers.length ? `${100 / headers.length}%` : "auto"}
					>
						{columnName}
					</Table.th>
					{children}
				</View>
			</View>
		</View>
	);
};

export default TableColumn;
