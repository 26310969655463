import { View as List } from "react-native";
import UserList from "./UserList";
import RoleList from "./RoleList";
import LeapList from "./LeapList";
import CustomerChangeList from "./CustomerChangeList";
import ReviewIngestionList from "./ReviewIngestionList";
import ToolDeltaList from "./ToolDeltaList";
import MissingToolsList from "./MissingToolsList";
import MasterToolList from "./MasterToolList";
import ExceptionToolList from "./ExceptionToolList";
import ColumnConfigurationList from "./ColumnConfigurationList";
import EngineFamilyList from "./EngineFamilyList";
import EngineVariantList from "./EngineVariantList";
import OEMToEngineFamilyList from "./OEMToEngineFamilyList";

List.User = UserList;
List.Role = RoleList;
List.Leap = LeapList;
List.Customer = CustomerChangeList;
List.IngestionReview = ReviewIngestionList;
List.ToolDelta = ToolDeltaList;
List.MissingTools = MissingToolsList;
List.MasterTools = MasterToolList;
List.Exceptions = ExceptionToolList;

List.ColumnConfig = ColumnConfigurationList;

List.EngineFamily = EngineFamilyList;
List.OEMToEngineFamily = OEMToEngineFamilyList;
List.EngineVariant = EngineVariantList;

export default List;
