import { View as Pages } from "react-native";
import HomePage from "./UserPage/HomePage";
import LeapMasterListPage from "./MasterToolList/LeapMasterListPage";
import AddUserPage from "./UserPage/AddUserPage";
import EditUserPage from "./UserPage/EditUserPage";
import RoleListPage from "./RolePage/RoleListPage";
import AddRolePage from "./RolePage/AddRolePage";
import EditRolePage from "./RolePage/EditRolePage";
import ConfigurationPage from "./Configuration/ConfigurationPage";
import ColumnConfigurationPage from "./ColumnConfiguration/ColumnConfigurationPage";
import ToolDeltaListPage from "./DeltaPage/ToolListDeltaPage";
import ExceptionToolListPage from "./ExceptionToolList/ExceptionToolListPage";
import AddMasterToolPage from "./MasterToolList/AddMasterToolPage";
import EditMasterToolPage from "./MasterToolList/EditMasterToolPage";
import OEMFamilyManagementPage from "./OEMEngineFamilyManagement/OEMEngineFamilyManagementPage";
import AddOEMFamilyManagementPage from "./OEMEngineFamilyManagement/AddOEMEngineFamilyManagment";
import AddEngineFamilyPage from "./EngineFamily/AddEngineFamilyPage";
import AddEngineVariantPage from "./EngineVariant/AddEngineVariantPage";
import EditOEMFamilyManagementPage from "./OEMEngineFamilyManagement/EditOEMEngineFamilyManagementPage";
import AddOEMPage from "./AddOEMPage";
import ToolStatusListPage from "./ToolStatus/ToolStatusListPage";
import AddToolStatusPage from "./ToolStatus/AddToolStatusPage";
import EditToolStatusPage from "./ToolStatus/EditToolStatusPage";
import PushNotificationsPage from "./PushNotifications/PushNotificationsListPage";
import WorkScopePage from "./WorkScopePage/WorkScopePage";
import AddWorkScopePage from "./WorkScopePage/AddWorkScopePage";
import EditWorkScopePage from "./WorkScopePage/EditWorkScopePage";
import MasterWorkScopePage from "./MasterWorkScopePage/MasterWorkScopePage";

Pages.Home = HomePage;
Pages.AddUser = AddUserPage;
Pages.EditUser = EditUserPage;

Pages.LeapMasterList = LeapMasterListPage;

Pages.RoleList = RoleListPage;
Pages.AddRole = AddRolePage;
Pages.EditRole = EditRolePage;

Pages.Configuration = ConfigurationPage;
Pages.ColumnConfiguration = ColumnConfigurationPage;
Pages.ToolDelta = ToolDeltaListPage;

Pages.ExceptionToolList = ExceptionToolListPage;

Pages.AddMasterTool = AddMasterToolPage;
Pages.EditMasterTool = EditMasterToolPage;

Pages.OEMEngineFamilyManagement = OEMFamilyManagementPage;
Pages.AddOEMEngineFamilyManagement = AddOEMFamilyManagementPage;
Pages.EditOEMEngineFamilyManagement = EditOEMFamilyManagementPage;

Pages.AddEngineFamily = AddEngineFamilyPage;
Pages.AddEngineVariant = AddEngineVariantPage;
Pages.AddOEM = AddOEMPage;

Pages.ToolStatusList = ToolStatusListPage;
Pages.AddToolStatus = AddToolStatusPage;
Pages.EditToolStatus = EditToolStatusPage;

Pages.PushNotifications = PushNotificationsPage;

Pages.WorkScopeList = WorkScopePage;
Pages.AddWorkScope = AddWorkScopePage;
Pages.EditWorkScope = EditWorkScopePage;

Pages.MasterWorkScopeList = MasterWorkScopePage;

export default Pages;
