import { action } from "easy-peasy";
import { defaultState } from "./state";
import { v4 as uuidv4 } from "uuid"; // make sure to install 'uuid'
import { groupMasterToolList } from "../../utilities/tool";
import { updateHeaderInList } from "../../utilities/filter";

const actions = {
	setToolListAction: action((state, list) => {
		state.tool.list = list;
	}),
	setToolSearchInputAction: action((state, input) => {
		state.tool.searchInput = input;
	}),
	setToolInputAction: action((state, input) => {
		state.tool.input = { ...state.tool.input, ...input };
	}),
	resetToolInputAction: action((state) => {
		state.tool.input = defaultState.input;
	}),

	setToolConfigFilterAction: action((state, input) => {
		state.tool.configFilter = input;
	}),

	addToolToReviewListAction: action((state, tool) => {
		if (typeof tool === "object" && tool !== null) {
			// tool.id = state.tool.reviewList.length + 1;
			tool.id = uuidv4();
			state.tool.reviewList.push(tool);
		} else {
			console.error('Expected "tool" to be an object, but got:', tool);
		}
	}),
	setToolStatusListSearchInputAction: action((state, input) => {
		state.tool.statusListSearchInput = input;
	}),

	setToolStatusInputAction: action((state, input) => {
		state.tool.toolStatusInput = { ...state.tool.toolStatusInput, ...input };
	}),
	removeToolFromReviewListAction: action((state, tool) => {
		state.tool.reviewList = state.tool.reviewList.filter(
			(item) => item.id !== tool.id
		);
	}),

	setReviewListModalActive: action((state, tool) => {
		state.tool.reviewListModalActive = tool;
	}),

	setFileUploadedModalVisibleAction: action((state, visible) => {
		state.tool.fileUploadedModalVisible = visible;
	}),

	setToolDeltaListAction: action((state, list) => {
		state.tool.deltaList = list;
	}),
	// setMissingToolListAction: action((state, list) => {
	// 	state.tool.missingList = list;
	// }),
	setMissingToolListAction: action((state, list) => {
		// group the list by part number
		let organizedData = [];
		list.forEach((item) => {
			const index = organizedData.findIndex(
				(obj) => obj.partNumber === item.partNumber
			);
			if (index > -1) {
				organizedData[index].details.push(item);
			} else {
				organizedData.push({
					partNumber: item.partNumber,
					details: [item],
				});
			}
		});
		state.tool.missingList = organizedData;
	}),

	setMasterToolListAction: action((state, list) => {
		state.tool.masterList = list;
	}),
	setToolStatusListAction: action((state, list) => {
		state.tool.statusList = list;
	}),
	setClientConfigurationToolListAction: action((state, list) => {
		state.tool.clientConfigurationList = list;
	}),
	setEnteredClientConfigurationSearchInputAction: action((state, input) => {
		state.tool.enteredClientConfigurationSearchInput = input;
	}),

	setRequestedToolListAction: action((state, list) => {
		state.tool.requestedToolList = list;
	}),

	setRequestedToolListModalVisibleAction: action((state, visible) => {
		state.tool.requestedToolListModalVisible = visible;
	}),

	setExceptionToolListAction: action((state, list) => {
		state.tool.exceptionList = list;
	}),

	setMasterToolListSearchInputAction: action((state, input) => {
		state.tool.masterListSearchInput = input;
	}),
	setEnteredMasterToolListSearchInputAction: action((state, input) => {
		state.tool.enteredMasterListSearchInput = input;
	}),

	setExceptionListSearchInputAction: action((state, input) => {
		state.tool.exceptionListSearchInput = input;
	}),

	setClientConfigurationSearchInputAction: action((state, input) => {
		state.tool.clientConfigurationSearchInput = input;
	}),

	setDeltaToolListSearchInputAction: action((state, input) => {
		state.tool.deltaListSearchInput = input;
	}),

	setMissingToolListSearchInputAction: action((state, input) => {
		state.tool.missingListSearchInput = input;
	}),

	groupDeltaToolListAction: action((state, list) => {
		let organizedData = [];

		list.forEach((item) => {
			let found = false;

			for (let i = 0; i < organizedData.length && !found; i++) {
				let group = organizedData[i];

				if (
					group.partNumber === item.partNumber &&
					group.details[0].ENGINE === item.ENGINE
				) {
					let suitablePairIndex = group.details.findIndex(
						(pair) =>
							pair.length < 2 &&
							!pair.some((pairItem) => pairItem.recordType === item.recordType)
					);

					if (suitablePairIndex > -1) {
						group.details[suitablePairIndex].push(item);
						found = true;
					} else {
						let canAddNewPair = !group.details.some(
							(pair) => pair.RecordType === item.RecordType
						);
						if (canAddNewPair) {
							group.details.push(item);
							found = true;
						}
					}
				}
			}

			if (!found) {
				organizedData.push({
					partNumber: item.partNumber,
					details: [item],
				});
			}
		});

		state.tool.groupedList = organizedData;
	}),

	addSelectedToolDetailAction: action((state, tool) => {
		if (typeof tool === "object" && tool !== null) {
			// tool.id = state.tool.reviewList.length + 1;
			tool.id = uuidv4();
			state.tool.selectedToolDetails.push(tool);
		} else {
			console.error('Expected "tool" to be an object, but got:', tool);
		}
	}),

	removeSelectedToolDetailAction: action((state, { partNumber, detail }) => {
		state.tool.selectedToolDetails = state.tool.selectedToolDetails.filter(
			(item) =>
				item.partNumber !== partNumber ||
				item.detail.RecordType !== detail.RecordType ||
				item.detail.Leap1A !== detail.Leap1A || // Added Leap1A to the comparison
				item.detail.Leap1B !== detail.Leap1B || // Added Leap1B to the comparison
				item.detail.Leap1C !== detail.Leap1C || // Added Leap1C to the comparison
				item.detail.partDescription !== detail.partDescription || // Added partDescription to the comparison
				item.detail.PartStatusDescription !== detail.PartStatusDescription || // Added PartStatusDescription to the comparison
				item.detail.ReplacedBy !== detail.ReplacedBy || // Added ReplacedBy to the comparison
				item.detail.LastRevision !== detail.LastRevision // Added LastRevision to the comparison
		);
	}),

	setReviewListAction: action((state, list) => {
		state.tool.reviewList = list;
	}),

	setMasterToolInputAction: action((state, input) => {
		state.tool.masterToolInput = { ...state.tool.masterToolInput, ...input };
	}),

	resetMasterToolInputAction: action((state) => {
		state.tool.masterToolInput = defaultState.masterToolInput;
	}),
	setCurrentReviewIndexAction: action((state, index) => {
		state.tool.currentReviewIndex = index;
	}),

	setReviewModalLoadingAction: action((state, loading) => {
		state.tool.reviewModalLoading = loading;
	}),

	setMasterToolIsEditableAction: action((state, editable) => {
		state.tool.masterToolIsEditable = editable;
	}),

	setAreYouSureReviewModalVisibleAction: action((state, visible) => {
		state.tool.areYouSureReviewModalVisible = visible;
	}),
	setAreYouSureRemoveModalVisibleAction: action((state, visible) => {
		state.tool.areYouSureRemoveModalVisible = visible;
	}),
	setAreYouSureReviewAddVisibleAction: action((state, visible) => {
		state.tool.areYouSureAddModalVisible = visible;
	}),

	setMergeToolInputAction: action((state, input) => {
		state.tool.mergeToolInput = { ...state.tool.mergeToolInput, ...input };
	}),
	setSelectedEngineVariantsAction: action((state, input) => {
		state.tool.selectedEngineVariants = input;
	}),

	setEngineVariantDropdownOptionsAction: action((state, dropdownOptions) => {
		state.tool.engineVariantDropdownOptions = dropdownOptions.map((item) => {
			return { item: item.EngineFamily, id: item.EngineFamilyID };
		});
	}),
	// here
	setIsComparingColumnFlagsMasterAction: action((state, flags) => {
		state.tool.isComparingColumnFlagsMaster = flags;
	}),
	setChosenColumnToSortIndexMasterAction: action((state, index) => {
		state.tool.chosenColumnToSortIndexMaster = index;
	}),
	setFilteredMasterListAction: action((state, list) => {
		state.tool.filteredMasterList = list;
	}),

	setFilterMasterValuesAction: action((state, list) => {
		state.tool.filterMasterValues = list;
	}),

	setChosenMasterComparisonIndexesAction: action((state, list) => {
		state.tool.chosenMasterComparisonIndexes = list;
	}),

	setIsComparingColumnFlagsToolStatusAction: action((state, flags) => {
		state.tool.isComparingColumnFlagsToolStatus = flags;
	}),
	setChosenColumnToSortIndexToolStatusAction: action((state, index) => {
		state.tool.chosenColumnToSortIndexToolStatus = index;
	}),
	setFilteredToolStatusListAction: action((state, list) => {
		state.tool.filteredToolStatusList = list;
	}),

	setFilterToolStatusValuesAction: action((state, list) => {
		state.tool.filterToolStatusValues = list;
	}),

	setChosenToolStatusComparisonIndexesAction: action((state, list) => {
		state.tool.chosenToolStatusComparisonIndexes = list;
	}),
	setShowMissingListAction: action((state, show) => {
		state.tool.showMissingList = show;
	}),

	setDynamicLoadingModalVisibleAction: action((state, visible) => {
		state.tool.dynamicLoadingModalVisible = visible;
	}),

	setFailedToImportMasterToolListAction: action((state, visible) => {
		state.tool.failedToImportMasterToolList = visible;
	}),

	setTotalToolsToImportAction: action((state, total) => {
		state.tool.totalToolsToImport = total;
	}),
	setTotalToolsNotImportedAction: action((state, total) => {
		state.tool.totalToolsNotImported = total;
	}),
	setTotalToolsImportedAction: action((state, total) => {
		state.tool.totalToolsImported = total;
	}),

	setIsComparingColumnFlagsDeltaAction: action((state, flags) => {
		state.tool.isComparingColumnFlagsDelta = flags;
	}),
	setChosenColumnToSortIndexDeltaAction: action((state, index) => {
		state.tool.chosenColumnToSortIndexDelta = index;
	}),
	setFilteredDeltaListAction: action((state, list) => {
		state.tool.filteredDeltaList = list;
	}),

	setFilterDeltaValuesAction: action((state, list) => {
		state.tool.filterDeltaValues = list;
	}),

	setChosenDeltaComparisonIndexesAction: action((state, list) => {
		state.tool.chosenDeltaComparisonIndexes = list;
	}),

	setSelectedMasterToolAction: action((state, tool) => {
		state.tool.selectedMasterTool = tool;
	}),

	setEditMasterToolEngineModalVisibleAction: action((state, engine) => {
		state.tool.editMasterToolEngineModalVisible = engine;
	}),

	setUpdateMasterToolEngineInputAction: action((state, input) => {
		state.tool.updateMasterToolEngineInput = {
			...state.tool.updateMasterToolEngineInput,
			...input,
		};
	}),

	groupMasterToolListAction: action((state, list) => {
		state.tool.masterList = list;
		state.tool.masterToolGroupedList = groupMasterToolList(list);
	}),

	setEditEngineIndexAction: action((state, index) => {
		state.tool.editEngineIndex = index;
	}),

	adjustEngineItemAction: action((state, payload) => {
		// Assuming `payload` contains `engineID` to identify the engine, and `updates` as an object with the properties to update
		const { id, updates } = payload;

		// Find the index of the engine item to be updated
		const engineIndex = state.tool.editEngineIndex;

		// Proceed only if the engine item is found
		if (engineIndex !== -1) {
			// Update the engine item by merging existing properties with updates
			// This example uses the spread operator for simplicity; consider using a deep merge utility for nested updates
			state.tool.selectedMasterTool.engines[engineIndex] = {
				// ...state.tool.selectedMasterTool.engines[engineIndex],
				// ...updates,
				// ...payload,
				// id: "1652",
				// engine: "1A",
				ENGINE: updates.engine,
				overridePartNumber: updates.overrideTSPartNumber,
				overrideTSCode: updates.overrideTSCode,
			};

			// we need to also update the masterToolInput
			state.tool.masterToolInput = {
				...state.tool.masterToolInput,
				// I need to get all of the selectedMasterTool Engines and make them comma separated
				EngineVariant: state.tool.selectedMasterTool.engines
					.map((engine) => engine.ENGINE)
					.join(","),
				// same thing to overridePartNumber
				overrideTSPartNumber: state.tool.selectedMasterTool.engines
					.map((engine) => engine.overridePartNumber)
					.join(","),
				// and overrideTSCode
				overrideTSCode: state.tool.selectedMasterTool.engines
					.map((engine) => engine.overrideTSCode)
					.join(","),
				// engines: state.tool.selectedMasterTool.engines,
			};
		}
	}),
	removeEngineItemAction: action((state, index) => {
		const engineIndex = state.tool.editEngineIndex;

		if (engineIndex !== -1) {
			state.tool.masterToolEnginesToRemove = [
				...state.tool.masterToolEnginesToRemove,
				state.tool.selectedMasterTool.engines[engineIndex],
			];
			state.tool.selectedMasterTool.engines.splice(engineIndex, 1);
		}
	}),
	setMasterToolEnginesToRemoveAction: action((state, list) => {
		state.tool.masterToolEnginesToRemove = list;
	}),
	handleToolImportAction: action((state, payload) => {
		state.tool.totalToolsToImport = payload.totalTools;

		if (payload.success) {
			state.tool.totalToolsImported++;
		} else {
			state.tool.failedToImportMasterToolList++;
		}
	}),

	setMasterToolGroupedListAction: action((state, list) => {
		state.tool.masterToolGroupedList = list;
	}),

	setClientConfigToolGroupedListAction: action((state, list) => {
		state.tool.clientConfigGroupedList = list;
	}),

	groupClientConfigToolList: action((state, list) => {
		let organizedData = [];

		list.forEach((item) => {
			const engines = item.ENGINE ? item.ENGINE.split(",") : [];
			const ismilitaryFlags = item.ismilitaryFlag
				? item.ismilitaryFlag.toString().split(",")
				: [];
			const overrideTSPartNumbers = item.LatestToolVersion
				? item.LatestToolVersion.split(",")
				: [];
			const overrideTSCodes = item.LatestToolVersionTSCode
				? item.LatestToolVersionTSCode.split(",")
				: [];
			const overrideQualifications = item.LatestToolVersionqualification
				? item.LatestToolVersionqualification.split(",")
				: [];

			engines.forEach((engine, index) => {
				const newItem = {
					...item,
					"Engine Model": item["Engine Model"],
					ENGINE: engine,
					ismilitaryFlag: ismilitaryFlags[index] || false,
					partNumber: item.partNumber,
					requestedTool: item.requestedTool,
					requestedToolStatus: item.requestedToolStatus,
					LatestpartNumber: item.LatestpartNumber,
					LatestpartNumberStatus: item.LatestpartNumberStatus,
					PartStatusDescription: item.PartStatusDescription,
					toolStatusCode: item.toolStatusCode,
					QualifiedRevision: item.QualifiedRevision,
					FnFCode: item.FnFCode,
					LastRevision: item.LastRevision,
					LatestToolVersion: item.LatestToolVersion,
					LatestToolVersionEngVariants: item.LatestToolVersionEngVariants,
					LatestToolVersionTSCode: item.LatestToolVersionTSCode,
					overridePartNumber: overrideTSPartNumbers[index] || "",
					overrideTSCode: overrideTSCodes[index] || "",
					overridequalification: overrideQualifications[index] || "",
				};

				let group = organizedData.find(
					(g) =>
						g.partNumber === newItem.partNumber &&
						g.partDescription === newItem.partDescription &&
						g["Engine Model"] === newItem["Engine Model"]
				);

				if (!group) {
					group = {
						...newItem,
						engines: [],
					};
					organizedData.push(group);
				}

				group.engines.push(newItem);
			});
		});

		// After all items are processed, add engine details as a string
		organizedData.forEach((group) => {
			group.ENGINE = group.engines.map((engine) => engine.ENGINE).join(", ");
		});

		// Update the state with the organized data
		state.tool.clientConfigGroupedList = organizedData;
	}),

	setMasterListGroupedPageAction: action((state, page) => {
		state.tool.masterToolGroupedPage = page;
	}),
	setCurrentConfigGroupedPageAction: action((state, page) => {
		state.tool.currentConfigGroupedPage = page;
	}),
	setIsApplyingFindToolFilterAction: action(
		(state, isApplyingFindToolFilter) => {
			state.tool.isApplyingFindToolFilter = isApplyingFindToolFilter;
		}
	),
	updateMasterToolHeaderAction: action(
		(state, { updatedTableHeader, parentHeaderIndex, subheaderIndex }) => {
			state.tool.masterToolHeaders = updateHeaderInList(
				updatedTableHeader,
				state.tool.masterToolHeaders,
				parentHeaderIndex,
				subheaderIndex
			);
		}
	),
	updateClientConfigToolHeaderAction: action(
		(state, { updatedTableHeader, parentHeaderIndex, subheaderIndex }) => {
			state.tool.clientConfigToolHeaders = updateHeaderInList(
				updatedTableHeader,
				state.tool.clientConfigToolHeaders,
				parentHeaderIndex,
				subheaderIndex
			);
		}
	),

	setTenantInfoByPartNumberAction: action((state, data) => {
		state.tool.tenantInfoByPartNumberList = data;
	}),
	setTenantInfoByPartNumberModalVisibleAction: action((state, visible) => {
		state.tool.tenantInfoByPartNumberModalVisible = visible;
	}),
};

export default actions;
