import ExcelJS from "exceljs";
import download from "downloadjs";
import moment from "moment";

async function downloadFile(
  headers,
  dataRows,
  { sheetName, filename, fileType, creatorName }
) {
  const now = new Date();

  const workbook = new ExcelJS.Workbook();
  workbook.creator = creatorName;
  workbook.created = now;
  workbook.modified = now;

  const worksheet = workbook.addWorksheet(sheetName, {});
  worksheet.columns = headers;

  dataRows.forEach((row) => worksheet.addRow(row));
  worksheet.getRow(1).eachCell((cell) => (cell.font = { bold: true }));
  worksheet.columns = worksheet.columns.map((column) => {
    const dataMax = column.values.reduce(
      (max, rowVal) =>
        rowVal !== null && rowVal.toString().length > max
          ? rowVal.toString().length
          : max,
      0
    );
    return { ...column, width: dataMax < 10 ? 10 : dataMax };
  });

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: fileType,
  });
  download(blob, filename, fileType);
}

async function exportWorkScopeToolListToCsvFile(data, filterFilename) {
  const now = new Date();
  const fileName = `${filterFilename}_${moment().format("MM-DD-YYYY")}.xlsx`;

  return new Promise((resolve, reject) => {
    const workbook = new ExcelJS.Workbook();
    workbook.creator = "Me";
    workbook.created = now;
    workbook.modified = now;

    const worksheet = workbook.addWorksheet("My Sheet", {});

    worksheet.columns = [
      { header: "Technical Workscope", key: "workscopedescription" },
      { header: "Part Number", key: "partnumber" },
      { header: "Work Package Description", key: "workpackagedescription" },
      { header: "Engine", key: "engine" },
      { header: "Engine Model", key: "enginemodel" },
      { header: "Technical Manual Reference", key: "technicalmanualreference" },
      { header: "Tool Nomenclature", key: "toolnomenclature" },
      { header: "Quantity", key: "quantity" },
      { header: "Tool Code", key: "toolcode" },
      { header: "Qual. / Validation Status", key: "qualifiedrevision" },
      { header: "Replaced By Part Number", key: "replacedbypartnumber" },
      { header: "Replaced By Status Code", key: "replacedbystatuscode" },
      {
        header: "Replaced By Qualification Code",
        key: "replacedByqualificationcode",
      },
      {
        header: "CE UK CA",
        key: "ceukca",
      },
      {
        header: "WIP Tool",
        key: "wiptool",
      },
      {
        header: "Use With",
        key: "usewith",
      },
      {
        header: "Tools Function",
        key: "toolsfunction",
      },
      {
        header: "Line",
        key: "line",
      },
      {
        header: "Module",
        key: "module",
      },
      {
        header: "Shop",
        key: "shop",
      },
      {
        header: "Repair",
        key: "repair",
      },
      {
        header: "LMT Other",
        key: "lmtother",
      },
      {
        header: "Duplicate",
        key: "duplicate",
      },
      {
        header: "TPM Comments",
        key: "tpmcomments",
      },
      {
        header: "Dimensions",
        key: "dimensions",
      },
      {
        header: "Weight",
        key: "weight",
      },
      {
        header: "Load Test",
        key: "loadtest",
      },
      {
        header: "Calibration",
        key: "calibration",
      },
      { header: "Date Modified", key: "datemodified" },
    ];

    data.forEach((item) => worksheet.addRow(item));

    worksheet.getRow(1).eachCell((cell) => {
      cell.font = { bold: true };
    });

    worksheet.columns.forEach((column) => {
      let dataMax = column.values.reduce((max, rowVal) => {
        if (rowVal !== null && rowVal.toString().length > max) {
          return rowVal.toString().length;
        }
        return max;
      }, 0);
      column.width = dataMax < 10 ? 10 : dataMax;
    });

    workbook.xlsx
      .writeBuffer()
      .then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        download(
          blob,
          fileName,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export { downloadFile, exportWorkScopeToolListToCsvFile };
