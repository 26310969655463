import { useStoreActions } from "easy-peasy";
import Button from "../../../presentational/Button";
import { useNavigation } from "@react-navigation/native";

function useOnPress() {
  const navigation = useNavigation();
  const { setAllowEditUserAction } = useStoreActions((actions) => actions);

  return () => {
    setAllowEditUserAction(false);
    navigation.navigate("Add Role");
  };
}

function GoToCreateRoleButton({ children = "Add Role", ...props }) {
  const onPress = useOnPress();
  return (
    <Button onPress={onPress} fullWidth {...props}>
      {children}
    </Button>
  );
}

export default GoToCreateRoleButton;
