import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";

async function connectToSignalRHub(baseUrl, userId, connectionCallbacks) {
  const { onreconnecting, onreconnected, onclose } = connectionCallbacks || {};

  const connection = new HubConnectionBuilder()
    .withUrl(`${baseUrl}?userId=${userId}`)
    .withAutomaticReconnect()
    .configureLogging(LogLevel.Information)
    .build();

  connection.serverTimeoutInMilliseconds = 1000 * 60 * 100; // 10 minutes
  connection.keepAliveIntervalInMilliseconds = 1000 * 60 * 100; // 5 minutes

  connection.onreconnecting((error) => {
    console.log("Reconnecting due to error", error);
    onreconnecting && onreconnecting(error);
  });

  connection.onreconnected((connectionId) => {
    console.log("Connection reestablished. Connection ID: ", connectionId);
    onreconnected && onreconnected(connectionId);
  });

  connection.onclose((error) => {
    console.log("Connection closed", error);
    onclose && onclose(error);
  });

  await connection.start();

  return connection;
}

export { connectToSignalRHub };
