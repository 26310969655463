import Page from "../../containers/Page";
import React from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import Row from "../../presentational/Row";
import Gap from "../../presentational/Gap";
import SearchBar from "../../presentational/SearchBar";
import Col from "../../presentational/Col";
import List from "../../functional/List";
import {
  useWorkScopeMasterToolTable,
  useWorkScopeToolListControlForm,
  useDownloadWorkScopeCsvButton,
} from "../../../hooks/workscope";
import Card from "../../presentational/Card";
import Input from "../../presentational/Input";
import Button from "../../presentational/Button";

const Container = styled(View)`
  padding: 2vh 4vw;
`;

const SRow = styled(Row)`
  align-items: center;
`;

function MasterWorkScopePage(props) {
  const tableDataProps = useWorkScopeMasterToolTable();

  const {
    workPackageNameInput,
    engineModelInput,
    engineInput,
    searchBar,
    ...inputProps
  } = useWorkScopeToolListControlForm();

  const { children, ...downloadCsvButtonProps } =
    useDownloadWorkScopeCsvButton();

  return (
    <>
      <Page.Protected {...props}>
        <Container>
          <SRow>
            <Col xs={12} sm={2}>
              <Input {...engineInput} {...inputProps} />
            </Col>
            <Col xs={12} sm={2}>
              <Input {...engineModelInput} {...inputProps} />
            </Col>
            <Col xs={12} sm={2}>
              <Input {...workPackageNameInput} {...inputProps} />
            </Col>
            {/* <Col xs={12} sm={2}>
            <Button fullWidth onPress={() => navigation.push("Add Work Scope")}>
              Add Work Scope
            </Button>
          </Col> */}

            <Col xs={12} sm={6}>
              <SearchBar {...searchBar} />
            </Col>
          </SRow>

          <Gap />

          <Card>
            <List.Grouped {...tableDataProps} />
          </Card>
        </Container>
      </Page.Protected>

      <View
        style={{
          position: "absolute",
          right: 20,
          bottom: 20,
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Button {...downloadCsvButtonProps}>{children}</Button>
      </View>
    </>
  );
}

export default MasterWorkScopePage;
