import { axios } from ".";

const userAxios = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}/user`,
});

function getAuthenticatedUserApiCall() {
  return userAxios({ method: "get", url: "/authenticated" });
}

function getUsersApiCall(id) {
  return userAxios({ method: "get", url: `/get/${id}` });
}

function upsertUserApiCall(data) {
  return userAxios({ method: "put", url: "/upsert", data });
}

function getUserAccessControlListApiCall(userId) {
  return userAxios({
    method: "get",
    url: `/access/control/list/get/${userId}`,
  });
}

export {
  userAxios,
  getAuthenticatedUserApiCall,
  getUsersApiCall,
  upsertUserApiCall,
  getUserAccessControlListApiCall,
};
