import Page from "../../containers/Page";
import React, { useEffect } from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { useIsFocused } from "@react-navigation/native";
import { useStoreActions, useStoreState } from "easy-peasy";
import Gap from "../../presentational/Gap";
import Col from "../../presentational/Col";
import IntegrationSearchBar from "../../containers/SearchBar/IntegrationItemsSearchBar";
import Button from "../../presentational/Button";
import List from "../../functional/List";
import Card from "../../presentational/Card";
import TenantInfoByPartNumberModal from "../../functional/Modal/TenantInfoByPartNumberModal";

const Container = styled(View)`
	padding: 2vh 4vw;
`;

function PushNotificationsPage(props) {
	const {
		integration: { pushNotificationPayload },
	} = useStoreState((state) => state);
	const {
		getIntegrationItemsThunk,
		sendPushNotificationThunk,
		getTenantInfoByPartNumberThunk,
	} = useStoreActions((actions) => actions);
	const isFocused = useIsFocused();

	const [partNumber, setPartNumber] = React.useState("");

	useEffect(() => {
		isFocused && getIntegrationItemsThunk();
	}, [isFocused]);

	return (
		<>
			<Page.Protected {...props}>
				<Container>
					<Col xs={12} sm={12}>
						<IntegrationSearchBar />
					</Col>
					<TenantInfoByPartNumberModal partNumber={partNumber} />
					<Gap />
					<Card>
						<List.PushNotification
							setToolModalVisible={(partNumber) => {
								setPartNumber(partNumber);
								getTenantInfoByPartNumberThunk(partNumber);
							}}
						/>
					</Card>
				</Container>
			</Page.Protected>
			<View
				style={{
					position: "absolute",
					right: 20,
					bottom: 20,
					justifyContent: "space-between",
					flexDirection: "row",
				}}
			>
				<Button
					disabled={pushNotificationPayload.integrationMessages.length === 0}
					onPress={() => {
						sendPushNotificationThunk();
					}}
				>
					Push Notifications
				</Button>
			</View>
		</>
	);
}

export default PushNotificationsPage;
