import { useStoreState } from "easy-peasy";
import Button from "../../Button";
import UserForm from "./UserForm";

function EditUserForm(props) {
  const {
    input: { userName, email, password, cpassword, firstName, lastName },
    allowEditUser,
  } = useStoreState((state) => state.user);

  return (
    <UserForm
      buttons={[
        <Button.AllowEditUser />,
        <Button.GoToUsers>Cancel</Button.GoToUsers>,
        <Button.UpsertUser
          disabled={
            !userName ||
            !email ||
            password !== cpassword ||
            !allowEditUser ||
            !firstName ||
            !lastName
          }
        >
          Save Changes
        </Button.UpsertUser>,
      ]}
      {...props}
    />
  );
}

export default EditUserForm;
