import Col from "../../../presentational/Col";
import Row from "../../../presentational/Row";
import Input from "../../../presentational/Input";
import { useStoreActions, useStoreState } from "easy-peasy";
import Form from "../Form";

function RoleForm({ buttons = [], ...props }) {
  const {
    input: { name, description },
    allowEdit,
  } = useStoreState((state) => state.role);

  const { setRoleInputAction } = useStoreActions((actions) => actions);

  return (
    <Form {...props}>
      <Row>
        <Col xs={12}>
          <Input
            label="Name"
            onChangeText={(name) => setRoleInputAction({ name })}
            value={name}
            required
            editable={allowEdit}
          />
        </Col>

        <Col xs={12}>
          <Input
            label="Description"
            onChangeText={(description) => setRoleInputAction({ description })}
            value={description}
            editable={allowEdit}
          />
        </Col>

        {buttons.map((button, index) => (
          <Col xs={12} md={12 / buttons.length} key={index}>
            {button}
          </Col>
        ))}
      </Row>
    </Form>
  );
}

export default RoleForm;
