import { axios } from '.';

const authAxios = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}/auth`,
});

function loginApiCall(data) {
  return authAxios({ method: 'post', url: '/login', data });
}

function logoutApiCall() {
  return authAxios({ method: 'post', url: '/logout' });
}

function refreshTokenApiCall() {
  return authAxios({ method: 'post', url: '/token/refresh' });
}

export { authAxios, loginApiCall, logoutApiCall, refreshTokenApiCall };
