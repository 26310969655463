import { useClientConfigGroupedToolTable } from "../../../hooks/client-config";
import Text from "../../presentational/Text";
import GroupedList from "./GroupedList";
import styled from "styled-components/native";

const Note = styled(Text)`
  font-style: italic;
  margin-bottom: 20px;
`;

function ClientConfigToolList(props) {
  const { isApplyingFindToolFilter, ...listProps } =
    useClientConfigGroupedToolTable();

  return (
    <>
      {isApplyingFindToolFilter && (
        <Note>* Note: Filter from finding tools is being applied</Note>
      )}

      <GroupedList {...listProps} {...props} />
    </>
  );
}

export default ClientConfigToolList;
