import Col from "../../presentational/Col";
import Row from "../../presentational/Row";
import Button from "../../presentational/Button";
import { useStoreActions, useStoreState } from "easy-peasy";
import Form from "../Form";
import EngineFamilyDropdown from "../Dropdown/EngineFamilyDropdown";
import OEMListDropdown from "../Dropdown/OEMListDropdown";

function ColumnConfigurationForm({ buttons = [], ...props }) {
  const {
    file: { fileChangesInput, engineFamilyList, oemList },
    leap: { leapFileChangesList },
    user: { authenticatedUser },
  } = useStoreState((state) => state);

  const {
    setFileListAction,
    setAddOEMModalVisibleAction,
    setFileChangesInputAction,
    getTableColumnNamesThunk,
    getOEMHeadersListThunk,
    setAddToColumnConfigModalVisibleAction,
    setLeapEngineModelAction,
    setRemoveFileMappingAsAdminAreYouSureModalVisibleAction,
  } = useStoreActions((actions) => actions);

  const showDeleteButton =
    leapFileChangesList.length > 0 && authenticatedUser.role === "admin";

  const inputSizes = showDeleteButton ? 3 : 4;
  const buttonSizes = 2;

  return (
    <Form {...props}>
      <Row>
        <Col xs={12} md={inputSizes}>
          <EngineFamilyDropdown
            value={
              engineFamilyList.find(
                (i) => i.EngineFamilyID === fileChangesInput.engFamilyID
              )?.EngineFamily
            }
            onSelect={(name) => {
              setLeapEngineModelAction(name.EngineFamily);
              setFileChangesInputAction({
                engFamilyID: name.EngineFamilyID,
              });
            }}
          />
        </Col>
        <Col xs={12} md={inputSizes}>
          <OEMListDropdown
            value={oemList.find((i) => i.OEMID === fileChangesInput.OEMID)?.OEM}
            onSelect={(name) =>
              setFileChangesInputAction({ OEMID: name.OEMID })
            }
          />
        </Col>
        <Col xs={12} md={buttonSizes}>
          <Button
            disabled={
              fileChangesInput.OEMID === "" ||
              fileChangesInput.engFamilyID === ""
            }
            fullWidth
            title="Add Mapping"
            onPress={() => {
              if (
                fileChangesInput.OEMID !== "" &&
                fileChangesInput.engFamilyID !== "" &&
                leapFileChangesList.length === 0
              ) {
                setAddOEMModalVisibleAction(true);
              } else {
                getOEMHeadersListThunk();
                setFileChangesInputAction({
                  id: null,
                  OEMID: fileChangesInput.OEMID,
                  engFamilyID: fileChangesInput.engFamilyID,
                  filecolumnName: "",
                  filecolumnID: "",
                  fileType: "xlsx",
                  tablecolumnName: "",
                  propertyName: "",
                  legendCode: "",
                  propertyType: "varchar(50)",
                  defaulttolegendcode: false,
                });
                setFileListAction([]);
                setAddToColumnConfigModalVisibleAction(true);
              }
              getTableColumnNamesThunk();
            }}
          />
        </Col>
        <Col xs={12} md={buttonSizes}>
          <Button onPress={() => setAddOEMModalVisibleAction(true)}>
            Upload New Header Files
          </Button>
        </Col>
        {showDeleteButton && (
          <Col xs={12} md={buttonSizes}>
            <Button
              color="#ab2328"
              disabled={
                fileChangesInput.OEMID === "" ||
                fileChangesInput.engFamilyID === ""
              }
              fullWidth
              title="Remove Mapping"
              onPress={() =>
                setRemoveFileMappingAsAdminAreYouSureModalVisibleAction(true)
              }
            />
          </Col>
        )}
      </Row>
    </Form>
  );
}

export default ColumnConfigurationForm;
