import { useStoreActions, useStoreState } from "easy-peasy";
import Button from "../../../presentational/Button";

function AllowEditUserButton({ children = "Edit", ...props }) {
  const authenticatedUser = useStoreState(
    (state) => state.user.authenticatedUser
  );

  const { setAllowEditUserAction, getUserAccessControlThunk } = useStoreActions(
    (actions) => actions
  );

  return (
    <Button
      fullWidth
      onPress={() => {
        setAllowEditUserAction(true);
        getUserAccessControlThunk(authenticatedUser.id);
      }}
      {...props}
    >
      {children}
    </Button>
  );
}

export default AllowEditUserButton;
