import React, { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import styled from "styled-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import Card from "../presentational/Card";
import { remToDp } from "../../utilities/responsive";
import Col from "../presentational/Col";
import Row from "../presentational/Row";
import Input from "../presentational/Input";
import Button from "../presentational/Button";

import { useIsFocused, useNavigation } from "@react-navigation/native";
import Page from "../containers/Page";
import Form from "../containers/Form";

const ColSwitch = styled(Col)`
	padding-left: ${remToDp(1.5)}px;
`;

const AddOEMPage = ({ ...props }) => {
	const navigation = useNavigation();
	const [currentStep, setCurrentStep] = useState(1);

	const {
		engineFamilyList,
		engineVariantList,
		oemToEngineFamilyInput,
		upsertOEMInput,
		oemToFamilyCurrentStep,
		upsertOEMToFamilyInput,
		oemList,
	} = useStoreState((state) => state.file);

	const {
		upsertToolsThunk,
		upsertExtraDocumentThunk,
		setOEMToEngineFamilyInputAction,
		setUpsertOEMInputAction,
		setOemToFamilyCurrentStepAction,
		upsertFileOEMThunk,
		setOEMToFamilyInputAction,
		upsertOEMToFamilyThunk,
		upsertOEMThunk,
	} = useStoreActions((actions) => actions);
	const isFocused = useIsFocused();

	useEffect(() => {
		if (isFocused) {
			setUpsertOEMInputAction({ OEM: "", OEMDescription: "" });
		}
	}, [isFocused]);

	return (
		<Page>
			<Form>
				<Card style={styles.container}>
					<Row>
						<Col xs={12} md={6}>
							{/* <Input
								width="99%"
								label="OEM Name"
								placeholder="OEM"
								value={upsertOEMInput.OEM}
								onSelect={(name) => {
									setUpsertOEMInputAction({ OEM: name.OEM });
								}}
								required
								editable={true}
								dropdown
								dropdownChoices={oemList}
								selectedItem={(item) => item.OEM}
								rowTextForSelection={(item) => item.OEM}
								buttonTextAfterSelection={(item) => item.OEM}
							/> */}
							<Input
								label="OEM Name"
								onChangeText={(OEM) => setUpsertOEMInputAction({ OEM })}
								value={upsertOEMInput.OEM}
								required
								placeholder="Type OEM Name"
							/>
						</Col>

						<Col xs={12} md={6}>
							<Input
								label="OEM Description"
								onChangeText={(OEMDescription) =>
									setUpsertOEMInputAction({ OEMDescription })
								}
								required
								value={upsertOEMInput.OEMDescription}
								placeholder="Type OEM Description"
							/>
						</Col>
					</Row>
					<Button
						disabled={
							upsertOEMInput.OEM == "" && upsertOEMInput.OEMDescription == ""
								? true
								: upsertOEMInput.OEM !== "" &&
								  upsertOEMInput.OEMDescription == ""
								? true
								: upsertOEMInput.OEM == "" &&
								  upsertOEMInput.OEMDescription !== ""
								? true
								: false
						}
						fullWidth
						title="Save"
						onPress={async () => {
							await upsertOEMThunk();
							navigation.goBack();
						}}
					/>
				</Card>
			</Form>
		</Page>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		padding: 16,
	},
	stepIndicatorsContainer: {
		flexDirection: "row",
		alignSelf: "center",
		marginBottom: 16,
		width: "100%",
	},
	stepIndicator: {
		fontFamily: "Barlow_600SemiBold",
		flex: 1,
		fontSize: 18,
		textAlign: "center",
		paddingHorizontal: 8,
		paddingVertical: 4,
		borderRadius: 8,
		marginRight: 8,
		flexDirection: "row",
	},
	currentStepIndicator: {
		backgroundColor: "#e8e9ea",
	},
	stepText: {
		fontSize: 20,
		marginBottom: 16,
	},
});

export default AddOEMPage;
