import { axios } from ".";
import { convertJsonToQueryString } from "../utilities/general";

const leapAxios = axios.create({
	baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}/leap`,
});

function uploadLeapMasterFileApiCall(data) {
	return leapAxios({ method: "put", url: "/master/upload", data });
}

function uploadLeapChangeFileApiCall(data) {
	return leapAxios({ method: "put", url: "/changes/upload", data });
}

function upsertFileDifferencesApiCall(data) {
	return leapAxios({ method: "put", url: "/oemdeltas/upsert", data });
}

function getMasterTableColumnsAPICall() {
	return leapAxios({
		method: "get",
		url: `/mastertablecolumnnames/get`,
	});
}

function createStoredProcedureApiCall(data) {
	return leapAxios({
		method: "post",
		url: "/storedprocedurefile/create",
		data,
	});
}

export {
	leapAxios,
	upsertFileDifferencesApiCall,
	uploadLeapMasterFileApiCall,
	uploadLeapChangeFileApiCall,
	createStoredProcedureApiCall,
	getMasterTableColumnsAPICall,
};
