import { Center } from "@cfbs/cfbsstrap-native";
import { useUploadWorkScopeFileModal } from "../../../hooks/workscope";
import Button from "../../presentational/Button";
import Modal from "./Modal";
import Gap from "../../presentational/Gap";

function UploadWorkScopeFileModal(props) {
  const { downloadButton, uploadButton, ...modalProps } =
    useUploadWorkScopeFileModal();

  return (
    <Modal {...modalProps} {...props}>
      <Center>
        <Button {...downloadButton.props}>{downloadButton.children}</Button>
        <Gap />
        <Button {...uploadButton.props}>{uploadButton.children}</Button>
      </Center>
    </Modal>
  );
}

export default UploadWorkScopeFileModal;
