import { View } from "react-native";
import Col from "../../presentational/Col";
import Row from "../../presentational/Row";
import Text from "../../presentational/Text";
import styled from "rn-css";
import { useStoreState } from "easy-peasy";
import Drawer from "../../presentational/Drawer";

const Container = styled(Row)`
  width: 100%;
  justify-content: space-between;
`;

const SText = styled(Text)`
  text-align: right;

  @media (max-width: 576px) {
    display: none;
  }
`;

function DrawerHeader(props) {
  const { firstName, lastName, role } = useStoreState(
    (state) => state.user.authenticatedUser
  );

  return (
    <Container {...props}>
      <Col xs={0} sm={3} />
      <Col xs={0} sm={4}>
        <Drawer.Brand />
      </Col>
      <Col xs={0} sm={5}>
        <View>
          <SText>Welcome to Rhinestahl PROST</SText>
          <SText>
            {firstName} {lastName}
          </SText>
          <SText>Role: {role}</SText>
        </View>
      </Col>
    </Container>
  );
}

export default DrawerHeader;
