const defaultState = {
  list: [],
  fileChangesInput: {
    id: null,
    OEMID: "",
    engFamilyID: "",
    filecolumnName: "",
    filecolumnID: "",
    fileType: "xlsx",
    tablecolumnName: "",
    propertyName: "",
    legendCode: "",
    propertyType: "varchar(50)",
    defaulttolegendcode: false,
  },

  oemToEngineFamilyInput: {
    oemName: "",
    oemDescription: "",
    engineFamily: "",
    engineVariant: "",
  },

  upsertOEMInput: {
    OEMid: null,
    OEM: "",
    OEMDescription: "",
  },

  upsertEngineFamilyInput: {
    id: null,
    enginefamilydesc: "",
  },

  upsertEngineVariantInput: {
    id: null,
    enginefamilyid: null,
    enginevariant: null,
    ismilitary: false,
  },

  upsertOEMToFamilyInput: {
    id: null,
    oemid: null,
    enginefamilyid: null,
  },

  updateOEMToEngineFamilyInput: {
    id: null,
    OEMID: "",
    oem: "",
    oemdescription: "",
    EngineFamilyID: "",
    EngineFamily: "",
    EngineVariantID: "",
    EngineVariant: "",
    ismilitaryFlag: false,
  },

  upsertOEMHeadersInput: {
    ids: "",
    OEMID: null,
    engFamilyID: null,
    filecolumnNames: "",
    filecolumnIDs: "",
  },

  oemList: [],
  fileExitWarningModalVisible: false,
  missingReviewList: [],
  engineFamilySearchInput: "",
  engineVariantSearchInput: "",
  oemToEngineFamilySearchInput: "",
  oemToEngineFamilyList: [],
  engineFamilyList: [],
  engineVariantList: [],

  oemToFamilyCurrentStep: 1,
  oemToFamilyEditCurrentStep: 1,
  oemFileHeaders: [],

  editHeadersIndex: 0,
  mergeAllAreYouSureModalVisible: false,
  deleteFileChangesRowAreYouSureModalVisible: false,

  isComparingColumnFlagsOemEngine: [false, false],
  chosenColumnToSortIndexOemEngine: -1,
  filteredOemEngineList: [],
  filterOemEngineValues: ["", "", "", ""],
  chosenOemEngineComparisonIndexes: [-1, -1, -1, -1],
  isUploadingFile: false,
};

const state = {
  file: defaultState,
};

export { state as default, defaultState };
