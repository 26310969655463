import Page from "../../containers/Page";
import React, { useEffect } from "react";

import { View } from "react-native";
import styled from "styled-components/native";
import ToolDeltaList from "../../containers/List/ToolDeltaList";
import ReviewToolsModal from "../../containers/Modal/ReviewToolsModal";
import { useIsFocused, useNavigation } from "@react-navigation/native";
import { useStoreActions, useStoreState } from "easy-peasy";
import Row from "../../presentational/Row";
import MissingToolsList from "../../containers/List/MissingToolsList";
import Switch from "../../presentational/Switch";
import Gap from "../../presentational/Gap";
import Input from "../../presentational/Input";
import SearchBar from "../../presentational/SearchBar";
import Col from "../../presentational/Col";
import AreYouSureModal from "../../containers/Modal/AreYouSureModal";
import WorkScopeList from "../../containers/List/WorkScopeList";
import Button from "../../presentational/Button";

const Container = styled(View)`
	padding: 2vh 4vw;
`;

const SRow = styled(Row)`
	align-items: center;
`;

function WorkScopePage(props) {
	const navigation = useNavigation();

	const { getWorkScopeThunk, upsertWorkScopeThunk, getWorkScopeToolsThunk } =
		useStoreActions((actions) => actions);
	const isFocused = useIsFocused();

	useEffect(() => {
		if (isFocused) {
			getWorkScopeThunk();
			getWorkScopeToolsThunk();
		}
	}, [isFocused]);

	return (
		<Page.Protected {...props}>
			<Container>
				<SRow>
					<Col xs={12} sm={2}>
						<Button
							fullWidth
							onPress={() => {
								// navigation.push("Add Work Scope");
								upsertWorkScopeThunk();
							}}
						>
							Add Work Scope
						</Button>
					</Col>
					<Col xs={12} sm={10}>
						<SearchBar
							onChangeText={(searchInput) => {}}
							value={""}
							placeholder="Search workscope by name"
						/>
					</Col>
				</SRow>
				<Gap />
				<WorkScopeList />
			</Container>
		</Page.Protected>
	);
}

export default WorkScopePage;
