// import Td from "./Td";
// import Th from "./Th";
// import Tr from "./Tr";
// import Table from "./Table";

// Table.th = Th;
// Table.td = Td;
// Table.tr = Tr;
// export default Table;

import styled from 'styled-components/native';
import Td from './Td';
import Th from './Th';
import Tr from './Tr';

const Table = styled.View`
  display: table;
`;

Table.th = Th;
Table.td = Td;
Table.tr = Tr;
export default Table;
