import { useStoreActions, useStoreState } from "easy-peasy";
import List from "../../presentational/List";
import { FontAwesome5, Ionicons } from "@expo/vector-icons";
import Icon from "../../presentational/Icon";
import Card from "../../presentational/Card";
import { useNavigation } from "@react-navigation/native";
import { formatStringForComparison } from "../../../utilities/string";
import Text from "../../presentational/Text";
import Td from "../../presentational/Table/Td";
import { View } from "react-native";
import { CheckBox } from "@rneui/themed";
import { remToDp } from "../../../utilities/responsive";

function MissingToolsList(props) {
	const {
		tool: { missingList, reviewList, missingListSearchInput },
		file: { missingReviewList },
	} = useStoreState((state) => state);
	const {
		removeToolFromMissingToolReviewListAction,
		addToolToMissingToolReviewListAction,
		removeToolFromReviewListAction,
		addToolToReviewListAction,
	} = useStoreActions((actions) => actions);
	return (
		<Card>
			<List
				pageSize={7}
				width={["30%", "60%", "10%"]}
				tableHeaders={["Missing Part Number", "Exception Message", "Select"]}
				tableRows={missingList
					.filter((user) =>
						formatStringForComparison(user.partNumber).includes(
							formatStringForComparison(missingListSearchInput)
						)
					)
					.map((item) => [
						item.partNumber, // partDescription, PartStatusCode,
						item.details[0].message,
						<View>
							<View style={{ flex: 1, flexDirection: "row" }}>
								<CheckBox
									checked={reviewList && reviewList.includes(item)}
									checkedColor="#007AFF"
									onPress={() => {
										if (reviewList && reviewList.includes(item)) {
											removeToolFromReviewListAction(item);
										} else {
											addToolToReviewListAction(item);
										}
									}}
									checkedIcon={
										<FontAwesome5
											name="check-square"
											size={remToDp(1.5)}
											color="#007AFF"
										/>
									}
									iconType="material-community"
									uncheckedIcon={"checkbox-blank-outline"}
									containerStyle={{ backgroundColor: "none" }}
								/>
							</View>
						</View>,
					])}
				{...props}
			/>
		</Card>
	);
}

export default MissingToolsList;
