import Form from "../../containers/Form";
import styled from "styled-components/native";
import { vhToDp, vwToDp } from "../../../utilities/responsive";
import Page from "../../containers/Page";

const Container = styled.View`
	padding: ${vhToDp(2)}px ${vwToDp(4)}px;
`;

function AddWorkScopePage(props) {
	return (
		<Page.Protected>
			<Container>
				<Form.AddWorkScope {...props} />
			</Container>
		</Page.Protected>
	);
}

export default AddWorkScopePage;
