const defaultState = {
  authenticatedUser: {
    email: "",
    userName: "",
    role: "",
    firstName: "",
    lastName: "",
  },
  list: [],
  searchInput: "",
  input: {
    id: 0,
    firstName: "",
    lastName: "",
    email: "",
    userName: "",
    password: "",
    cpassword: "",
    role: "user",
    active: false,
    oemaccess: [],
    enginefamilyaccess: [],
    enginevariantaccess: [],
  },
  allowEditUser: false,

  isComparingColumnFlagsUser: [false, false],
  chosenColumnToSortIndexUser: -1,
  filteredUserList: [],
  filterUserValues: ["", "", "", ""],
  chosenUserComparisonIndexes: [-1, -1, -1, -1],

  updatedOEMList: [],
  updatedEngineFamilyList: [],
  updatedEngineVariantList: [],

  accessControlList: [],
};

const state = {
  user: defaultState,
};

export { state as default, defaultState };
