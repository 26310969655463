import axios from "axios";

axios.defaults.baseURL =
	process.env.REACT_APP_BACKEND_BASE_URL || "http://localhost:8080";

axios.defaults.timeout = 500000000000;

axios.defaults.withCredentials = true;

export { axios };
