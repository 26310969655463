import React, { useCallback, useState } from "react";
import {
	Dimensions,
	ScrollView,
	StyleSheet,
	TouchableOpacity,
	View,
} from "react-native";
import Text from "../../presentational/Text";
import Button from "../../presentational/Button";
import CustomModal from "../../presentational/Modal";
import { useStoreState, useStoreActions } from "easy-peasy";
import Row from "../../presentational/Row";
import Col from "../../presentational/Col";
import { pxToDp } from "../../../utilities/responsive";
const { width } = Dimensions.get("window");

export default function ReviewToolsModal() {
	const {
		tool: {
			reviewListModalActive,
			reviewList,
			selectedToolDetails,
			masterToolInput,
			reviewModalLoading,
			currentReviewIndex,
			mergeToolInput,
		},
		file: { oemList },
	} = useStoreState((state) => state);

	const { setMasterToolInputAction, mergeToolToMasterThunk, setLoadingAction } =
		useStoreActions((actions) => actions);

	const {
		addSelectedToolDetailAction,
		removeSelectedToolDetailAction,
		setReviewListModalActive,
		setAlertThunk,
		setReviewListAction,
		removeToolFromDeltaListThunk,
		upsertMasterToolThunk,
		setCurrentReviewIndexAction,
		setAreYouSureReviewModalVisibleAction,
		addToolToMasterListThunk,
		setAreYouSureRemoveModalVisibleAction,
		setAreYouSureAddModalVisibleAction,
		setAreYouSureReviewAddVisibleAction,
		setMergeToolInputAction,
	} = useStoreActions((actions) => actions);

	const handleSelectDetail = useCallback(
		(partNumber, detail) => {
			const isSelected = selectedToolDetails.some(
				(item) =>
					item.partNumber === partNumber &&
					item.detail.RecordType === detail.RecordType &&
					item.detail.Leap1A === detail.Leap1A &&
					item.detail.Leap1B === detail.Leap1B &&
					item.detail.Leap1C === detail.Leap1C &&
					item.detail.partDescription === detail.partDescription &&
					item.detail.PartStatusDescription === detail.PartStatusDescription &&
					item.detail.ReplacedBy === detail.ReplacedBy &&
					item.detail.LastRevision === detail.LastRevision &&
					item.detail.PartStatusCode === detail.PartStatusCode
				//  &&
				// item.detail.Comments === detail.Comments
			);
			if (isSelected) {
				removeSelectedToolDetailAction({ partNumber, detail });
			} else {
				setMergeToolInputAction({
					id: detail.masterRecordID,
					OEMID: detail.OEMID,

					EngineFamilyID: detail.engineFamilyID || detail.EngineFamilyID || "",
					partNumber: partNumber || "",
					partDescription: detail.partDescription || "",
					TSCode: detail.PartStatusCode || "",
					QualifiedRevision: detail.QualifiedRevision || "",
					SupersededReplaceBy: detail.ReplacedBy || "",
					LastRevision: detail.LastRevision || "",
					Comments: detail.Comments || "",
					EngineVariant: detail.ENGINE || detail.Engine || "",
					ismilitiaryFlag: detail.ismilitiaryFlag,
					PreviousTSCode:
						// if there are multiple items in the index of the details array, then we need to check if the previous item has a TSCode
						reviewList &&
						reviewList[currentReviewIndex] &&
						reviewList[currentReviewIndex].details &&
						reviewList[currentReviewIndex].details.length > 1
							? // we need to find the record of type 'Master Record'
							  reviewList[currentReviewIndex].details.find(
									(item) => item.RecordType === "MASTER RECORD"
							  ).PartStatusCode
							: null,
					overrideTSPartNumber: detail.LatestToolNumber,
					overrideTSCode: detail.LatestToolNumberTSCode,
				});

				addSelectedToolDetailAction({ partNumber, detail });
			}
		},
		[
			addSelectedToolDetailAction,
			removeSelectedToolDetailAction,
			selectedToolDetails,
			reviewList,
		]
	);

	const isSelected = (partNumber, detail) =>
		selectedToolDetails.some(
			(item) =>
				item.partNumber === partNumber &&
				item.detail.RecordType === detail.RecordType &&
				item.detail.Leap1A === detail.Leap1A &&
				item.detail.Leap1B === detail.Leap1B &&
				item.detail.Leap1C === detail.Leap1C &&
				item.detail.partDescription === detail.partDescription &&
				item.detail.PartStatusDescription === detail.PartStatusDescription &&
				item.detail.ReplacedBy === detail.ReplacedBy &&
				item.detail.LastRevision === detail.LastRevision &&
				item.detail.ENGINE === detail.ENGINE
		);

	const hasMoreReviews = currentReviewIndex < reviewList.length - 1;

	const currentItems = reviewList[currentReviewIndex];

	const HighlightText = ({ label, value, style }) => (
		<Text style={[styles.highlightText, style]}>
			{label}: <Text style={styles.boldText}>{value}</Text>
		</Text>
	);

	const DetailItem = ({ partNumber, detail }) => (
		<TouchableOpacity
			style={[
				styles.detailItem,
				isSelected(partNumber, detail) ? styles.detailItemSelected : {},
				{ marginBottom: 10, margin: 10, width: pxToDp(500) },
			]}
			onPress={() => handleSelectDetail(partNumber, detail)}
		>
			<HighlightText
				label="Record Type"
				style={styles.revisedHighlight}
				value={detail.RecordType}
			/>
			<HighlightText label="Engine" value={detail.ENGINE} />
			<HighlightText label="Description" value={detail.partDescription} />
			<HighlightText label="Status" value={detail.PartStatusCode} />
			<HighlightText label="Replaced By" value={detail.ReplacedBy || "N/A"} />
			<HighlightText label="Last Revision" value={detail.LastRevision} />
			<HighlightText
				label="Qualified Revision"
				value={detail.QualifiedRevision}
			/>
			<HighlightText label="FNF Code" value={detail.FnFCode} />
			<HighlightText label="Comments" value={detail.Comments} />
			<HighlightText label="Service Bulletin" value={detail.ServiceBulletin} />
		</TouchableOpacity>
	);

	return (
		<CustomModal
			style={styles.modalContainer}
			isVisible={reviewListModalActive}
			title={`Review Tools for  #${
				reviewList &&
				reviewList.length > 0 &&
				reviewList[currentReviewIndex] &&
				reviewList[currentReviewIndex].partNumber
			} ${
				reviewList && reviewList.length > 0 && reviewList.length > 1
					? `(${currentReviewIndex + 1}/${reviewList.length})`
					: ""
			}`}
			onClose={() => setReviewListModalActive(false)}
		>
			<ScrollView style={styles.scrollView}>
				<View style={{ flexDirection: "row", justifyContent: "center" }}>
					{currentItems && (
						<View style={styles.detailSection}>
							<Row>
								{currentItems.details.map((detail, index) => (
									<DetailItem
										key={index}
										partNumber={currentItems.partNumber}
										detail={detail}
									/>
								))}
							</Row>
						</View>
					)}
				</View>
				<View style={styles.actionButtons}>
					<Row>
						{hasMoreReviews ? (
							<>
								{currentItems && currentItems.details.length == 1 ? (
									<>
										<Col xs={12} md={6}>
											<Button
												style={styles.actionButton}
												fullWidth
												disabled={selectedToolDetails.length === 0}
												onPress={() => {
													setAreYouSureRemoveModalVisibleAction(true);
													// removeToolFromDeltaListThunk();
												}}
												color="#ab2328"
											>
												Remove
											</Button>
										</Col>
										<Col xs={12} md={6}>
											<Button
												style={styles.actionButton}
												fullWidth
												disabled={selectedToolDetails.length === 0}
												onPress={() => {
													setAreYouSureReviewAddVisibleAction(true);
													// addToolToMasterListThunk();
												}}
												color="#0088CE"
											>
												Add
											</Button>
										</Col>
									</>
								) : (
									<>
										<Col xs={12} md={6}>
											<Button
												color="#ab2328"
												style={styles.actionButton}
												fullWidth
												disabled={selectedToolDetails.length === 0}
												onPress={() => {
													// mergeToolToMasterThunk();
												}}
											>
												Do Not Merge
											</Button>
										</Col>
										<Col xs={12} md={6}>
											<Button
												style={styles.actionButton}
												fullWidth
												disabled={selectedToolDetails.length === 0}
												onPress={() => {
													setAreYouSureReviewModalVisibleAction(true);
													// mergeToolToMasterThunk();
												}}
											>
												Merge
											</Button>
										</Col>
									</>
								)}
							</>
						) : (
							<>
								{currentItems && currentItems.details.length == 1 ? (
									<>
										<Col xs={12} md={6}>
											<Button
												style={styles.actionButton}
												disabled={selectedToolDetails.length === 0}
												fullWidth
												onPress={() => {
													setAreYouSureRemoveModalVisibleAction(true);
													// removeToolFromDeltaListThunk();
													// handleAction();
												}}
												color="#ab2328"
											>
												Remove
											</Button>
										</Col>
										<Col xs={12} md={6}>
											<Button
												style={styles.actionButton}
												disabled={selectedToolDetails.length === 0}
												fullWidth
												onPress={() => {
													setAreYouSureReviewAddVisibleAction(true);
													// addToolToMasterListThunk();
													// handleAction();
												}}
												color="#0088CE"
											>
												Add
											</Button>
										</Col>
									</>
								) : (
									<>
										<Col xs={12} md={6}>
											<Button
												color="#ab2328"
												disabled={selectedToolDetails.length === 0}
												style={styles.actionButton}
												fullWidth
												onPress={() => {
													// mergeToolToMasterThunk();
												}}
											>
												Do Not Merge
											</Button>
										</Col>
										<Col xs={12} md={6}>
											<Button
												style={styles.actionButton}
												disabled={selectedToolDetails.length === 0}
												fullWidth
												onPress={() => {
													setAreYouSureReviewModalVisibleAction(true);
													// mergeToolToMasterThunk();
												}}
											>
												Merge
											</Button>
										</Col>
									</>
								)}
							</>
						)}
					</Row>
				</View>
			</ScrollView>
		</CustomModal>
	);
}

const styles = StyleSheet.create({
	scrollView: {
		maxHeight: 500,
	},
	modalContainer: {
		maxHeight: "80%",
		// width: width * 0.9,
	},
	sectionHeader: {
		fontWeight: "800",
		fontSize: 18,
		color: "#333",
		padding: 10,
		backgroundColor: "#f7f7f7",
	},
	detailSection: {
		borderColor: "#ddd",
		borderWidth: 1,
		borderRadius: 10,
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 5,
		marginVertical: 10,
		backgroundColor: "#fff",
		padding: 15,
	},
	detailItem: {
		padding: 10,
		paddingBottom: 10,
		borderBottomWidth: 1,
		borderBottomColor: "#f2f2f2",

		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.1,
		shadowRadius: 2,
		elevation: 2,
		marginVertical: 8,

		borderColor: "#ddd",
		borderWidth: 1,
		borderRadius: 5,

		padding: 10,

		backgroundColor: "#ffffff",
	},
	detailItemSelected: {
		backgroundColor: "#d0f0d0",
		borderRadius: 10,
	},
	highlightText: {
		fontSize: 16,
		color: "#333",
		paddingVertical: 2,
	},
	boldText: {
		fontWeight: "bold",
	},
	revisedHighlight: {
		color: "red",
		fontWeight: "bold",
	},
	divider: {
		backgroundColor: "#e0e0e0",
		marginVertical: 5,
	},
	actionButtons: {
		marginTop: 20,
		paddingHorizontal: 10,
		paddingBottom: 10,
	},
	actionButton: {
		flex: 1,
		marginHorizontal: 5,
	},
	closeButton: {
		backgroundColor: "#ccc",
	},
});
