import { createFilterHeaders } from "../../utilities/filter";

// Initial state for the integration module
const initialPayload = {
  integrationMessages: [],
};
const state = {
  integration: {
    list: [],
    listSearchInput: "",
    isComparingColumnFlagsIntegration: [false, false],
    chosenColumnToSortIndexIntegration: -1,
    filteredIntegrationList: [],
    filterIntegrationValues: ["", "", "", ""],
    chosenIntegrationComparisonIndexes: [-1, -1, -1, -1],
    pushNotificationPayload: initialPayload,
    headers: createFilterHeaders([
      {
        columnName: "Part Number",
        propertyName: "partNumber",
      },
      {
        columnName: "Part Description",
        propertyName: "partDescription",
      },
      {
        columnName: "Status Code",
        propertyName: "TSCode",
      },
      {
        columnName: "Engine Variant",
        propertyName: "enginevariant",
      },
      {
        columnName: "CRTL REPLACEMENT",
        subheaders: [
          {
            columnName: "Part Number",
            propertyName: "crtl_replaced_by_part_number",
          },
          {
            columnName: "Status Code",
            propertyName: "crtl_replaced_by_ts_code",
          },
          {
            columnName: "Qualification",
            propertyName: "crtl_replaced_by_qualification",
          },
        ],
      },
      {
        columnName: "RHINESTAHL OVERRIDE",
        subheaders: [
          {
            columnName: "Part Number",
            propertyName: "rhinestahl_override_partnumber",
          },
          {
            columnName: "Status Code",
            propertyName: "rhinestahl_override_tool_status_code",
          },
          {
            columnName: "Qualification",
            propertyName: "rhinestahl_override_qualification_code",
          },
        ],
      },
      {
        columnName: "Select",
        checkbox: true,
      },
    ]),
    currListPageNumber: 1,
  },
};

export default state;
