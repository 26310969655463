import { action } from "easy-peasy";
import { defaultState } from "./state";

const actions = {
	setRoleListAction: action((state, list) => {
		state.role.list = list;
	}),
	setRoleSearchInputAction: action((state, input) => {
		state.role.searchInput = input;
	}),
	setRoleInputAction: action((state, input) => {
		state.role.input = { ...state.role.input, ...input };
	}),
	resetRoleInputAction: action((state) => {
		state.role.input = defaultState.input;
	}),
	setAllowEditRoleAction: action((state, allowEdit) => {
		state.role.allowEdit = allowEdit;
	}),

	setIsComparingColumnFlagsRoleAction: action((state, flags) => {
		state.role.isComparingColumnFlagsRole = flags;
	}),
	setChosenColumnToSortIndexRoleAction: action((state, index) => {
		state.role.chosenColumnToSortIndexRole = index;
	}),
	setFilteredRoleListAction: action((state, list) => {
		state.role.filteredRoleList = list;
	}),

	setFilterRoleValuesAction: action((state, list) => {
		state.role.filterRoleValues = list;
	}),

	setChosenRoleComparisonIndexesAction: action((state, list) => {
		state.role.chosenRoleComparisonIndexes = list;
	}),
};

export default actions;
