const state = {
	status: {
		loading: false,
		alerts: [],
		areYouSureModalVisible: false,

		currentPage: 0,
		currentGroupedPage: 1,
	},
};

export default state;
