import { action } from "easy-peasy";
import { defaultState } from "./state";

const actions = {
  setLoginInputAction: action((state, input) => {
    state.auth.loginInput = {
      ...state.auth.loginInput,
      ...input,
    };
  }),
  resetLoginInputAction: action((state) => {
    state.auth.loginInput = defaultState.loginInput;
  }),
};

export default actions;
