import ColumnConfigurationForm from "./ColumnConfigurationForm";
import AddEngineFamilyForm from "./EngineFamilyForm/AddEngineFamilyForm";
import AddEngineVariantForm from "./EngineFamilyForm/AddEngineVariantForm";
import Form from "./Form";
import AddRoleForm from "./RoleForm/AddRoleForm";
import EditRoleForm from "./RoleForm/EditRoleForm";
import MasterToolForm from "./Tool/AddMasterToolForm";
import EditMasterToolForm from "./Tool/EditMasterToolForm";
import AddUserForm from "./UserForm/AddUserForm";
import EditUserForm from "./UserForm/EditUserForm";
import AddWorkScopeForm from "./WorkScope/AddWorkScopeForm";
import EditWorkScopeForm from "./WorkScope/EditWorkScopeForm";

Form.AddUser = AddUserForm;
Form.EditUser = EditUserForm;

Form.AddRole = AddRoleForm;
Form.EditRole = EditRoleForm;

Form.ColumnConfiguration = ColumnConfigurationForm;

Form.AddMasterTool = MasterToolForm;
Form.EditMasterTool = EditMasterToolForm;

Form.AddEngineFamily = AddEngineFamilyForm;
Form.AddEngineVariant = AddEngineVariantForm;

Form.AddWorkScope = AddWorkScopeForm;
Form.EditWorkScope = EditWorkScopeForm;

export default Form;
