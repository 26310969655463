import { useEffect, useState } from "react";
import { AntDesign } from "@expo/vector-icons";
import { Platform, StyleSheet, TouchableOpacity, View } from "react-native";
import Table from "./Table";
import Button from "./Button";
import Gap from "./Gap";
import Text from "./Text";
import { vhToDp } from "../../utilities/responsive";
import { useStoreActions, useStoreState } from "easy-peasy";

function List({
	sortDir = "asc",
	sortCol = null,
	tableHeaders = [],
	tableRows = [],
	striped = true,
	hover = true,
	border = true,
	pageSize = 10,
	height,
	maxHeight,

	...props
}) {
	const { currentPage } = useStoreState((state) => state.status);
	const { setCurrentPage } = useStoreActions((actions) => actions);
	const [sortInfo, setSortInfo] = useState({ sortCol, sortDir });
	// const [currentPage, setCurrentPage] = useState(0);
	const { setSortCol, setSortDir } = props;

	const totalPages = Math.ceil(tableRows.length / pageSize);

	const handleNextPage = () => {
		if (currentPage < totalPages - 1) {
			setCurrentPage(currentPage + 1);
		}
	};

	const handlePrevPage = () => {
		if (currentPage > 0) {
			setCurrentPage(currentPage - 1);
		}
	};
	const handleSort = (colIndex) => {
		setCurrentPage(0);
		let newSortInfo = { sortCol: colIndex, sortDir: "asc" };
		if (colIndex === sortInfo.sortCol) {
			newSortInfo.sortDir = sortInfo.sortDir === "asc" ? "desc" : "asc";
		}
		setSortInfo(newSortInfo);
	};

	const handleShowMore = () => {
		setCurrentPage(currentPage + 1);
	};

	const sortedRows = [...tableRows].sort((a, b) => {
		const { sortCol, sortDir } = sortInfo;
		if (sortCol === null) {
			return 0;
		}
		const aVal = a[sortCol];
		const bVal = b[sortCol];
		if (typeof aVal === "number" && typeof bVal === "number") {
			return sortDir === "asc" ? aVal - bVal : bVal - aVal;
		} else {
			const sortVal = aVal.toString().localeCompare(bVal.toString());
			return sortDir === "asc" ? sortVal : -sortVal;
		}
	});
	const startIndex = currentPage * pageSize;
	const endIndex = startIndex + pageSize;
	const paginatedRows = sortedRows.slice(startIndex, endIndex);
	// const paginatedRows = sortedRows.slice(0, (currentPage + 1) * pageSize);
	// const paginatedRows = sortedRows.slice(0, (currentPage + 1) * pageSize);

	useEffect(() => {
		if (sortCol !== null) {
			setSortDir("asc");
		}
	}, [sortCol]);

	return (
		<View
			style={
				{
					// height: `${vhToDp(70)}px`,
				}
			}
		>
			<Table
				{...props}
				style={{
					height: height ?? `${vhToDp(55)}px`,
					maxHeight: maxHeight ?? `${vhToDp(55)}px`,
				}}
			>
				<>
					<Table.tr fontFamily="Barlow_600SemiBold">
						{tableHeaders.map((children, index) => (
							<View
								style={{
									width: props.width
										? props.width[index]
										: tableHeaders.length
										? `${100 / tableHeaders.length}%`
										: "auto",
									height: 60,
								}}
								key={index}
							>
								<Table.th
									key={index}
									border={border}
									index={index}
									width={
										props.width
											? props.width[index]
											: tableHeaders.length
											? `${100 / tableHeaders.length}%`
											: "auto"
									}
									fontFamily="Barlow_600SemiBold"
								>
									{children}
									{index !== tableHeaders.length - 1 &&
										Platform.OS == "web" && (
											<TouchableOpacity
												type="button"
												onPress={() => handleSort(index)}
												aria-label={`Sort by ${children}`}
											>
												<AntDesign
													name="caretdown"
													size={10}
													color="gray"
													style={
														Platform.OS == "web" && {
															transform:
																sortInfo.sortCol === index
																	? sortInfo.sortDir === "desc"
																		? "rotate(180deg)"
																		: "none"
																	: "rotate(180deg)",
														}
													}
												/>
											</TouchableOpacity>
										)}
								</Table.th>
							</View>
						))}
					</Table.tr>
				</>
				<>
					{paginatedRows.map((row, index) => (
						<Table.tr
							key={index}
							striped={striped && index % 2 === 0}
							hover={hover}
						>
							{row.map((children, index) => (
								<View
									style={{
										width: props.width
											? props.width[index]
											: tableHeaders.length
											? `${100 / tableHeaders.length}%`
											: "auto",
										height: 60,
									}}
									key={index}
								>
									<Table.td
										key={index}
										border={border}
										index={index}
										width={props.width}
										// width={columnWidths}
										color={
											children === "Inactive" ||
											children === "CO" ||
											children === "Unavailable" ||
											children == "Broken Tool" ||
											children == "Unserviceable" ||
											children == "Checked Out"
												? "red"
												: children === "Active" ||
												  children === "Available" ||
												  children === "Configured"
												? "green"
												: children === "Reserved" || children === "Inspection"
												? "#c0b000"
												: "#00205b"
										}
									>
										{children}
									</Table.td>
								</View>
							))}
						</Table.tr>
					))}
				</>
			</Table>
			{/* {tableRows.length > pageSize &&
				paginatedRows.length < tableRows.length && (
					<>
						<Gap />
						<Button onPress={handleShowMore}>Show More</Button>
					</>
				)}
         */}
			<View style={styles.paginationContainer}>
				<TouchableOpacity onPress={handlePrevPage} disabled={currentPage === 0}>
					<AntDesign
						name="left"
						size={20}
						color={currentPage === 0 ? "gray" : "black"}
					/>
				</TouchableOpacity>
				<Text style={styles.pageInfo}>
					Page {currentPage + 1} of {totalPages}
				</Text>
				<TouchableOpacity
					onPress={handleNextPage}
					disabled={currentPage >= totalPages - 1}
				>
					<AntDesign
						name="right"
						size={20}
						color={currentPage >= totalPages - 1 ? "gray" : "black"}
					/>
				</TouchableOpacity>
			</View>
		</View>
	);
}

export default List;

const styles = StyleSheet.create({
	paginationContainer: {
		width: "100%",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "flex-end",
		padding: 10,
	},
	pageInfo: {
		marginHorizontal: 10,
	},
});
