import { action } from "easy-peasy";

const actions = {
  setLeapEngineModelAction: action((state, engineModel) => {
    state.leap.engineModel = engineModel;
  }),

  setEngineVariantAction: action((state, engineVariant) => {
    state.leap.engineVariant = engineVariant;
  }),

  setMasterLeapFileSelectionModalVisibleAction: action(
    (state, visible = false) => {
      state.leap.masterLeapFileSelectionModalVisible = visible;
    }
  ),

  setLeapFileChangesListAction: action((state, list) => {
    state.leap.leapFileChangesList = list;
  }),
  setAddOEMModalVisibleAction: action((state, visible) => {
    state.leap.oemModalVisible = visible;
  }),
  setNewColumnHeadersAction: action((state, headers) => {
    state.leap.newColumnHeaders = headers;

    let filecolumnNames = [];
    let filecolumnIDs = [];

    headers.forEach((item) => {
      filecolumnNames.push(item.header);
      filecolumnIDs.push(item.column);
    });

    state.leap.filecolumnNames = filecolumnNames;
    state.leap.filecolumnIDs = filecolumnIDs;
  }),

  setAddNewConfigurationModalVisibleAction: action((state, visible) => {
    state.leap.addNewConfigurationModalVisible = visible;
  }),

  setEditConfigurationModalVisibleAction: action((state, visible) => {
    state.leap.editConfigurationModalVisible = visible;
  }),
  setTableColumnNamesListAction: action((state, list) => {
    state.leap.tablecolumnNames = list;
  }),

  setUploadTypeAction: action((state, type) => {
    state.leap.uploadType = type;
  }),
  setColumnConfigOEMTypeAction: action((state, type) => {
    state.leap.columnConfigOEMType = type;
  }),
  setUploadEngineModelAction: action((state, model) => {
    state.leap.uploadEngineModel = model;
  }),

  setIsComparingColumnFlagsColumnConfigAction: action((state, flags) => {
    state.leap.isComparingColumnFlagsColumnConfig = flags;
  }),
  setChosenColumnToSortIndexColumnConfigAction: action((state, index) => {
    state.leap.chosenColumnToSortIndexColumnConfig = index;
  }),

  setFilterColumnConfigValuesAction: action((state, list) => {
    state.leap.filterColumnConfigValues = list;
  }),

  setChosenColumnConfigComparisonIndexesAction: action((state, list) => {
    state.leap.chosenColumnConfigComparisonIndexes = list;
  }),

  setIsComparingColumnFlagsClientConfigAction: action((state, flags) => {
    state.leap.isComparingColumnFlagsClientConfig = flags;
  }),
  setChosenColumnToSortIndexClientConfigAction: action((state, index) => {
    state.leap.chosenColumnToSortIndexClientConfig = index;
  }),

  setFilterClientConfigValuesAction: action((state, list) => {
    state.leap.filterClientConfigValues = list;
  }),

  setChosenClientConfigComparisonIndexesAction: action((state, list) => {
    state.leap.chosenClientConfigComparisonIndexes = list;
  }),
  setDefaultToLegendAction: action((state, bool) => {
    state.leap.defaultToLegend = bool;
  }),

  setAddToColumnConfigModalVisibleAction: action((state, visible) => {
    state.leap.addToColumnConfigModalVisible = visible;
  }),
  setCRTLImportingAction: action((state, bool) => {
    state.leap.CRTLImporting = bool;
  }),
  processCRTLImportAction: action((state, processedTool) => {
    state.leap.currentRowParsingIndex = processedTool.currentRowParsingIndex;
    state.leap.failedCRTLImportList = processedTool.totalToolsFailed;
    state.leap.successfulCRTLImportList = processedTool.totalToolsImported;
    state.leap.totalCRTLToolsProcessed =
      processedTool.totalToolsFailed + processedTool.totalToolsImported;

    !processedTool.success &&
      (state.leap.failedCRTLImportedToolsList = [
        ...state.leap.failedCRTLImportedToolsList,
        processedTool.tool,
      ]);
  }),
  setTotalCRTLToolsToImportAction: action((state, totalTools) => {
    state.leap.totalCRTLToolsToImport = totalTools;
  }),
  resetCRTLImportAction: action((state) => {
    state.leap.totalCRTLToolsToImport = 0;
    state.leap.totalCRTLToolsProcessed = 0;
    state.leap.successfulCRTLImportList = 0;
    state.leap.failedCRTLImportList = 0;
    state.leap.failedCRTLImportedToolsList = [];
    state.leap.remainingCRTLToolsToImport = 0;
  }),
  setIsPreparingControlFileAction: action((state, bool) => {
    state.leap.isPreparingControlFile = bool;
  }),
};

export default actions;
