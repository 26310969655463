import Page from "./Page";
import styled from "styled-components/native";
import Input from "../../presentational/Input";
import Center from "../../presentational/Center";
import Card from "../../presentational/Card";
import Button from "../Button";
import Gap from "../../presentational/Gap";
import {
	MaterialCommunityIcons,
	FontAwesome5,
} from "react-native-vector-icons";
import Text from "../../presentational/Text";
import Icon from "../../presentational/Icon";
import React from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { remToDp, vhToDp } from "../../../utilities/responsive";
import Drawer from "../../presentational/Drawer";

const SCard = styled(Card)`
	max-width: 600px;
`;

const Header = styled(Text)`
	font-size: ${remToDp(1.75)}px;
	text-align: center;
`;

const SInput = styled(Input)`
	text-align: center;
`;

function LoginPage() {
	const { email, password } = useStoreState((state) => state.auth.loginInput);

	const { setLoginInputAction, loginThunk } = useStoreActions(
		(actions) => actions
	);

	return (
		<Page>
			<Center.Screen>
				<SCard style={{ maxWidth: 600 }}>
					<Center>
						<Drawer.Brand height={vhToDp(12)} width="100%" />
					</Center>

					<Gap />

					<Header>Login</Header>

					<Gap />

					<SInput
						onChangeText={(email) => setLoginInputAction({ email })}
						value={email}
						placeholder="Type email"
						inputMode="email"
						leftIcon={<Icon Component={MaterialCommunityIcons} name="email" />}
						onSubmitEditing={() => loginThunk()}
					/>

					<Gap />

					<SInput
						onChangeText={(password) => setLoginInputAction({ password })}
						value={password}
						placeholder="Type password"
						secureTextEntry
						leftIcon={<Icon Component={FontAwesome5} name="lock" />}
						onSubmitEditing={() => loginThunk()}
					/>

					<Gap />

					<Button.Login />
				</SCard>
			</Center.Screen>
		</Page>
	);
}

export default LoginPage;
