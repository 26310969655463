import { useStoreActions } from "easy-peasy";
import Button from "../../../presentational/Button";
import { useNavigation } from "@react-navigation/native";

function UpsertUserButton({ children, ...props }) {
  const navigation = useNavigation();
  const { upsertUserThunk } = useStoreActions((actions) => actions);
  return (
    <Button fullWidth onPress={() => upsertUserThunk(navigation)} {...props}>
      {children}
    </Button>
  );
}

export default UpsertUserButton;
