import Page from "../../containers/Page";
import React, { useEffect } from "react";

import { View } from "react-native";
import styled from "styled-components/native";
import Button from "../../containers/Button/index";
import { useIsFocused } from "@react-navigation/native";
import { useStoreActions, useStoreState } from "easy-peasy";
import Row from "../../presentational/Row";
import SearchBar from "../../presentational/SearchBar";
import Col from "../../presentational/Col";
import List from "../../containers/List";

const Container = styled(View)`
	padding: 2vh 4vw;
`;

const SRow = styled(Row)`
	align-items: center;
`;

function OEMFamilyManagementPage({ navigation }) {
	const {
		leap: { type },
		file: { oemToEngineFamilySearchInput },
	} = useStoreState((state) => state);

	const {
		getOEMToEngineFamilyListThunk,
		setOEMToEngineFamilySearchInputAction,
		getOEMListThunk,
	} = useStoreActions((actions) => actions);

	const isFocused = useIsFocused();

	useEffect(() => {
		if (isFocused) {
			getOEMListThunk();
			getOEMToEngineFamilyListThunk();
		}
	}, [isFocused, type]);

	return (
		<Page.Protected>
			<Container>
				<SRow>
					{/* <Col xs={12} sm={8}>
						<SearchBar
							onChangeText={(searchInput) => {
								setOEMToEngineFamilySearchInputAction(searchInput);
							}}
							value={oemToEngineFamilySearchInput}
							placeholder="Search tools by name"
						/>
					</Col> */}
					<Col xs={12} sm={12}>
						<Button
							fullWidth
							onPress={() => {
								navigation.push("Add OEM to Engine Family");
							}}
						>
							Manage OEM Engine Family
						</Button>
					</Col>
				</SRow>
				<List.OEMToEngineFamily navigation={navigation} />
			</Container>
		</Page.Protected>
	);
}

export default OEMFamilyManagementPage;
