import React, { useState } from "react";
import { FlatList, TouchableOpacity, StyleSheet } from "react-native";
import styled from "styled-components/native";
import Text from "./Text";
import { Input as RNInput } from "@rneui/themed";
import { height } from "../../utilities/general";
import { MaterialIcons } from "@expo/vector-icons";
import DropdownOverlay from "./Overlay";
import { View } from "react-native";

const SInput = styled(RNInput)`
	background-color: "none";
	padding: 0 10px;
	font-family: Barlow_400Regular;
	width: 100%;
	color: #00205b;
`;
const Label = styled(Text)`
	color: #00205b;
`;

const VariantsBox = styled.View`
	padding: 10px;
	margin: 3px;
	border: 0.6px solid #ced4da;
	border-radius: 4px;
`;

const SearchIcon = styled(MaterialIcons).attrs({
	name: "search",
	size: 20,
	color: "#00205b",
	alignSelf: "center",
})`
	margin-left: 10px;
`;

function CombinedInputDropdown({
	label,
	data,
	placeholder,
	onSelect,
	...props
}) {
	const [filteredList, setFilteredList] = useState([]);
	const [inputValue, setInputValue] = useState("");

	const filterItems = (text) => {
		setInputValue(text);
		if (!text.trim()) {
			setFilteredList([]);
		} else {
			const filtered = data.filter(
				(item) => item && item.toLowerCase().includes(text.toLowerCase())
			);
			setFilteredList(filtered);
		}
	};

	const handleSelectItem = (item) => {
		onSelect(item);
		setInputValue(item);
		setFilteredList([]);
	};

	return (
		<View>
			{label && <Label>{label}</Label>}

			<SInput
				placeholderTextColor="#76787A"
				editable={true}
				{...props}
				rightIconContainerStyle={{ position: "absolute", right: 0 }}
				style={{
					color: "#00205b",
				}}
				rightIcon={<SearchIcon />}
				value={inputValue}
				placeholder={placeholder}
				onChangeText={filterItems}
			/>
			{filteredList.length > 0 && (
				<FlatList
					data={filteredList}
					contentContainerStyle={{
						maxHeight: height * 0.3,
						height: height * 0.3,
						// 	position: "absolute",
						//
						// 	height: 500,
						// 	width: 500,
						// 	backgroundColor: "red",
						// 	zIndex: 10000,
					}}
					renderItem={({ item, _i }) => (
						<TouchableOpacity key={_i} onPress={() => handleSelectItem(item)}>
							<VariantsBox>
								<Text>{item}</Text>
							</VariantsBox>
						</TouchableOpacity>
					)}
					keyExtractor={(item) => item}
				/>
			)}
		</View>
	);
}

export default CombinedInputDropdown;
