import { useStoreActions, useStoreState } from "easy-peasy";
import * as DocumentPicker from "expo-document-picker";
import { useEffect } from "react";
import { formatStringForComparison } from "../utilities/string";
import { useIsFocused } from "@react-navigation/native";
import { downloadFile } from "../utilities/file";
import { useTableFilter } from "./filter";

const firstPageNum = 1;

function useShowUploadWorkscopeFileButton(navigation) {
  const { setShowUploadWorkScopeFileModalAction } = useStoreActions(
    (actions) => actions
  );

  return {
    children: "Work Scope File",
    setShowModal: () => {
      navigation.navigate("Home");
      setShowUploadWorkScopeFileModalAction(true);
      navigation.closeDrawer();
    },
    color: "#0088CE",
  };
}

function useUploadWorkScopeFileModal() {
  const {
    workscope: { showUploadWorkScopeFileModal },
    user: { authenticatedUser },
  } = useStoreState((state) => state);

  const {
    setShowUploadWorkScopeFileModalAction,
    uploadWorkScopeFileThunk,
    setAlertThunk,
  } = useStoreActions((actions) => actions);

  return {
    isVisible: showUploadWorkScopeFileModal,
    onClose: () => setShowUploadWorkScopeFileModalAction(false),
    title: "Work Scope File Options",
    downloadButton: {
      children: "Download Work Scope File Template",
      props: {
        onPress: () => {
          try {
            downloadFile(
              [
                {
                  header: "Work Scope Description",
                  key: "workscopedescription",
                },
                {
                  header: "Engine",
                  key: "engine",
                },
                {
                  header: "Engine Model",
                  key: "enginemodel",
                },
                {
                  header: "Technical Manual Reference",
                  key: "technicalmanualreference",
                },
                {
                  header: "Work Package Description",
                  key: "workpackagedescription",
                },
                {
                  header: "Part Number",
                  key: "partnumber",
                },
                {
                  header: "Tool Nomenclature",
                  key: "toolnomenclature",
                },
                {
                  header: "Quantity",
                  key: "quantity",
                },
                {
                  header: "Wip Tool",
                  key: "wiptool",
                },
                {
                  header: "Use With",
                  key: "usewith",
                },
                {
                  header: "Tool Code",
                  key: "toolcode",
                },
                {
                  header: "Qualified Revision",
                  key: "qualifiedrevision",
                },
                {
                  header: "CE/UK CA",
                  key: "ceukca",
                },
                {
                  header: "Tools Function",
                  key: "toolsfunction",
                },
                {
                  header: "Line",
                  key: "line",
                },
                {
                  header: "Module",
                  key: "module",
                },
                {
                  header: "Shop",
                  key: "shop",
                },
                {
                  header: "Repair",
                  key: "repair",
                },
                {
                  header: "LMT Other",
                  key: "lmtother",
                },
                {
                  header: "Duplicate",
                  key: "duplicate",
                },
                {
                  header: "TPM Comments",
                  key: "tpmcomments",
                },
                {
                  header: "Dimensions",
                  key: "dimensions",
                },
                {
                  header: "Weight",
                  key: "weight",
                },
                {
                  header: "Load Test",
                  key: "loadtest",
                },
                {
                  header: "Calibration",
                  key: "calibration",
                },
              ],
              [],
              {
                sheetName: "workscope",
                filename: "Work_Scope_Master_Tools_Sample_Upload_File.xlsx",
                fileType:
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                creatorName: authenticatedUser.userName,
              }
            );
          } catch (err) {
            setAlertThunk({
              type: "error",
              message: err.message || err,
            });
          }
        },
        fullWidth: true,
        color: "#0088CE",
      },
    },
    uploadButton: {
      children: "Upload Work Scope File",
      props: {
        onPress: async () => {
          const document = await DocumentPicker.getDocumentAsync({
            type: "json/csv",
          });

          uploadWorkScopeFileThunk({
            ...document,
            base64: document.uri.split(",")[1],
          });
        },
        fullWidth: true,
      },
    },
  };
}

function useWorkScopeMasterToolTable() {
  const {
    list,
    workScopeMasterToolListSearchInput,
    currWorkScopeMasterToolListPageNumber,
    workScopeMasterToolHeaders,
    engineInput,
    engineModelInput,
    workScopeNameInput,
  } = useStoreState((state) => state.workscope);

  const isFocused = useIsFocused();

  const {
    setCurrWorkScopeMasterToolListPageNumberAction,
    getWorkScopeMasterToolListThunk,
    updateWorkScopeMasterToolHeaderAction,
  } = useStoreActions((actions) => actions);

  useEffect(() => {
    isFocused && getWorkScopeMasterToolListThunk();
  }, [isFocused, engineInput, engineModelInput, workScopeNameInput]);

  function reloadListByPageNumber(pageNumber) {
    getWorkScopeMasterToolListThunk(pageNumber);
    setCurrWorkScopeMasterToolListPageNumberAction(pageNumber);
  }

  const { filteredList, ...tableProps } = useTableFilter({
    headers: workScopeMasterToolHeaders,
    list,
    updateHeaderFunction: updateWorkScopeMasterToolHeaderAction,
    reloadListByPageNumberFunction: reloadListByPageNumber,
    currPageNumber: currWorkScopeMasterToolListPageNumber,
    setPageNumberFunction: setCurrWorkScopeMasterToolListPageNumberAction,
  });

  return {
    ...tableProps,
    list: filteredList.filter((tool) =>
      formatStringForComparison(tool.partnumber).includes(
        formatStringForComparison(workScopeMasterToolListSearchInput)
      )
    ),
    isScrollableHorizontally: true,
  };
}

function useWorkScopeToolListControlForm() {
  const {
    selectionOptions,
    engineInput,
    engineModelInput,
    workScopeNameInput,
    workScopeMasterToolListSearchInput,
  } = useStoreState((state) => state.workscope);

  const {
    getWorkScopeSelectionOptionsThunk,
    setEngineModelInputAction,
    setEngineInputAction,
    setWorkScopeNameInputAction,
    getWorkScopeMasterToolListThunk,
    setCurrWorkScopeMasterToolListPageNumberAction,
    setWorkScopeMasterToolListSearchInputAction,
    getOEMToEngineFamilyListThunk,
  } = useStoreActions((actions) => actions);

  useEffect(() => {
    getWorkScopeSelectionOptionsThunk();
    getOEMToEngineFamilyListThunk();
  }, []);

  const defaultItem = "All";

  function onSelect(callback) {
    return (option) => {
      callback(option === defaultItem ? "" : option);
      setCurrWorkScopeMasterToolListPageNumberAction(1);
      getWorkScopeMasterToolListThunk(1);
    };
  }

  function value(input) {
    return input || defaultItem;
  }

  function filterByEngine(option) {
    return !engineInput || option.engine === engineInput;
  }

  function removeDuplicateChoices(mapFn, filterFn = () => true) {
    return [
      defaultItem,
      ...[...new Set(selectionOptions.filter(filterFn).map(mapFn))],
    ];
  }

  return {
    engineInput: {
      label: "Engine",
      onSelect: onSelect((option) => {
        setEngineInputAction(option);
        setEngineModelInputAction("");
        setWorkScopeNameInputAction("");
      }),
      value: value(engineInput),
      dropdownChoices: removeDuplicateChoices((option) => option.engine),
    },
    engineModelInput: {
      label: "Engine Model",
      onSelect: onSelect((option) => {
        setEngineModelInputAction(option);
        setWorkScopeNameInputAction("");
      }),
      value: value(engineModelInput),
      dropdownChoices: removeDuplicateChoices(
        (option) => option.enginemodel,
        filterByEngine
      ),
    },
    workPackageNameInput: {
      label: "Work Scope Name",
      onSelect: onSelect((option) => setWorkScopeNameInputAction(option)),
      value: value(workScopeNameInput),
      dropdownChoices: removeDuplicateChoices(
        (option) => option.workpackagename,
        (option) =>
          filterByEngine(option) &&
          (!engineModelInput || option.enginemodel === engineModelInput)
      ),
    },
    searchBar: {
      onChangeText: (searchInput) =>
        setWorkScopeMasterToolListSearchInputAction(searchInput),
      value: workScopeMasterToolListSearchInput,
      placeholder: "Search workscope by part number",
      onSubmitEditing: () => {
        getWorkScopeMasterToolListThunk(firstPageNum);
        setCurrWorkScopeMasterToolListPageNumberAction(firstPageNum);
      },
    },
    selectedItem: (option) => option,
    rowTextForSelection: (option) => option,
    width: "99%",
    required: true,
    editable: true,
    dropdown: true,
  };
}

function useDownloadWorkScopeCsvButton() {
  const { exportWorkScopeToolListCsvThunk } = useStoreActions(
    (actions) => actions
  );

  return {
    children: "Generate Work Scope CSV",
    onPress: () => exportWorkScopeToolListCsvThunk(),
  };
}

export {
  useShowUploadWorkscopeFileButton,
  useUploadWorkScopeFileModal,
  useWorkScopeMasterToolTable,
  useWorkScopeToolListControlForm,
  useDownloadWorkScopeCsvButton,
};
