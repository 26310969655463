import { View } from "react-native";
import styled from "styled-components/native";
import Row from "../../presentational/Row";
import Button from "../../containers/Button";
import Col from "../../presentational/Col";
import Page from "../../containers/Page";
import SearchBar from "../../containers/SearchBar";
import React, { useEffect } from "react";
import List from "../../containers/List";
import Gap from "../../presentational/Gap";
import { useStoreActions } from "easy-peasy";
import { useIsFocused } from "@react-navigation/native";

const Container = styled(View)`
	padding: 2vh 4vw;
`;

const SRow = styled(Row)`
	align-items: center;
`;

function RoleListPage(props) {
	const { getRolesThunk } = useStoreActions((actions) => actions);

	const isFocused = useIsFocused();

	useEffect(() => {
		if (isFocused) {
			getRolesThunk();
		}
	}, [isFocused]);

	return (
		<Page.Protected {...props}>
			<Container>
				<SRow>
					<Col xs={12} sm={2}>
						<Button.GoToCreateRole>Add Role</Button.GoToCreateRole>
					</Col>
					<Col xs={12} sm={10}>
						<SearchBar.Role />
					</Col>
				</SRow>

				<Gap />

				<List.Role />
			</Container>
		</Page.Protected>
	);
}

export default RoleListPage;
