function removeDuplicateChoices(array, prop) {
  const seen = new Set();
  return array.filter((item) => {
    const val = item[prop];
    if (seen.has(val)) {
      return false;
    } else {
      seen.add(val);
      return true;
    }
  });
}

export { removeDuplicateChoices };
