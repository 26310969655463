import { View } from "react-native";
import styled from "styled-components/native";
import Row from "../../presentational/Row";
import Button from "../../containers/Button";
import Col from "../../presentational/Col";
import Page from "../../containers/Page";
import React, { useEffect } from "react";
import List from "../../containers/List";
import Gap from "../../presentational/Gap";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useIsFocused } from "@react-navigation/native";
import Input from "../../presentational/Input";
import SearchBar from "../../presentational/SearchBar";

const Container = styled(View)`
	padding: 2vh 4vw;
`;

const SRow = styled(Row)`
	align-items: center;
`;

function ExceptionToolListPage(props) {
	const {
		leap: { type },
		file: { oemList },

		tool: { exceptionListSearchInput },
	} = useStoreState((state) => state);
	const { getExceptionToolListThunk, setExceptionListSearchInputAction } =
		useStoreActions((actions) => actions);

	const isFocused = useIsFocused();

	useEffect(() => {
		if (isFocused) {
			getExceptionToolListThunk(type == "All" ? "" : type);
		}
	}, [isFocused, type]);

	return (
		<Page.Protected {...props}>
			<Container>
				<SRow>
					<Col xs={12} sm={10}>
						<SearchBar
							onChangeText={(searchInput) => {
								setExceptionListSearchInputAction(searchInput);
							}}
							value={exceptionListSearchInput}
							placeholder="Search tools by name"
						/>
					</Col>
				</SRow>

				<Gap />

				<List.Exceptions />
			</Container>
		</Page.Protected>
	);
}

export default ExceptionToolListPage;
