import { useEffect } from "react";
import Form from "../../containers/Form";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useIsFocused } from "@react-navigation/native";
import styled from "styled-components/native";
import { vhToDp, vwToDp } from "../../../utilities/responsive";
import Page from "../../containers/Page";
import Button from "../../presentational/Button";
import EditMasterToolEngineModal from "../../containers/Modal/EditMasterToolEngineModal";

const Container = styled.View`
	padding: ${vhToDp(2)}px ${vwToDp(4)}px;
`;

function EditMasterToolPage({ navigation, ...props }) {
	const {
		tool: { masterToolIsEditable, masterToolInput },
		file: { engineVariantList },
	} = useStoreState((state) => state);
	const {
		upsertMasterToolThunk,
		setMasterToolIsEditableAction,
		setSelectedEngineVariantsAction,
	} = useStoreActions((actions) => actions);

	const isFocused = useIsFocused();

	useEffect(() => {
		if (isFocused) {
			setMasterToolIsEditableAction(false);
		}
	}, [isFocused]);

	return (
		<Page.Protected>
			<Container>
				<EditMasterToolEngineModal />
				<Form.EditMasterTool
					buttons={[
						<Button
							fullWidth
							onPress={async () => {
								await upsertMasterToolThunk({ navigation });
								navigation.goBack();
							}}
						>
							Save Changes
						</Button>,
					]}
					{...props}
				/>
			</Container>
		</Page.Protected>
	);
}

export default EditMasterToolPage;
