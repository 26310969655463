import { useStoreActions, useStoreState } from "easy-peasy";
import CustomModal from "../../presentational/Modal";
import Button from "../Button";
import Text from "../../presentational/Text";
import Row from "../../presentational/Row";
import Col from "../../presentational/Col";
import Gap from "../../presentational/Gap";

export default function FileUploadedModal({ navigation }) {
	const {
		tool: { fileUploadedModalVisible },
		leap: { uploadType },
	} = useStoreState((state) => state);

	const {
		setFileUploadedModalVisibleAction,
		upsertLeapFileDifferencesThunk,
		getToolListDeltasThunk,
		resetCRTLImportAction,
	} = useStoreActions((actions) => actions);

	const handleOnSubmit = () => {
		upsertLeapFileDifferencesThunk(uploadType);
		setFileUploadedModalVisibleAction(false);
		resetCRTLImportAction();
		navigation.navigate("Tool Delta");
		getToolListDeltasThunk(uploadType);
	};

	return (
		<CustomModal
			style={{ width: 500 }}
			width="50%"
			height="50%"
			title="Confirmation"
			onClose={() => setFileUploadedModalVisibleAction(false)}
			isVisible={fileUploadedModalVisible}
		>
			<Text style={{ fontSize: 20, fontWeight: "bold", alignSelf: "center" }}>
				File Uploaded Successfully
			</Text>
			<Gap />
			<Text style={{ fontSize: 20, fontWeight: "bold", alignSelf: "center" }}>
				Would you like to process these tools?
			</Text>
			<Row>
				<Col xs={12} sm={12}>
					<Button
						color="#ab2328"
						fullWidth
						style={{ marginTop: 20 }}
						onPress={handleOnSubmit}
					>
						Confirm
					</Button>
				</Col>
			</Row>
		</CustomModal>
	);
}
