import { SearchBar as RNSearchBar } from "@rneui/themed";
import EntypoIcon from "react-native-vector-icons/Entypo";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";
import styled from "styled-components/native";
import Icon from "./Icon";
import { useStoreActions } from "easy-peasy";
import { TouchableOpacity } from "react-native";

const SSearchBar = styled(RNSearchBar)`
  padding: 0 16px;
  width: 100%;
`;

function SearchBar(props) {
  const { setCurrentPage, setCurrentGroupedPage } = useStoreActions(
    (actions) => actions
  );

  return (
    <SSearchBar
      containerStyle={{
        backgroundColor: "white",
        borderTopWidth: 0,
        borderBottomWidth: 0,
        width: "100%",
      }}
      inputContainerStyle={{ backgroundColor: "#F7F7F7", width: "100%" }}
      searchIcon={<Icon Component={EntypoIcon} name="magnifying-glass" />}
      clearIcon={
        <TouchableOpacity
          onPress={() => {
            props.onChangeText("");
          }}
        >
          <Icon Component={MaterialIcons} name="clear" />
        </TouchableOpacity>
      }
      {...props}
      onFocus={() => {
        setCurrentPage(0);
        setCurrentGroupedPage(1);
      }}
    />
  );
}

export default SearchBar;
