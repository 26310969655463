import { thunk } from "easy-peasy";
import { getRoleApiCall, upsertRoleApiCall } from "../../api/role";
import {
  deleteFileMappingAPICall,
  getEngineFamilyListAPICall,
  getEngineVariantListAPICall,
  getFileMappingAPICall,
  getOEMHeadersAPICall,
  getOEMListAPICall,
  getOEMToEngineFamilyListAPICall,
  removeFileMappingAsAdminAPICall,
  upsertEngineFamilyAPICall,
  upsertEngineVariantAPICall,
  upsertFileMappingAPICall,
  upsertOEMAPICall,
  upsertOEMHeadersAPICall,
  upsertOEMToEngineFamilyAPICall,
} from "../../api/file";

const thunks = {
  getFileChangesThunk: thunk(async (actions, file, helpers) => {
    actions.setLoadingAction(true);

    try {
      let r = await getFileMappingAPICall({
        OEMID: file,
        engFamilyID: helpers.getState().leap.uploadEngineModel,
      });
      actions.setFileListAction(r.data);
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: "error",
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
  upsertFileChangesThunk: thunk(async (actions, index, helpers) => {
    actions.setLoadingAction(true);

    try {
      if (helpers.getState().leap.leapFileChangesList.length > 0) {
        await upsertFileMappingAPICall({
          ...helpers.getState().file.fileChangesInput,
          OEMID: helpers.getState().file.fileChangesInput.OEMID,
          engFamilyID: helpers.getState().file.fileChangesInput.engFamilyID,
          paramorder: helpers.getState().file.leapFileChangesList.length + 1,
          defaulttolegendcode: helpers.getState().leap.defaultToLegend,
        });
      } else {
        let arr = [
          {
            id: null,
            OEMID: helpers.getState().file.fileChangesInput.OEMID,
            engFamilyID: helpers.getState().file.fileChangesInput.engFamilyID,
            filecolumnName: "",
            filecolumnID: "",
            fileType: "",
            tablecolumnName: "OEM",
            propertyName: "OEM",
            legendCode: "",
            propertyType: "varchar(50)",
            paramorder: 1,
            defaulttolegendcode: false, //helpers.getState().leap.defaultToLegend,
          },
          {
            id: null,
            OEMID: helpers.getState().file.fileChangesInput.OEMID,
            engFamilyID: helpers.getState().file.fileChangesInput.engFamilyID,
            filecolumnName: "",
            filecolumnID: "",
            fileType: "",
            tablecolumnName: "OEM File Name",
            propertyName: "OEMFileName",
            legendCode: "",
            propertyType: "varchar(50)",
            paramorder: 2,
            defaulttolegendcode: false, //helpers.getState().leap.defaultToLegend,
          },
          {
            id: null,
            OEMID: helpers.getState().file.fileChangesInput.OEMID,
            engFamilyID: helpers.getState().file.fileChangesInput.engFamilyID,
            filecolumnName: "",
            filecolumnID: "",
            fileType: "",
            tablecolumnName: "Engine Family ID",
            propertyName: "EngineFamilyID",
            legendCode: "",
            propertyType: "varchar(50)",
            paramorder: 3,
            defaulttolegendcode: false, //helpers.getState().leap.defaultToLegend,
          },
          { ...helpers.getState().file.fileChangesInput, paramorder: 4 },
        ];
        arr.map(async (item, i) => {
          await upsertFileMappingAPICall({
            ...item,
            id: null,
            OEMID: helpers.getState().file.fileChangesInput.OEMID,
            engFamilyID: helpers.getState().file.fileChangesInput.engFamilyID,
            defaulttolegendcode: helpers.getState().leap.defaultToLegend,
          });
        });
        await actions.getLeapFileChangesThunk(
          helpers.getState().file.fileChangesInput.OEMID
        );
      }
      // await actions.getFileChangesThunk(
      // 	helpers.getState().file.fileChangesInput.OEMID

      actions.setDefaultToLegendAction(false);
      await actions.getLeapFileChangesThunk(
        helpers.getState().file.fileChangesInput.OEMID
      );
      actions.setFileChangesInputAction({
        ...helpers.getState().file.fileChangesInput,
        filecolumnName: "",
        filecolumnID: "",
        tablecolumnName: "",
        legendCode: "",
        propertyName: "",
        defaulttolegendcode: false, ///helpers.getState().leap.defaultToLegend,
      });
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: "error",
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),

  getOEMListThunk: thunk(async (actions, _, helpers) => {
    actions.setLoadingAction(true);

    try {
      let r = await getOEMListAPICall();
      actions.setOEMListAction(r.data);
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: "error",
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),

  getEngineFamilyListThunk: thunk(async (actions, _, helpers) => {
    actions.setLoadingAction(true);

    try {
      let r = await getEngineFamilyListAPICall();
      actions.setEngineFamilyListAction(r.data);
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: "error",
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),

  getEngineVariantListThunk: thunk(async (actions, _, helpers) => {
    actions.setLoadingAction(true);

    try {
      let r = await getEngineVariantListAPICall();
      actions.setEngineVariantListAction(r.data);
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: "error",
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),

  getOEMToEngineFamilyListThunk: thunk(async (actions, _, helpers) => {
    actions.setLoadingAction(true);

    try {
      let r = await getOEMToEngineFamilyListAPICall();
      actions.setOEMToEngineFamilyListAction(r.data);
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: "error",
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),

  upsertOEMThunk: thunk(async (actions, _, helpers) => {
    actions.setLoadingAction(true);

    try {
      let r = await upsertOEMAPICall(helpers.getState().file.upsertOEMInput);
      actions.setOEMToFamilyInputAction({ oemid: r.data.OEMID });
      actions.getOEMListThunk();
      actions.setAlertThunk({
        type: "success",
        message: "OEM added Successfully",
      });
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: "error",
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),

  upsertFileOEMThunk: thunk(async (actions, _, helpers) => {
    actions.setLoadingAction(true);

    try {
      let r = await upsertOEMAPICall(helpers.getState().file.upsertOEMInput);

      actions.setOEMToFamilyInputAction({
        ...helpers.getState().file.upsertOEMToFamilyInput,
        oemid: r.data.OEMID,
      });
      actions.setOemToFamilyCurrentStepAction(
        helpers.getState().file.oemToFamilyCurrentStep + 1
      );
      actions.setAlertThunk({
        type: "success",
        message: "OEM added Successfully",
      });
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: "error",
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),

  upsertEditFileOEMThunk: thunk(async (actions, _, helpers) => {
    actions.setLoadingAction(true);

    try {
      let r = await upsertOEMAPICall(helpers.getState().file.upsertOEMInput);

      actions.setOEMToFamilyInputAction({
        ...helpers.getState().file.upsertOEMToFamilyInput,
        oemid: r.data.OEMID,
      });
      actions.setEditOemToFamilyCurrentStepAction(
        helpers.getState().file.oemToFamilyEditCurrentStep + 1
      );
      actions.setAlertThunk({
        type: "success",
        message: "OEM edited Successfully",
      });
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: "error",
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),

  upsertEngineFamilyThunk: thunk(async (actions, _, helpers) => {
    actions.setLoadingAction(true);

    try {
      let r = await upsertEngineFamilyAPICall(
        helpers.getState().file.upsertEngineFamilyInput
      );
      actions.setLeapEngineModelAction(r.data.EngineFamily);
      actions.setUploadEngineModelAction(r.data.id);
      actions.setOEMToFamilyInputAction({
        ...helpers.getState().file.upsertOEMToFamilyInput,
        enginefamilyid: r.data.id,
      });
      actions.getEngineFamilyListThunk();
      actions.setAlertThunk({
        type: "success",
        message: "Engine Family added Successfully",
      });
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: "error",
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),

  upsertEngineVariantThunk: thunk(async (actions, _, helpers) => {
    actions.setLoadingAction(true);

    try {
      const r = await upsertEngineVariantAPICall({
        ...helpers.getState().file.upsertEngineVariantInput,
        enginefamilyid: helpers.getState().leap.uploadEngineModel,
      });
      actions.setUpsertEngineVariantInputAction({
        ...helpers.getState().file.upsertOEMToFamilyInput,
        id: r.data.id,
        enginefamilyid: helpers.getState().leap.uploadEngineModel,
        enginevariant: r.data.EngineVariant,
      });
      actions.setUpsertEngineFamilyInputAction({
        id: r.data.id,
        engineVariant: r.data.EngineVariant,
      });
      actions.setOEMToFamilyInputAction({
        engineVariant: r.data.EngineVariant,
      });
      actions.getEngineVariantListThunk();
      actions.setAlertThunk({
        type: "success",
        message: "Engine Variant added Successfully",
      });
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: "error",
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),

  upsertOEMToFamilyThunk: thunk(async (actions, navigation, helpers) => {
    actions.setLoadingAction(true);

    try {
      await upsertOEMToEngineFamilyAPICall(
        helpers.getState().file.upsertOEMToFamilyInput
      );
      navigation.goBack();

      actions.setAlertThunk({
        type: "success",
        message: "OEM to Family added Successfully",
      });
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: "error",
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),

  upsertOEMHeadersThunk: thunk(async (actions, navigation, helpers) => {
    actions.setLoadingAction(true);

    try {
      await upsertOEMHeadersAPICall(
        helpers.getState().file.upsertOEMHeadersInput
      );

      actions.setAlertThunk({
        type: "success",
        message: "OEM headers added Successfully",
      });
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: "error",
        message: "OEM to Family added Successfully",
      });
    }

    actions.setLoadingAction(false);
  }),
  getOEMHeadersListThunk: thunk(async (actions, navigation, helpers) => {
    actions.setLoadingAction(true);

    try {
      let r = await getOEMHeadersAPICall({
        OEMID: helpers.getState().file.fileChangesInput.OEMID,
        engFamilyID: helpers.getState().file.fileChangesInput.engFamilyID,
      });

      actions.setOEMFileHeadersAction(r.data);
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: "error",
        message: "OEM to Family added Successfully",
      });
    }

    actions.setLoadingAction(false);
  }),

  editFileChangesThunk: thunk(async (actions, index, helpers) => {
    actions.setLoadingAction(true);

    try {
      await upsertFileMappingAPICall({
        ...helpers.getState().file.fileChangesInput,
        id: helpers.getState().file.fileChangesInput.id,
        paramorder: helpers.getState().file.editHeadersIndex + 1,
      });

      actions.setFileChangesInputAction({
        ...helpers.getState().file.fileChangesInput,
        filecolumnName: "",
        filecolumnID: "",
        tablecolumnName: "",
        legendCode: "",
      });
      await actions.getLeapFileChangesThunk(helpers.getState().leap.uploadType);

      actions.setAlertThunk({
        type: "success",
        message: "File Changes edited Successfully",
      });
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: "error",
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),

  deleteFileChangesRowThunk: thunk(async (actions, index, helpers) => {
    actions.setLoadingAction(true);

    try {
      await deleteFileMappingAPICall({
        id: helpers.getState().file.fileChangesInput.id,
        OEMID: helpers.getState().file.fileChangesInput.OEMID,
        engFamilyID: helpers.getState().file.fileChangesInput.engFamilyID,
        // ...helpers.getState().file.list[index],
        // isdeleted: true,
      });

      actions.setDeleteFileChangesRowAreYouSureModalVisibleAction(false);

      await actions.getLeapFileChangesThunk(helpers.getState().leap.uploadType);
      actions.setAlertThunk({
        type: "success",
        message: "File Changes deleted Successfully",
      });
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: "error",
        message: "Unable to delete File Changes",
      });
    }

    actions.setLoadingAction(false);
  }),

  removeCRTLFileMappingAsAdminThunk: thunk(async (actions, index, helpers) => {
    actions.setLoadingAction(true);

    try {
      await removeFileMappingAsAdminAPICall({
        OEMID: helpers.getState().file.fileChangesInput.OEMID,
        engFamilyID: helpers.getState().file.fileChangesInput.engFamilyID,
      });

      actions.setDeleteFileChangesRowAreYouSureModalVisibleAction(false);

      await actions.getLeapFileChangesThunk(helpers.getState().leap.uploadType);
      actions.setAlertThunk({
        type: "success",
        message: "File Changes deleted Successfully",
      });
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: "error",
        message: "Unable to delete File Changes",
      });
    }

    actions.setLoadingAction(false);
  }),
};

export default thunks;
