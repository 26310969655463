import { useStoreActions, useStoreState } from "easy-peasy";
import Button from "../../../presentational/Button";
import { useNavigation } from "@react-navigation/native";
import WorkScopeForm from "./WorkScopeForm";

function AddWorkScopeForm(props) {
	const { upsertWorkScopeFileThunk } = useStoreActions((actions) => actions);
	const navigation = useNavigation();
	return (
		<WorkScopeForm
			buttons={[
				<Button
					fullWidth
					onPress={async () => {
						await upsertWorkScopeFileThunk();
						navigation.goBack();
					}}
				>
					Add Status
				</Button>,
			]}
			{...props}
		/>
	);
}

export default AddWorkScopeForm;
