import { useStoreActions, useStoreState } from "easy-peasy";
import CustomModal from "../../presentational/Modal";
import Button from "../Button";
import Text from "../../presentational/Text";
import Row from "../../presentational/Row";
import Col from "../../presentational/Col";
import * as DocumentPicker from "expo-document-picker";
import Input from "../../presentational/Input";
import { height, width } from "../../../utilities/general";
import CombinedInputDropdown from "../../presentational/SelectInputField";
import { useState } from "react";
import Gap from "../../presentational/Gap";
import { Divider } from "react-native-elements";
import FileChangesList from "../List/FileChangeList";
import { View } from "react-native-web";
import { vhToDp } from "../../../utilities/responsive";
import ExitFileModal from "./ExitFileModal";
import Switch from "../../presentational/Switch";

export default function AddNewConfigItemModal({ ...props }) {
	const {
		leap: {
			tablecolumnNames,
			leapFileChangesList,
			defaultToLegend,
			addToColumnConfigModalVisible,
		},
		file: { fileChangesInput, list, oemFileHeaders },
	} = useStoreState((state) => state);
	const {
		setFileChangesInputAction,

		setEditConfigurationModalVisibleAction,
		editFileChangesThunk,
		setDefaultToLegendAction,
		setAddToColumnConfigModalVisibleAction,
	} = useStoreActions((actions) => actions);

	return (
		<CustomModal
			style={{
				width: width * 0.9,
				maxHeight: height * 0.8,
			}}
			width="50%"
			height="50%"
			title="Add Configuration"
			onClose={() => setAddToColumnConfigModalVisibleAction(false)}
			isVisible={addToColumnConfigModalVisible}
		>
			<ExitFileModal />

			<Row>
				<Col xs={12} md={6}>
					{/* <CombinedInputDropdown */}
					<Input
						placeholder={`Select File Column Name`}
						label={"File Column Name"}
						// dropdownChoices={filecolumnNames.filter((item) => {
						// 	return !list.some((file) => file.filecolumnName === item);
						// })}
						dropdownChoices={oemFileHeaders.map((item) => {
							return item.filecolumnName;
						})}
						// dropdownChoices={oemFileHeaders.map ((item) => {
						// 	return

						// }
						onSelect={(value) =>
							setFileChangesInputAction({ filecolumnName: value })
						}
						dropdown
						property={"filecolumnName"}
						selectedItem={(item) => item}
						value={fileChangesInput.filecolumnName}
						rowTextForSelection={(item) => item}
						search
					/>
				</Col>
				<Col xs={12} md={6}>
					{/* <CombinedInputDropdown */}
					<Input
						placeholder={`Select Column ID`}
						label={"Column ID"}
						// data={filecolumnIDs.filter((item) => {
						// 	return !list.some((file) => file.filecolumnID === item);
						// })}
						// dropdownChoices={filecolumnIDs.filter((item) => {
						// 	return !list.some((file) => file.filecolumnID === item);
						// })}
						dropdownChoices={oemFileHeaders.map((item) => {
							return item.filecolumnID;
						})}
						onSelect={(value) =>
							setFileChangesInputAction({ filecolumnID: value })
						}
						value={fileChangesInput.filecolumnID}
						dropdown
						selectedItem={(item) => item}
						rowTextForSelection={(item) => item}
						search
					/>
				</Col>
			</Row>
			<Row>
				<Col xs={12} md={6}>
					{/* <CombinedInputDropdown */}
					<Input
						placeholder={`Select Table Column Name`}
						label={"Table Column Name"}
						dropdownChoices={tablecolumnNames.filter((item) => {
							return (
								item.TableColumnName === "EngineVariant" ||
								(item.TableColumnName !== "OEMID" &&
									item.TableColumnName !== "OEM File Name" &&
									item.TableColumnName !== "EngineFamilyID" &&
									!leapFileChangesList.some(
										(file) => file.tablecolumnName === item.TableColumnName
									))
							);
						})}
						onSelect={(value) => {
							if (
								defaultToLegend &&
								value.TableColumnName === "EngineVariant"
							) {
								setFileChangesInputAction({
									legendCode: fileChangesInput.filecolumnName,
								});
							}
							setFileChangesInputAction({
								...fileChangesInput,
								tablecolumnName: value.TableColumnName,
								propertyName: value.TableColumnName.replace(/ /g, ""),
								propertyType: value.TableDatatype,
							});
						}}
						search
						dropdown
						property={"tablecolumnName"}
						selectedItem={(item) => item.TableColumnName}
						value={fileChangesInput.tablecolumnName}
						rowTextForSelection={(item) => item.TableColumnName}
					/>
				</Col>
				<Col xs={12} md={6}>
					<Input
						placeholder={`Select Legend Code`}
						label={"Legend Code"}
						onChangeText={(value) => {
							setFileChangesInputAction({ legendCode: value });
						}}
						value={fileChangesInput.legendCode}
					/>
				</Col>
			</Row>
			<Col xs={12}>
				<Switch
					label="Default to Legend Code as Engine Variant"
					onValueChange={(active) => {
						setFileChangesInputAction({ defaulttolegendcode: active });

						setDefaultToLegendAction(active);
					}}
					value={defaultToLegend}
				/>
			</Col>
			<Row>
				<Col xs={12} md={12}>
					<Button
						color="#00205B"
						fullWidth
						style={{ marginTop: 20 }}
						onPress={async () => {
							editFileChangesThunk();

							setEditConfigurationModalVisibleAction(false);
						}}
					>
						Add Row
					</Button>
				</Col>
			</Row>
			{leapFileChangesList.length > 0 && (
				<FileChangesList
					height={`${vhToDp(35)}px`}
					maxHeight={`${vhToDp(35)}px`}
				/>
			)}
		</CustomModal>
	);
}
