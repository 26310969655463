import { axios } from ".";
import { convertJsonToQueryString } from "../utilities/general";

const workscopeAxios = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}/workpackage`,
});

function getWorkScopeListAPICall(queryString) {
  return workscopeAxios({
    method: "get",
    url: `/workscope/get?${convertJsonToQueryString(queryString)}`,
  });
}

function upsertWorkScopeAPICall(data) {
  return workscopeAxios({ method: "put", url: "/workscope/upsert", data });
}

function upsertWorkScopeFileAPICall(data) {
  return workscopeAxios({ method: "put", url: "/workscope/file/upsert", data });
}

function upsertWorkScopeToolAPICall(data) {
  return workscopeAxios({ method: "put", url: "/workscope/tool/upsert", data });
}

function upsertWorkPackageAPICall(data) {
  return workscopeAxios({ method: "put", url: "/workscope/file/upsert", data });
}

function upsertWorkPackageItemAPICall(data) {
  return workscopeAxios({ method: "put", url: "/workscope/file/upsert", data });
}

function getWorkScopeAPICall(queryString) {
  return workscopeAxios({
    method: "get",
    url: `/workscope/get?${convertJsonToQueryString(queryString)}`,
  });
}

function getWorkScopeToolListAPICall(queryString) {
  return workscopeAxios({
    method: "get",
    url: `/workscope/toolist/get?${convertJsonToQueryString(queryString)}`,
  });
}

async function getWorkScopeMasterToolListAPICall(jsonQuery) {
  const response = await workscopeAxios({
    method: "get",
    url: `/workscope/tool/master/list/get?${convertJsonToQueryString(
      jsonQuery
    )}`,
  });
  return response.data;
}

function getWorkPackageAPICall(queryString) {
  return workscopeAxios({
    method: "get",
    url: `/get?${convertJsonToQueryString(queryString)}`,
  });
}

async function uploadWorkScopeFileApiCall(data) {
  const response = await workscopeAxios({
    method: "put",
    url: "/workscope/file/upload",
    data,
  });
  return response.data;
}

async function getWorkScopeSelectionOptionsApiCall() {
  const response = await workscopeAxios({
    method: "get",
    url: "/workscope/selection/options",
  });
  return response.data;
}

export {
  workscopeAxios,
  upsertWorkScopeFileAPICall,
  getWorkScopeListAPICall,
  getWorkScopeToolListAPICall,
  upsertWorkScopeToolAPICall,
  upsertWorkScopeAPICall,
  getWorkScopeMasterToolListAPICall,
  getWorkPackageAPICall,
  getWorkScopeAPICall,
  upsertWorkPackageAPICall,
  upsertWorkPackageItemAPICall,
  uploadWorkScopeFileApiCall,
  getWorkScopeSelectionOptionsApiCall,
};
