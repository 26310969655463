import { useStoreState, useStoreActions } from "easy-peasy";
import List from "../../presentational/List";
import { FontAwesome5 } from "@expo/vector-icons";
import Icon from "../../presentational/Icon";
import Card from "../../presentational/Card";
import { useNavigation } from "@react-navigation/native";
import { formatStringForComparison } from "../../../utilities/string";
import { TouchableOpacity } from "react-native";

function FileChangesList({ ...props }) {
	const { leapFileChangesList } = useStoreState((state) => state.leap);

	return (
		<List
			pageSize={4}
			tableHeaders={[
				"filecolumnName",
				"filecolumnID",
				"tablecolumnName",
				"legendCode",
			]}
			tableRows={leapFileChangesList
				.filter((item) => {
					return (
						item.tablecolumnName !== "OEM" &&
						item.tablecolumnName !== "OEM File Name" &&
						item.tablecolumnName !== "Engine Family ID"
					);
				})
				.map((role) => [
					role.filecolumnName,
					role.filecolumnID,
					role.tablecolumnName,
					role.legendCode,
				])}
			{...props}
		/>
		// </Card>
	);
}

export default FileChangesList;
