import { thunk } from "easy-peasy";
import {
  getAuthenticatedUserApiCall,
  getUserAccessControlListApiCall,
  getUsersApiCall,
  upsertUserApiCall,
} from "../../api/user";
import { getAuthToken } from "../../utilities/api";

const thunks = {
  getAuthenticatedUserThunk: thunk(async (actions) => {
    actions.setLoadingAction(true);

    try {
      if (await getAuthToken()) {
        const response = await getAuthenticatedUserApiCall();
        actions.setAuthenticatedUserAction(response.data);
      }
    } catch (err) {
      actions.setAlertThunk({
        type: "error",
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
  getUserThunk: thunk(async (actions, id) => {
    actions.setLoadingAction(true);

    try {
      const response = await getUsersApiCall(id);
      actions.setUserInputAction({
        ...response.data,
        oemaccess: response.data.oemaccess?.split(",") || [],
        enginefamilyaccess: response.data.enginefamilyaccess?.split(",") || [],
        enginevariantaccess:
          response.data.enginevariantaccess?.split(",") || [],
        password: "",
      });
    } catch (err) {
      actions.setAlertThunk({
        type: "error",
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
  getUsersThunk: thunk(async (actions) => {
    actions.setLoadingAction(true);

    try {
      if (await getAuthToken()) {
        const response = await getUsersApiCall(0);
        actions.setUserListAction(response.data);
      }
    } catch (err) {
      actions.setAlertThunk({
        type: "error",
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
  upsertUserThunk: thunk(async (actions, navigation, helpers) => {
    actions.setLoadingAction(true);

    try {
      const response = await upsertUserApiCall({
        ...helpers.getState().user.input,
        oemaccess: helpers.getState().user.updatedOEMList?.join(",") || "",
        engfamilyaccess:
          helpers.getState().user.updatedEngineFamilyList?.join(",") || "",
        engvariantaccess:
          helpers.getState().user.updatedEngineVariantList?.join(",") || "",
      });
      actions.setUserInputAction(response.data);
      navigation.navigate("Home");

      actions.setAlertThunk({
        type: "success",
        message: "User successfully saved",
      });
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: "error",
        message: err?.response?.data,
      });
    }

    actions.setLoadingAction(false);
  }),
  getUserAccessControlThunk: thunk(async (actions, userid, helpers) => {
    actions.setLoadingAction(true);

    try {
      const response = await getUserAccessControlListApiCall(
        userid || helpers.getState().user.authenticatedUser.id
      );
      actions.setAccessControlListAction(response.data);
    } catch (err) {
      actions.setAlertThunk({
        type: "error",
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
};

export default thunks;
