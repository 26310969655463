import { useStoreActions, useStoreState } from "easy-peasy";
import List from "../../presentational/List";
import { FontAwesome5 } from "@expo/vector-icons";
import Icon from "../../presentational/Icon";
import Card from "../../presentational/Card";
import { useNavigation } from "@react-navigation/native";
import { formatStringForComparison } from "../../../utilities/string";
import PaginatedList from "../../presentational/PaginatedList";

function ToolStatusList(props) {
	const {
		statusList,
		statusListSearchInput,
		isComparingColumnFlagsToolStatus,
		chosenColumnToSortIndexToolStatus,
		filterToolStatusValues,
		chosenToolStatusComparisonIndexes,
	} = useStoreState((state) => state.tool);
	const {
		setToolStatusInputAction,

		setIsComparingColumnFlagsToolStatusAction,
		setChosenColumnToSortIndexToolStatusAction,
		setFilteredToolStatusListAction,
		setFilterToolStatusValuesAction,
		setChosenToolStatusComparisonIndexesAction,
	} = useStoreActions((actions) => actions);
	const navigation = useNavigation();

	return (
		<Card>
			<PaginatedList
				chosenComparisonIndexes={chosenToolStatusComparisonIndexes}
				setChosenComparisonIndexes={setChosenToolStatusComparisonIndexesAction}
				filterValues={filterToolStatusValues}
				setFilterValues={setFilterToolStatusValuesAction}
				setChosenColumnToSortIndex={setChosenColumnToSortIndexToolStatusAction}
				chosenColumnToSortIndex={chosenColumnToSortIndexToolStatus}
				isComparingColumnFlags={isComparingColumnFlagsToolStatus}
				setIsComparingColumnFlags={setIsComparingColumnFlagsToolStatusAction}
				width={["20%", "70%", "10%"]}
				pageSize={8}
				// tableHeaders={["Status Code", "Status Description", "Edit"]}
				headers={[
					{ columnName: "Status Code", propertyName: "tscode" },
					{ columnName: "Status Description", propertyName: "tsdescription" },
					{ columnName: "Edit", propertyName: "tscode" },
				]}
				list={statusList
					.filter(
						(user) =>
							formatStringForComparison(user.tscode).includes(
								formatStringForComparison(statusListSearchInput)
							) ||
							formatStringForComparison(user.tsdescription).includes(
								formatStringForComparison(statusListSearchInput)
							)
					)
					.map((user) => ({
						tscode: user.tscode,
						tsdescription: user.tsdescription,
						edit: (
							<Icon
								Component={FontAwesome5}
								name="edit"
								onPress={() => {
									setToolStatusInputAction({
										id: user.tskey,
										OEMid: user.oemid,
										EngineFamilyID: user.enginefamiliy,
										TSCode: user.tscode,
										TSDescription: user.tsdescription,
									});
									navigation.navigate("Edit Tool Status", { id: user.id });
								}}
							/>
						),
					}))}
				{...props}
			/>
		</Card>
	);
}

export default ToolStatusList;
