import { action } from "easy-peasy";
import { updateHeaderInList } from "../../utilities/filter";

const actions = {
  setIntegrationListAction: action((state, loading) => {
    state.integration.list = loading;
  }),
  setIsComparingColumnFlagsIntegrationAction: action((state, flags) => {
    state.integration.isComparingColumnFlagsIntegration = flags;
  }),
  setChosenColumnToSortIndexIntegrationAction: action((state, index) => {
    state.integration.chosenColumnToSortIndexIntegration = index;
  }),
  setFilteredIntegrationListAction: action((state, list) => {
    state.integration.filteredIntegrationList = list;
  }),

  setFilterIntegrationValuesAction: action((state, list) => {
    state.integration.filterIntegrationValues = list;
  }),

  setChosenIntegrationComparisonIndexesAction: action((state, list) => {
    state.integration.chosenIntegrationComparisonIndexes = list;
  }),

  setIntegrationListSearchInputAction: action((state, input) => {
    state.integration.listSearchInput = input;
  }),
  addToolToIntegrationPayloadAction: action((state, tool) => {
    if (typeof tool === "object" && tool !== null) {
      // tool.id = state.tool.reviewList.length + 1;
      state.integration.pushNotificationPayload.integrationMessages.push(tool);
    } else {
      console.error('Expected "tool" to be an object, but got:', tool);
    }
  }),

  removeToolFromIntegrationPayloadAction: action((state, tool) => {
    if (typeof tool === "object" && tool !== null) {
      state.integration.pushNotificationPayload.integrationMessages =
        state.integration.pushNotificationPayload.integrationMessages.filter(
          (item) => item.partNumber !== tool.partNumber
        );
    } else {
      console.error('Expected "tool" to be an object, but got:', tool);
    }
  }),

  setPushNotificationPayloadAction: action((state, payload) => {
    state.integration.pushNotificationPayload = payload;
  }),
  updatePushNotificationHeaderAction: action(
    (state, { updatedTableHeader, parentHeaderIndex, subheaderIndex }) => {
      state.integration.headers = updateHeaderInList(
        updatedTableHeader,
        state.integration.headers,
        parentHeaderIndex,
        subheaderIndex
      );
    }
  ),
  setCurrentListPageNumberAction: action((state, pageNumber) => {
    state.integration.currListPageNumber = pageNumber;
  }),
};

export default actions;
