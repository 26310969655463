import { useEffect } from "react";
import Form from "../../containers/Form";
import { useStoreActions } from "easy-peasy";
import { useIsFocused } from "@react-navigation/native";
import styled from "styled-components/native";
import { vhToDp, vwToDp } from "../../../utilities/responsive";
import Page from "../../containers/Page";
import Button from "../../presentational/Button";

const Container = styled.View`
	padding: ${vhToDp(2)}px ${vwToDp(4)}px;
`;

function AddMasterToolPage({ navigation, ...props }) {
	const {
		upsertMasterToolThunk,
		resetMasterToolInputAction,
		setSelectedEngineVariantsAction,
	} = useStoreActions((actions) => actions);

	const isFocused = useIsFocused();

	useEffect(() => {
		if (isFocused) {
			setSelectedEngineVariantsAction("");

			resetMasterToolInputAction();
		}
	}, [isFocused]);

	return (
		<Page.Protected>
			<Container>
				<Form.AddMasterTool
					buttons={[
						<Button
							fullWidth
							onPress={async () => {
								await upsertMasterToolThunk({ navigation });
								navigation.goBack();
							}}
						>
							Add Master Tool
						</Button>,
					]}
					{...props}
				/>
			</Container>
		</Page.Protected>
	);
}

export default AddMasterToolPage;
