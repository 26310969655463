import { useStoreActions, useStoreState } from 'easy-peasy';
import List from '../../presentational/List';
import {
  FontAwesome5,
  Ionicons,
  MaterialCommunityIcons,
} from '@expo/vector-icons';
import Icon from '../../presentational/Icon';
import Card from '../../presentational/Card';
import { useNavigation } from '@react-navigation/native';
import { formatStringForComparison } from '../../../utilities/string';
import Text from '../../presentational/Text';
import Td from '../../presentational/Table/Td';
import { Touchable, TouchableOpacity, View } from 'react-native';
import { CheckBox } from '@rneui/themed';
import { remToDp } from '../../../utilities/responsive';

function ReviewIngestionList(props) {
  const { list, searchInput, configFilter, reviewList } = useStoreState(
    (state) => state.tool
  );
  const { addToolToReviewListAction, removeToolFromReviewListAction } =
    useStoreActions((actions) => actions);

  const navigation = useNavigation();

  return (
    <List
      pageSize={7}
      width={['10%', '15%', '54%', '12%', '9%']}
      tableHeaders={[
        'Engine',
        'Part Number',
        'Part Description',
        'Status Code',
        // 'Replaced By',
        // 'Actions',
        'Remove',
      ]}
      tableRows={reviewList.map((item) => [
        <Text
          style={{
            fontWeight: 'bold',
            color:
              item.ColorCode === 'R'
                ? 'red'
                : item.ColorCode === 'Y'
                ? '#c0b000'
                : 'green',
          }}
        >
          {item.ENGINE}
        </Text>,
        item.partNumber,
        item.partDescription,
        item.PartStatusCode,
        // item.ReplacedBy,
        // <View style={{ flex: 1, flexDirection: 'row' }}>
        //   <CheckBox
        //     checked={reviewList && reviewList.includes(item)}
        //     checkedColor="#007AFF"
        //     onPress={() => {
        //       if (reviewList && reviewList.includes(item)) {
        //         removeToolFromReviewListAction(item);
        //       } else {
        //         addToolToReviewListAction(item);
        //       }
        //     }}
        //     checkedIcon={
        //       <FontAwesome5
        //         name="check-square"
        //         size={remToDp(1.5)}
        //         color="#007AFF"
        //       />
        //     }
        //     iconType="material-community"
        //     uncheckedIcon={'checkbox-blank-outline'}
        //     containerStyle={{ backgroundColor: 'none' }}
        //   />
        // </View>,
        <TouchableOpacity onPress={() => removeToolFromReviewListAction(item)}>
          <MaterialCommunityIcons name="cancel" size={20} color="#ab2328" />
        </TouchableOpacity>,
      ])}
      {...props}
    />
  );
}

export default ReviewIngestionList;
