import React from "react";
import { Overlay } from "@rneui/themed";
import { View, StyleSheet, ActivityIndicator } from "react-native";
import Text from "./Text";

const CustomModal = ({
	isVisible,
	title,
	children,
	loading,
	onClose,
	...props
}) => {
	return (
		<Overlay
			isVisible={isVisible}
			onBackdropPress={onClose}
			overlayStyle={styles.overlay}
		>
			<View style={styles.container} {...props}>
				<View style={styles.titleContainer}>
					<Text style={styles.title}>{title}</Text>
				</View>

				<View style={styles.content}>
					{loading ? (
						<View {...props}>
							<ActivityIndicator size="large" color="#0088ce" />
						</View>
					) : (
						children
					)}
				</View>
			</View>
		</Overlay>
	);
};

const styles = StyleSheet.create({
	overlay: {
		borderRadius: 10,
	},
	container: {
		flex: 1,
	},
	titleContainer: {
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center",
		borderBottomWidth: 1,
		borderColor: "#ccc",
		paddingBottom: 10,
	},
	title: {
		fontSize: 20,
		fontWeight: "bold",
	},
	content: {
		marginTop: 10,
	},
});

export default CustomModal;
