const defaultState = {
  type: "All",
  engineModel: "",
  engineVariant: "",
  importedList: [],
  failedImportList: [],
  masterLeapFileSelectionModalVisible: false,
  leapFileChangesList: [],
  filecolumnNames: [],
  filecolumnIDs: [],
  tablecolumnNames: [],
  legendCodes: [],
  oemModalVisible: false,

  newColumnHeaders: [],
  addNewConfigurationModalVisible: false,
  editConfigurationModalVisible: false,
  uploadType: "",

  columnConfigOEMType: "",
  uploadEngineModel: "",

  isComparingColumnFlagsColumnConfig: [false, false],
  chosenColumnToSortIndexColumnConfig: -1,
  filterColumnConfigValues: ["", "", "", "", ""],
  chosenColumnConfigComparisonIndexes: [-1, -1, -1, -1, -1],

  isComparingColumnFlagsClientConfig: [false, false],
  chosenColumnToSortIndexClientConfig: -1,
  filterClientConfigValues: ["", "", "", "", "", "", "", "", "", "", "", ""],
  chosenClientConfigComparisonIndexes: [
    -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
  ],
  defaultToLegend: false,

  addToColumnConfigModalVisible: false,
  CRTLImporting: false,
  totalCRTLToolsToImport: 0,
  totalCRTLToolsProcessed: 0,
  successfulCRTLImportList: 0,
  failedCRTLImportList: 0,
  failedCRTLImportedToolsList: [],
  remainingCRTLToolsToImport: 0,
  currentRowParsingIndex: 1,
  isPreparingControlFile: false,
};

const state = {
  leap: defaultState,
};

export { state as default, defaultState };
