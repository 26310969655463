import { useStoreActions, useStoreState } from "easy-peasy";
import CustomModal from "../../presentational/Modal";
import Button from "../Button";
import Col from "../../presentational/Col";
import * as DocumentPicker from "expo-document-picker";
import Input from "../../presentational/Input";
import { getOEMNameFromList, width } from "../../../utilities/general";
import ExcelJS from "exceljs";
import { useState } from "react";
import Gap from "../../presentational/Gap";

export default function AddOEMModal({ ...props }) {
  const {
    leap: { oemModalVisible, uploadEngineModel, engineModel },
    file: { fileChangesInput, engineFamilyList, oemList },
  } = useStoreState((state) => state);
  const {
    setAddOEMModalVisibleAction,
    setNewColumnHeadersAction,
    setAddNewConfigurationModalVisibleAction,
    setFileChangesInputAction,
    setUploadEngineModelAction,
    setLeapEngineModelAction,
    setUploadTypeAction,
    setUpsertOEMHeadersInputAction,
    upsertOEMHeadersThunk,
  } = useStoreActions((actions) => actions);

  const [ssn, setSSN] = useState("");

  const getHeadersAndColumnLettersFromExcel = async (base64File, sheetName) => {
    return new Promise((resolve, reject) => {
      const workbook = new ExcelJS.Workbook();
      const buffer = Buffer.from(base64File, "base64");

      workbook.xlsx
        .load(buffer)
        .then(() => {
          const worksheet = workbook.getWorksheet(sheetName);

          if (!worksheet) {
            reject(`Worksheet named '${sheetName}' not found`);
            return;
          }

          const firstRow = worksheet.getRow(1);
          const headers = firstRow.values
            .map((cell, index) => {
              if (!cell) return null; // Skip undefined or null values
              const columnLetter = worksheet.getColumn(index).letter; // Get column letter
              const headerValue = typeof cell === "object" ? cell.text : cell;
              return {
                column: columnLetter,
                header: headerValue,
              };
            })
            .filter((header) => header !== null); // Remove any null values

          // take all the header.colums and make a comma delimited string for the filecolumnIDs
          const filecolumnIDs = headers
            .map((header) => header.column)
            .join(",");
          // take all the header.headers and make a comma delimited string for the filecolumnNames
          const filecolumnNames = headers
            .map((header) => header.header)
            .join(",");
          setUpsertOEMHeadersInputAction({
            OEMID: fileChangesInput.OEMID,
            engFamilyID: fileChangesInput.engFamilyID,
            filecolumnNames: filecolumnNames,
            filecolumnIDs: filecolumnIDs,
          });

          setNewColumnHeadersAction(headers);
          resolve(headers);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  return (
    <CustomModal
      style={{ width: width * 0.5 }}
      width="50%"
      height="50%"
      title="Add New OEM File Headers"
      onClose={() => setAddOEMModalVisibleAction(false)}
      isVisible={oemModalVisible}
    >
      <Col>
        <Input
          width="99%"
          label="OEM"
          placeholder="Enter OEM"
          value={getOEMNameFromList(oemList, fileChangesInput.OEMID)}
          onSelect={(name) => {
            setUploadTypeAction(name.OEMID);
            setFileChangesInputAction({ OEMID: name.OEMID });
          }}
          required
          editable={true}
          dropdown
          dropdownChoices={oemList}
          selectedItem={(item) => item.OEM}
          rowTextForSelection={(item) => item.OEM}
          buttonTextAfterSelection={(item) => item.OEM}
        />
      </Col>
      <Col>
        <Input
          width="99%"
          label="Engine Model"
          placeholder="Enter Engine Model"
          onSelect={(name) => {
            setFileChangesInputAction({
              engFamilyID: name.EngineFamilyID,
            });
            setLeapEngineModelAction(name.EngineFamily);
            setUploadEngineModelAction(name.EngineFamilyID);
          }}
          value={
            engineFamilyList.find(
              (e) => e.EngineFamilyID == fileChangesInput.engFamilyID
            )?.EngineFamily !== undefined
              ? engineFamilyList.find(
                  (e) => e.EngineFamilyID == fileChangesInput.engFamilyID
                ).EngineFamily
              : ""
          }
          required
          editable={true}
          dropdown
          dropdownChoices={engineFamilyList}
          selectedItem={(item) => item.EngineFamily}
          rowTextForSelection={(item) => item.EngineFamily}
        />
      </Col>
      <Gap />
      <Col>
        <Button
          color="#00205B"
          fullWidth
          onPress={async () => {
            const document = await DocumentPicker.getDocumentAsync({
              type: "json/csv",
            });
            const b64 = document.uri.split(",")[1];

            await getHeadersAndColumnLettersFromExcel(b64, engineModel);

            upsertOEMHeadersThunk();
            setAddOEMModalVisibleAction(false);
            setAddNewConfigurationModalVisibleAction(true);
          }}
        >
          Upload Headers
        </Button>
      </Col>
    </CustomModal>
  );
}
