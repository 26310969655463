import { useEffect } from "react";
import Form from "../../containers/Form";
import { useStoreActions } from "easy-peasy";
import { useIsFocused } from "@react-navigation/native";
import styled from "styled-components/native";
import { vhToDp, vwToDp } from "../../../utilities/responsive";
import Page from "../../containers/Page";
import EditToolStatusForm from "../../containers/Form/ToolStatus/EditToolStatusForm";

const Container = styled.View`
	padding: ${vhToDp(2)}px ${vwToDp(4)}px;
`;

function EditToolStatusPage(props) {
	const { getRolesThunk, resetUserInputAction, setAllowEditUserAction } =
		useStoreActions((actions) => actions);

	const isFocused = useIsFocused();

	return (
		<Page.Protected>
			<Container>
				<EditToolStatusForm {...props} />
			</Container>
		</Page.Protected>
	);
}

export default EditToolStatusPage;
