import { useStoreActions, useStoreState } from "easy-peasy";
import List from "../../presentational/List";
import { FontAwesome5 } from "@expo/vector-icons";
import Icon from "../../presentational/Icon";
import Card from "../../presentational/Card";
import { useNavigation } from "@react-navigation/native";
import { formatStringForComparison } from "../../../utilities/string";
import PaginatedList from "../../presentational/PaginatedList";

function RoleList(props) {
	const {
		list,
		searchInput,

		isComparingColumnFlagsRole,
		chosenColumnToSortIndexRole,
		filterRoleValues,
		chosenRoleComparisonIndexes,
	} = useStoreState((state) => state.role);

	const {
		setIsComparingColumnFlagsRoleAction,
		setChosenColumnToSortIndexRoleAction,
		setFilterRoleValuesAction,
		setChosenRoleComparisonIndexesAction,
	} = useStoreActions((actions) => actions);

	const navigation = useNavigation();

	return (
		<Card>
			{/* <List */}
			<PaginatedList
				chosenComparisonIndexes={chosenRoleComparisonIndexes}
				setChosenComparisonIndexes={setChosenRoleComparisonIndexesAction}
				filterValues={filterRoleValues}
				setFilterValues={setFilterRoleValuesAction}
				setChosenColumnToSortIndex={setChosenColumnToSortIndexRoleAction}
				chosenColumnToSortIndex={chosenColumnToSortIndexRole}
				isComparingColumnFlags={isComparingColumnFlagsRole}
				setIsComparingColumnFlags={setIsComparingColumnFlagsRoleAction}
				tableHeaders={["Name", "Description", "Edit"]}
				headers={[
					{
						columnName: "Name",
						propertyName: "name",
					},
					{
						columnName: "Description",
						propertyName: "description",
					},
					{
						columnName: "Edit",
						propertyName: "description",
					},
				]}
				list={list
					.filter((role) =>
						formatStringForComparison(role.name).includes(
							formatStringForComparison(searchInput)
						)
					)
					.map((role) => ({
						name: role.name,
						description: role.description,
						edit: (
							<Icon
								Component={FontAwesome5}
								name="edit"
								onPress={() =>
									navigation.navigate("Edit Role", { id: role.id })
								}
							/>
						),
					}))}
				{...props}
			/>
		</Card>
	);
}

export default RoleList;
