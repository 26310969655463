import { useStoreState } from "easy-peasy";
import UserForm from "./UserForm";
import Button from "../../Button";

function AddUserForm(props) {
  const { userName, email, password, cpassword, firstName, lastName } =
    useStoreState((state) => state.user.input);

  return (
    <UserForm
      buttons={[
        <Button.GoToUsers>Cancel</Button.GoToUsers>,
        <Button.UpsertUser
          disabled={
            !userName ||
            !email ||
            password !== cpassword ||
            !firstName ||
            !lastName ||
            !password
          }
        >
          Add User
        </Button.UpsertUser>,
      ]}
      {...props}
    />
  );
}

export default AddUserForm;
