import styled from "rn-css";
import Button from "../Button";
import { View } from "react-native";
import Drawer from "../../presentational/Drawer";
import Gap from "../../presentational/Gap";
import { useShowUploadWorkscopeFileButton } from "../../../hooks/workscope";
import Modal from "../../functional/Modal";
import DropdownButton from "../Button/DropdownButton";

const Container = styled.View`
  padding: 12px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

Container.Bottom = styled.View`
  margin-top: auto;
`;

function DrawerContent({ state, navigation, ...props }) {
  const { children, ...workScopeFileButtonProps } =
    useShowUploadWorkscopeFileButton(navigation);

  return (
    <Container {...props}>
      <View>
        {state.routes
          .filter(
            (route) =>
              route.name !== "Edit User" &&
              route.name !== "Add User" &&
              route.name !== "Edit Role" &&
              route.name !== "Add Role" &&
              route.name !== "Leap Change List" &&
              route.name !== "Tool Exception List" &&
              route.name !== "Add Tool Status" &&
              route.name !== "Edit Tool Status"
          )
          .map((route, index) => (
            <Drawer.Tab
              key={route.key}
              onPress={() => navigation.navigate(route.name)}
            >
              {route.name}
            </Drawer.Tab>
          ))}
      </View>

      <Container.Bottom>
        <Button.UploadLeapMasterFile navigation={navigation} />

        <Gap />

        <DropdownButton {...workScopeFileButtonProps}>
          {children}
        </DropdownButton>

        <Gap />

        <Button.Logout navigation={navigation} />
      </Container.Bottom>

      <Modal.UploadWorkScopeFile />
    </Container>
  );
}

export default DrawerContent;
