import { useStoreActions, useStoreState } from "easy-peasy";
import SearchBar from "../../presentational/SearchBar";

function UserSearchBar(props) {
  const { searchInput } = useStoreState((state) => state.user);
  const { setUserSearchInputAction } = useStoreActions((actions) => actions);
  return (
    <SearchBar
      onChangeText={(searchInput) => setUserSearchInputAction(searchInput)}
      value={searchInput}
      placeholder="Search user by username, email, first name, last name, and role"
      {...props}
    />
  );
}

export default UserSearchBar;
