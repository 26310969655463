import { View } from "react-native";
import styled from "styled-components/native";
import Row from "../../presentational/Row";
import Page from "../../containers/Page";
import React, { useEffect } from "react";
import Form from "../../containers/Form";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useIsFocused } from "@react-navigation/native";
import List from "../../containers/List";
import AddOEMModal from "../../containers/Modal/AddOEMModal";
import AddNewConfigModal from "../../containers/Modal/AddNewConfigModal";
import Gap from "../../presentational/Gap";
import ExitFileModal from "../../containers/Modal/ExitFileModal";
import EditNewConfigModal from "../../containers/Modal/EditNewConfigModal";
import AreYouSureModal from "../../containers/Modal/AreYouSureModal";
import AddNewConfigItemModal from "../../containers/Modal/AddNewConfigItemModal";

const Container = styled(View)`
  padding: 2vh 4vw;
`;

const SRow = styled(Row)`
  align-items: center;
`;

function ColumnConfigurationPage(props) {
  const {
    leap: { leapFileChangesList, uploadType, uploadEngineModel },
    file: { deleteFileChangesRowAreYouSureModalVisible, fileChangesInput },
    workscope: { removeFileMappingAsAdminAreYouSureModalVisible },
  } = useStoreState((state) => state);

  const isFocused = useIsFocused();

  const {
    setDeleteFileChangesRowAreYouSureModalVisibleAction,
    deleteFileChangesRowThunk,
    getLeapFileChangesThunk,
    removeCRTLFileMappingAsAdminThunk,
    setRemoveFileMappingAsAdminAreYouSureModalVisibleAction,
    setFileChangesInputAction,
  } = useStoreActions((actions) => actions);

  useEffect(() => {
    if (
      isFocused &&
      fileChangesInput.OEMID !== "" &&
      fileChangesInput.engFamilyID !== ""
    ) {
      getLeapFileChangesThunk();
    } else {
      (uploadType || uploadEngineModel) &&
        setFileChangesInputAction({
          OEMID: uploadType,
          engFamilyID: uploadEngineModel,
        });
    }
  }, [
    uploadType,
    uploadEngineModel,
    isFocused,
    fileChangesInput.engFamilyID,
    fileChangesInput.OEMID,
  ]);

  return (
    <Page.Protected {...props}>
      <Container>
        <AreYouSureModal
          confirmationPhrase={"Are you sure you want to delete this row?"}
          onClose={() => {
            setDeleteFileChangesRowAreYouSureModalVisibleAction(false);
          }}
          isVisible={deleteFileChangesRowAreYouSureModalVisible}
          onSubmit={() => {
            deleteFileChangesRowThunk();
          }}
        />

        <AreYouSureModal
          confirmationPhrase={"Are you sure you want to delete this mapping?"}
          onClose={() => {
            setRemoveFileMappingAsAdminAreYouSureModalVisibleAction(false);
          }}
          isVisible={removeFileMappingAsAdminAreYouSureModalVisible}
          onSubmit={() => {
            removeCRTLFileMappingAsAdminThunk();
            setRemoveFileMappingAsAdminAreYouSureModalVisibleAction(false);
          }}
        />
        <AddNewConfigItemModal />
        <AddNewConfigModal />
        <EditNewConfigModal />
        <AddOEMModal />
        <ExitFileModal />
        <Form.ColumnConfiguration />
        <Gap />
        {leapFileChangesList.length > 0 && <List.ColumnConfig />}
      </Container>
    </Page.Protected>
  );
}

export default ColumnConfigurationPage;
