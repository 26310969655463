import { thunk } from "easy-peasy";
import { getRoleApiCall, upsertRoleApiCall } from "../../api/role";

const thunks = {
  getRoleThunk: thunk(async (actions, id) => {
    actions.setLoadingAction(true);

    try {
      const response = await getRoleApiCall(id);
      actions.setRoleInputAction(response.data);
    } catch (err) {
      actions.setAlertThunk({
        type: "error",
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
  getRolesThunk: thunk(async (actions) => {
    actions.setLoadingAction(true);

    try {
      const response = await getRoleApiCall(0);
      actions.setRoleListAction(response.data);
    } catch (err) {
      actions.setAlertThunk({
        type: "error",
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
  upsertRoleThunk: thunk(async (actions, navigation, helpers) => {
    actions.setLoadingAction(true);

    try {
      const response = await upsertRoleApiCall(helpers.getState().role.input);
      actions.setRoleInputAction(response.data);
      navigation.navigate("Roles");
    } catch (err) {
      actions.setAlertThunk({
        type: "error",
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
};

export default thunks;
