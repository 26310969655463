import { useStoreState } from "easy-peasy";
import Button from "../../Button";
import RoleForm from "./RoleForm";

function EditRoleForm(props) {
  const {
    input: { name },
    allowEdit,
  } = useStoreState((state) => state.role);

  return (
    <RoleForm
      buttons={[
        <Button.AllowEditRole />,
        <Button.GoToRoles>Cancel</Button.GoToRoles>,
        <Button.UpsertRole disabled={!name || !allowEdit}>
          Save Changes
        </Button.UpsertRole>,
      ]}
      {...props}
    />
  );
}

export default EditRoleForm;
