import queryString from "querystring";
import { Dimensions } from "react-native";

import ExcelJS from "exceljs";
import moment from "moment";
import download from "downloadjs";

const DROPDOWN_MAX_HEIGHT = height * 0.4;

const calculateDropdownHeight = (
  dropdownStyle,
  rowStyle,
  dataLength,
  search
) => {
  if (dropdownStyle && dropdownStyle.height) {
    return dropdownStyle.height;
  } else {
    if (dataLength == 0) {
      return 150;
    } else {
      const count = search ? dataLength + 1 : dataLength;
      if (rowStyle && rowStyle.height) {
        const height = rowStyle.height * count;
        return height < DROPDOWN_MAX_HEIGHT ? height : DROPDOWN_MAX_HEIGHT;
      } else {
        const height = 50 * count;
        return height < DROPDOWN_MAX_HEIGHT ? height : DROPDOWN_MAX_HEIGHT;
      }
    }
  }
};

function convertJsonToQueryString(paramJson) {
  const cleanedParams = Object.fromEntries(
    Object.entries(paramJson).filter(([_, v]) => v !== null)
  );
  return queryString.stringify(cleanedParams);
}

const exportToCSV = async (data, filter) => {
  const now = new Date();
  const fileName = `${filter}_${moment().format("MM/DD/YYYY")}.xlsx`;

  return new Promise((resolve, reject) => {
    const workbook = new ExcelJS.Workbook();
    workbook.creator = "Me";
    workbook.created = now;
    workbook.modified = now;

    const worksheet = workbook.addWorksheet("My Sheet", {});

    // worksheet.columns = Object.keys(data[0]).map((key) => ({
    // 	header: key,
    // 	key,
    // }));

    worksheet.columns = [
      // loop through the keys of the first object to get the column names
      ...Object.keys(data[0]).map((key) => ({
        header: key,
        key,
      })),
    ];

    data.forEach((user) => {
      worksheet.addRow(user);
    });

    worksheet.getRow(1).eachCell((cell) => {
      cell.font = { bold: true };
    });

    worksheet.columns.forEach((column) => {
      let dataMax = column.values.reduce((max, rowVal) => {
        if (rowVal !== null && rowVal.toString().length > max) {
          return rowVal.toString().length;
        }
        return max;
      }, 0);
      column.width = dataMax < 10 ? 10 : dataMax;
    });

    workbook.xlsx
      .writeBuffer()
      .then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        download(
          blob,
          fileName,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const exportQuoteSheetToCSV = async (data, filter) => {
  const now = new Date();
  const fileName = `${filter}_${moment().format("MM-DD-YYYY")}.xlsx`;

  return new Promise((resolve, reject) => {
    const workbook = new ExcelJS.Workbook();
    workbook.creator = "Me";
    workbook.created = now;
    workbook.modified = now;

    const worksheet = workbook.addWorksheet("My Sheet", {});

    worksheet.columns = [
      { header: "Part", key: "LatestpartNumber" },
      { header: "Rev", key: "revision" },
      { header: "Act Non-Disc Base Sales Price", key: "salesPrice" },
      { header: "Discount Percent Used", key: "discountPercent" },
      { header: "Lead Time", key: "leadTime" },
      { header: "Expected Quantity", key: "expectedQuantity" },
      { header: "Comment", key: "comment" },
      { header: "TCO Cost Used", key: "tcoCostUsed" },
      { header: "Unit Cost Used", key: "unitCostUsed" },
      { header: "Freight Used", key: "freightUsed" },
      { header: "Duties Used", key: "dutiesUsed" },
      { header: "Escalation Used", key: "escalationUsed" },
      { header: "Supplier Used", key: "supplierUsed" },
    ];

    const seenPartNumbers = {};

    data.forEach((user) => {
      const row = {
        ...user,
        LatestpartNumber:
          user.LatestToolVersion || user.LatestpartNumber || user.partNumber,
      };

      const partNumberToCompare = user.LatestpartNumber || user.partNumber;

      if (
        !seenPartNumbers.hasOwnProperty(row.LatestpartNumber) ||
        (seenPartNumbers.hasOwnProperty(row.LatestpartNumber) &&
          seenPartNumbers[row.LatestpartNumber] !== partNumberToCompare)
      ) {
        seenPartNumbers[row.LatestpartNumber] = partNumberToCompare;
        worksheet.addRow(row);
      }
    });

    worksheet.getRow(1).eachCell((cell) => {
      cell.font = { bold: true };
    });

    worksheet.columns.forEach((column) => {
      let dataMax = column.values.reduce((max, rowVal) => {
        if (rowVal !== null && rowVal.toString().length > max) {
          return rowVal.toString().length;
        }
        return max;
      }, 0);
      column.width = dataMax < 10 ? 10 : dataMax;
    });

    workbook.xlsx
      .writeBuffer()
      .then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        download(
          blob,
          fileName,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const exportSinglePartNumberToCSV = async (data, filter) => {
  const now = new Date();
  const fileName = `${filter}_${moment().format("MM-DD-YYYY")}.xlsx`;

  return new Promise((resolve, reject) => {
    const workbook = new ExcelJS.Workbook();
    workbook.creator = "Me";
    workbook.created = now;
    workbook.modified = now;

    const worksheet = workbook.addWorksheet("My Sheet", {});

    worksheet.columns = [
      { header: "Part Number", key: "LatestpartNumber" },
      { header: "Revision", key: "revision" },
      { header: "Act Non-Disc Base Sales Price", key: "salesPrice" },
      { header: "Discount Percent Used", key: "discountPercent" },
      { header: "Lead Time", key: "leadTime" },
      { header: "Expected Quantity", key: "expectedQuantity" },
    ];

    const seenPartNumbers = new Set();

    data.forEach((user) => {
      const row = { ...user };

      if (row.LatestToolVersion) {
        row.LatestpartNumber = row.LatestToolVersion;
      }

      if (!row.LatestToolVersion) {
        row.LatestpartNumber = row.LatestpartNumber;
      }

      if (!row.LatestToolVersion && !row.LatestpartNumber) {
        row.LatestpartNumber = row.partNumber;
      }
      if (!row.LatestpartNumber || seenPartNumbers.has(row.LatestpartNumber))
        return;

      seenPartNumbers.add(row.LatestpartNumber);

      worksheet.addRow(row);
    });

    worksheet.getRow(1).eachCell((cell) => {
      cell.font = { bold: true };
    });

    worksheet.columns.forEach((column) => {
      let dataMax = column.values.reduce((max, rowVal) => {
        if (rowVal !== null && rowVal.toString().length > max) {
          return rowVal.toString().length;
        }
        return max;
      }, 0);
      column.width = dataMax < 10 ? 10 : dataMax;
    });

    workbook.xlsx
      .writeBuffer()
      .then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        download(
          blob,
          fileName,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const { height, width } = Dimensions.get("window");

const getOEMNameFromList = (oemList, OEMID) => {
  if (!oemList) return;
  if (!OEMID) return;
  return oemList?.find((e) => e.OEMID == OEMID)?.OEM;
};

export {
  convertJsonToQueryString,
  exportToCSV,
  height,
  width,
  calculateDropdownHeight,
  exportSinglePartNumberToCSV,
  getOEMNameFromList,
  exportQuoteSheetToCSV,
};
