import { useStoreActions, useStoreState } from "easy-peasy";
import SearchBar from "../../presentational/SearchBar";

function IntegrationSearchBar(props) {
	const { listSearchInput } = useStoreState((state) => state.integration);
	const { setIntegrationListSearchInputAction } = useStoreActions(
		(actions) => actions
	);
	return (
		<SearchBar
			onChangeText={(searchInput) =>
				setIntegrationListSearchInputAction(searchInput)
			}
			value={listSearchInput}
			placeholder="Search items by part number, description, and status"
			{...props}
		/>
	);
}

export default IntegrationSearchBar;
