import GroupedList from "./GroupedList";
import { useStoreActions, useStoreState } from "easy-peasy";
import { formatStringForComparison } from "../../../utilities/string";
import { useTableFilter } from "../../../hooks/filter";
import { useEffect, useState } from "react";
import Text from "../../presentational/Text";
import { TouchableOpacity } from "react-native";

function useTable() {
	const firstPageNum = 1;

	const {
		tenantInfoByPartNumberList,
		tenantInfoHeaders,
		currTenantInfoPageNumber,
	} = useStoreState((state) => state.tool);

	const { updatePushNotificationHeaderAction, setCurrentListPageNumberAction } =
		useStoreActions((actions) => actions);

	function reloadListByPageNumber(pageNumber) {
		setFilteredCopyList(filterList());
		setCurrentListPageNumberAction(pageNumber);
	}

	const { filteredList: sortedList, ...tableProps } = useTableFilter({
		headers: tenantInfoHeaders,
		list: tenantInfoByPartNumberList.map((pn) => ({
			...pn,
			numofpages: totalPageNum,
		})),
		updateHeaderFunction: updatePushNotificationHeaderAction,
		reloadListByPageNumberFunction: reloadListByPageNumber,
		currPageNumber: currTenantInfoPageNumber,
		setPageNumberFunction: setCurrentListPageNumberAction,
	});

	const [filteredCopyList, setFilteredCopyList] = useState(sortedList.slice());
	const [isFiltering, setIsFiltering] = useState(false);

	const pageSize = 3;
	const totalPageNum = Math.ceil(filteredCopyList.length / pageSize) || 1;

	function filterList() {
		return sortedList
			.filter((pn) =>
				tenantInfoHeaders
					.flatMap((header) => header.subheaders || header)
					.every((header) => {
						const value = (pn[header.propertyName] || "").toLowerCase();

						if (header.isCheckingEqual) {
							return value === header.filterValue.toLowerCase();
						} else if (header.isCheckingContains) {
							return value.includes(header.filterValue.toLowerCase());
						}

						return true;
					})
			)
			.slice();
	}

	useEffect(() => {
		if (isFiltering) {
			setFilteredCopyList(filterList());
			setIsFiltering(false);
		}
	}, [isFiltering]);

	useEffect(() => {
		setFilteredCopyList(filterList());
	}, [
		JSON.stringify(
			tenantInfoHeaders
				.flatMap((header) => header.subheaders || header)
				.map((header) => ({
					isSortAscending: header.isSortAscending,
					isSortDescending: header.isSortDescending,
					isCheckingEqual: header.isCheckingEqual,
					isCheckingContains: header.isCheckingContains,
				}))
		),
		tenantInfoByPartNumberList,
	]);

	return {
		list: filteredCopyList
			.slice(
				0 + currTenantInfoPageNumber * pageSize - pageSize,
				0 + currTenantInfoPageNumber * pageSize
			)
			.map((item) => ({
				...item,
				tenantname: item.tenantname,
				numofpages: totalPageNum,
			})),
		...tableProps,
		totalPageNum,
		onPressOk: (evt) => {
			setIsFiltering(true);
			tableProps.filterEvents.onPressOk(evt);
		},
		onPressLastPage: () => reloadListByPageNumber(totalPageNum),
		onChangePageNum: (text) => {
			const currPageNum = Math.min(
				parseInt(text) || firstPageNum,
				totalPageNum
			);
			setCurrentListPageNumberAction(Math.max(firstPageNum, currPageNum));
		},
	};
}

function TenantInfoByPartNumberList({ setToolModalVisible, ...props }) {
	const listProps = useTable(setToolModalVisible);
	return <GroupedList {...listProps} {...props} />;
}

export default TenantInfoByPartNumberList;
