import { useEffect } from "react";
import Form from "../../containers/Form";
import { useStoreActions } from "easy-peasy";
import { useIsFocused } from "@react-navigation/native";
import styled from "styled-components/native";
import { vhToDp, vwToDp } from "../../../utilities/responsive";
import Page from "../../containers/Page";

const Container = styled.View`
  padding: ${vhToDp(2)}px ${vwToDp(4)}px;
`;

function AddUserPage(props) {
  const { getRolesThunk, resetUserInputAction, setAllowEditUserAction } =
    useStoreActions((actions) => actions);

  const isFocused = useIsFocused();

  useEffect(() => {
    if (isFocused) {
      getRolesThunk();
      resetUserInputAction();
      setAllowEditUserAction(true);
    }
  }, [isFocused]);

  return (
    <Page.Protected>
      <Container>
        <Form.AddUser {...props} />
      </Container>
    </Page.Protected>
  );
}

export default AddUserPage;
