function createFilterHeaders(headers) {
  const defaultState = {
    showMenu: false,
    isSortAscending: false,
    isSortDescending: false,
    filterValue: "",
    isCheckingEqual: false,
    pickedEqualCheck: false,
    isCheckingContains: false,
    pickedContainsCheck: false,
  };

  return headers.map((header) => ({
    ...header,
    subheaders: header.subheaders
      ? header.subheaders.map((subheader) => ({
          ...subheader,
          ...defaultState,
        }))
      : undefined,
    ...defaultState,
  }));
}

function updateHeaderInList(
  updatedTableHeader,
  tableHeaders,
  parentHeaderIndex,
  subheaderIndex
) {
  function resetSort(header) {
    const updatedHeader = { ...header };

    if (
      updatedTableHeader.isSortAscending ||
      updatedTableHeader.isSortDescending
    ) {
      if (header.subheaders) {
        updatedHeader.subheaders = header.subheaders.map((subheader) => ({
          ...subheader,
          isSortAscending: false,
          isSortDescending: false,
        }));
      } else {
        updatedHeader.isSortAscending = false;
        updatedHeader.isSortDescending = false;
      }
    }

    return updatedHeader;
  }

  let updatedFilterHeader = { ...tableHeaders[parentHeaderIndex] };
  if (subheaderIndex >= 0) {
    updatedFilterHeader.subheaders = [
      ...updatedFilterHeader.subheaders.slice(0, subheaderIndex).map(resetSort),
      {
        ...updatedFilterHeader.subheaders[subheaderIndex],
        ...updatedTableHeader,
      },
      ...updatedFilterHeader.subheaders
        .slice(subheaderIndex + 1)
        .map(resetSort),
    ];
  } else {
    updatedFilterHeader = {
      ...updatedFilterHeader,
      ...updatedTableHeader,
    };
  }

  return [
    ...tableHeaders.slice(0, parentHeaderIndex).map(resetSort),
    updatedFilterHeader,
    ...tableHeaders.slice(parentHeaderIndex + 1).map(resetSort),
  ];
}

function mapHeaderSearchJson(header) {
  let flagValue = "";

  header.isCheckingEqual && (flagValue = "1");
  header.isCheckingContains && (flagValue = "0");

  return {
    [header.filterPropertyValueName]: header.filterValue,
    [header.filterPropertyFlagName]: flagValue,
  };
}

export { createFilterHeaders, updateHeaderInList, mapHeaderSearchJson };
