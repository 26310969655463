import React, { useEffect } from "react";
import { Platform, StyleSheet, TouchableOpacity, View } from "react-native";
import Table from "./Table";
import { useStoreState, useStoreActions } from "easy-peasy";
import { useState } from "react";
import { AntDesign, FontAwesome5 } from "@expo/vector-icons";

import { CheckBox } from "@rneui/themed";
import { remToDp, vhToDp } from "../../utilities/responsive";
import { formatStringForComparison } from "../../utilities/string";
import Text from "./Text";
import Icon from "./Icon";
import {
	Pagination,
	Input,
	Center,
	Menu,
	Row,
	Pressable,
} from "@cfbs/cfbsstrap-native";
import Button from "./Button";
import TableColumn from "./Table/TableColumn";

function ToolDeltaGroupedList({
	sortDir = "asc",
	sortCol = null,
	tableHeaders = [],
	tableRows = [],
	striped = true,
	hover = true,
	border = true,
	pageSize = 9,
	isComparingColumnFlags = [false, false],
	setIsComparingColumnFlags,

	setChosenColumnToSortIndex,
	chosenColumnToSortIndex,
	filterValues,
	setFilterValues,
	chosenComparisonIndexes,
	setChosenComparisonIndexes,
	pagination = true,
	headers,
	list,
	...props
}) {
	const { reviewList } = useStoreState((state) => state.tool);
	const { addToolToReviewListAction, removeToolFromReviewListAction } =
		useStoreActions((actions) => actions);
	const [sortInfo, setSortInfo] = useState({ sortCol, sortDir });
	const [currentGroupedPage, setCurrentGroupedPage] = useState(0);
	const itemsPerPage = pageSize;

	const [filteredList, setFilteredList] = useState(list.slice());

	const totalPages = Math.ceil(filteredList && filteredList.length / pageSize); // Use clientConfigGroupedList for calculating total pages

	const handleNextPage = () => {
		if (currentGroupedPage < totalPages) {
			setCurrentGroupedPage(currentGroupedPage + 1);
		}
	};

	const handlePrevPage = () => {
		if (currentGroupedPage > 0) {
			setCurrentGroupedPage(currentGroupedPage - 1);
		}
	};
	const startIndex = currentGroupedPage * pageSize;

	// const sortedRows = [...(filteredList ?? [])];
	const paginatedRows = filteredList.slice(startIndex, startIndex + pageSize);

	function isComparingColumn(columnIndex) {
		return isComparingColumnFlags[columnIndex];
	}
	function isSortingColumn(columnIndex) {
		return chosenColumnToSortIndex === columnIndex;
	}
	const [showMenus, setShowMenus] = useState(
		tableHeaders.map((header) => false)
	);
	const [currentPage, setCurrentPage] = useState(0);
	const [chosenSortIndex, setChosenSortIndex] = useState(-1);

	const lastPageNumDisplay = Math.ceil(filteredList.length / pageSize || 1);
	const lastPageIndex = lastPageNumDisplay - 1;

	function showMenu(columnIndex) {
		setShowMenus(
			showMenus.map((value, index) => (index === columnIndex ? true : value))
		);
	}

	function hideMenu(columnIndex) {
		setShowMenus(
			showMenus.map((value, index) => (index === columnIndex ? false : value))
		);
	}

	const renderFunction = (details, property) => {
		return details.map((detail, index) => (
			<Table.td
				key={`detail-${index}`}
				border={border}
				index={index}
				width={props.width}
				fontSize={15}
				style={{ fontSize: 15 }}
			>
				{detail[property]}
			</Table.td>
		));
	};
	// filteredList
	function filterList() {
		let newList = list.slice();

		if (chosenColumnToSortIndex > -1) {
			const propertyName = headers[chosenColumnToSortIndex].propertyName;
			chosenSortIndex === 0 &&
				(newList = newList.sort((jsonA, jsonB) =>
					sort(jsonA, jsonB, propertyName)
				));
			chosenSortIndex === 1 &&
				(newList = newList.sort((jsonA, jsonB) =>
					sort(jsonB, jsonA, propertyName)
				));
		}

		for (let i = 0; i < chosenComparisonIndexes.length; i++) {
			const comparisonIndex = chosenComparisonIndexes[i];
			const propertyName = headers[i].propertyName;
			if (isComparingColumnFlags[i]) {
				comparisonIndex === 0 &&
					(newList = newList.filter(
						(json) =>
							json[propertyName].toLowerCase() === filterValues[i].toLowerCase()
					));
				comparisonIndex === 1 &&
					(newList = newList.filter((json) =>
						json[propertyName]
							.toLowerCase()
							.includes(filterValues[i].toLowerCase())
					));
			}
		}
		// setCustomList(newList);

		return newList;
	}

	const renderSelectRows = (item, index) => {
		const isInReviewList = reviewList.some(
			(reviewItem) => reviewItem.id === item.id
		);

		return (
			<Table.td
				key={`-detail-${index}`}
				border={border}
				index={index}
				width={props.width}
			>
				<CheckBox
					checked={isInReviewList}
					checkedColor="#007AFF"
					onPress={() => {
						if (isInReviewList) {
							removeToolFromReviewListAction(item);
						} else {
							addToolToReviewListAction(item);
						}
					}}
					checkedIcon={
						<FontAwesome5
							name="check-square"
							size={remToDp(1.5)}
							color="#007AFF"
						/>
					}
					iconType="material-community"
					uncheckedIcon={"checkbox-blank-outline"}
					containerStyle={{ backgroundColor: "none" }}
				/>
			</Table.td>
		);
	};

	useEffect(() => {
		setFilteredList(filterList());
	}, [
		chosenSortIndex,
		chosenColumnToSortIndex,
		isComparingColumnFlags,
		list,
		chosenComparisonIndexes,
	]);

	return (
		<View>
			<Table>
				<>
					<Table.tr fontFamily="Barlow_600SemiBold">
						{headers.map(({ columnName, propertyName }, columnIndex) => {
							return (
								<TableColumn
									headers={headers}
									propertyName={propertyName}
									isComparingColumn={isComparingColumn}
									isSortingColumn={isSortingColumn}
									columnIndex={columnIndex}
									columnName={columnName}
									showMenus={showMenus}
									showMenu={showMenu}
									hideMenu={hideMenu}
									setIsComparingColumnFlags={setIsComparingColumnFlags}
									chosenComparisonIndexes={chosenComparisonIndexes}
									setChosenComparisonIndexes={setChosenComparisonIndexes}
									filterValues={filterValues}
									setFilterValues={setFilterValues}
									setChosenColumnToSortIndex={setChosenColumnToSortIndex}
									setChosenSortIndex={setChosenSortIndex}
									setCurrentPage={setCurrentGroupedPage}
									setFilteredList={setFilteredList}
									filterList={filterList}
									isComparingColumnFlags={isComparingColumnFlags}
									chosenSortIndex={chosenSortIndex}
								/>
							);
						})}
					</Table.tr>
				</>

				{paginatedRows.map((row, index) => (
					<Table.tr
						key={index}
						striped={striped && index % 2 === 0}
						hover={hover}
					>
						<View
							style={{
								width: headers.length ? `${100 / headers.length}%` : "auto",
								height: 60,
							}}
						>
							<Table.td
								border={border}
								index={index}
								fontSize={15}
								style={{ fontSize: 15 }}
							>
								{row.partNumber}
							</Table.td>
						</View>

						{headers
							.slice(1)
							.map((item) => item.propertyName)
							.map((name, index) => (
								<View
									style={{
										width: headers.length ? `${100 / headers.length}%` : "auto",
									}}
								>
									{name === "Select"
										? renderSelectRows(row, index)
										: renderFunction(row.details, name)}
								</View>
							))}
					</Table.tr>
				))}
			</Table>

			<View
				style={{
					alignContent: "center",
					alignItems: "center",
					justifyContent: "center",
					flexDirection: "row",
				}}
			>
				{pagination && (
					<Center>
						<Pagination
							style={{
								marginTop: 20,
								alignContent: "center",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<Pagination.Item
								onPress={() => {
									setCurrentGroupedPage(0);
								}}
							>
								<Pagination.First />
							</Pagination.Item>

							<Pagination.Item onPress={() => handlePrevPage()}>
								<Pagination.Previous />
							</Pagination.Item>
							<Pagination.Item>
								<View style={{ flexDirection: "row", alignItems: "center" }}>
									<Text style={{ marginRight: 10 }}>Page</Text>
									<Input
										style={{
											borderWidth: 0.1,
											borderColor: "#000",
											padding: 5,
											width: 50,
											textAlign: "center",
										}}
										keyboardType="numeric"
										placeholder={currentGroupedPage + 1 + ""}
										value={currentGroupedPage + 1 + ""}
										maxLength={Math.floor(Math.log10(lastPageNumDisplay) + 1)}
										onChangeText={(text) => {
											const pageNumber = parseInt(text);
											if (
												!isNaN(pageNumber) &&
												pageNumber >= 1 &&
												pageNumber <= lastPageNumDisplay
											) {
												setCurrentGroupedPage(pageNumber);
											} else if (pageNumber > lastPageNumDisplay) {
												setCurrentGroupedPage(lastPageNumDisplay);
											}
										}}
									/>
									<Text style={{ marginLeft: 10 }}>
										of {lastPageNumDisplay}
									</Text>
								</View>
							</Pagination.Item>

							<Pagination.Item onPress={handleNextPage}>
								<Pagination.Next />
							</Pagination.Item>

							<Pagination.Item
								onPress={() => {
									// navigate to the last page in the list
									setCurrentGroupedPage(lastPageIndex);
								}}
							>
								<Pagination.Last />
							</Pagination.Item>
						</Pagination>
					</Center>
				)}
			</View>
		</View>
	);
}

export default ToolDeltaGroupedList;

const styles = StyleSheet.create({
	paginationContainer: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "flex-end",
		// padding: 10,
		// marginTop: 10,
		margin: 10,
		// marginTop: 100,
	},
	pageInfo: {
		marginHorizontal: 10,
	},
});
