import Col from "../../../presentational/Col";
import Row from "../../../presentational/Row";
import Input from "../../../presentational/Input";
import { useStoreActions, useStoreState } from "easy-peasy";
import Switch from "../../../presentational/Switch";
import Form from "../Form";
import SelectBox from "react-native-multi-selectbox";
import { xorBy } from "lodash";
import { Platform } from "react-native";

function MasterToolForm({ buttons = [], ...props }) {
	const {
		tool: {
			masterToolInput,
			selectedEngineVariants,
			engineVariantDropdownOptions,
		},
		user,
		file: { oemList, engineFamilyList, engineVariantList },
	} = useStoreState((state) => state);

	const { setMasterToolInputAction, setSelectedEngineVariantsAction } =
		useStoreActions((actions) => actions);

	function getObjectsByIds(ids, objectList) {
		if (!ids || !objectList) return [];

		if (typeof ids === "string") {
			ids = ids.split(",");
		}

		return objectList.filter((obj) => ids.includes(obj.id.toString()));
	}

	// function onSelectedItemsChange(item) {
	// 	setSelectedEngineVariantsAction(
	// 		xorBy(selectedEngineVariants, [item], "id")
	// 	);

	// 	// Create a comma separated string of the ids
	// 	const ids = selectedEngineVariants.map((item) => item.item).join(",");

	// 	setMasterToolInputAction({
	// 		EngineVariant: ids,
	// 	});
	// }
	function onSelectedItemsChange(item) {
		const updatedEngineVariants = xorBy(selectedEngineVariants, [item], "id");

		// Update the selected engine variants
		setSelectedEngineVariantsAction(updatedEngineVariants);

		// Create a comma separated string of the ids from the updated list
		const ids = updatedEngineVariants.map((item) => item.item).join(",");

		setMasterToolInputAction({
			EngineVariant: ids,
		});
	}

	return (
		<Form {...props}>
			<Row>
				<Col xs={12} md={6} lg={4}>
					<Input
						width="99%"
						label="OEM"
						placeholder="Enter OEM"
						onSelect={(name) =>
							setMasterToolInputAction({
								OEMID: name.OEMID,
							})
						}
						value={
							oemList.find((item) => item.OEMID == masterToolInput.OEMID)?.OEM
						} // masterToolInput.OEMID}
						required
						editable={true}
						dropdown
						// dropdownChoices={["GE", "SN"]}
						dropdownChoices={oemList}
						selectedItem={(item) => item.OEM}
						rowTextForSelection={(item) => item.OEM}
						buttonTextAfterSelection={(item) => item.OEM}
					/>
				</Col>

				<Col xs={12} md={6} lg={4}>
					<Input
						width="99%"
						label="Engine Family"
						placeholder="Enter Engine Family"
						onSelect={(name) =>
							setMasterToolInputAction({
								EngineFamilyID: name.EngineFamilyID,
							})
						}
						value={
							engineFamilyList.find(
								(item) => item.EngineFamilyID == masterToolInput.EngineFamilyID
							)?.EngineFamily
						} // masterToolInput.OEMID}
						required
						editable={true}
						dropdown
						// dropdownChoices={["GE", "SN"]}
						dropdownChoices={engineFamilyList}
						selectedItem={(item) => item.EngineFamily}
						rowTextForSelection={(item) => item.EngineFamily}
						buttonTextAfterSelection={(item) => item.EngineFamily}
					/>
				</Col>

				<Col xs={12} md={6} lg={4}>
					<Input
						label="Part Number"
						placeholder="Type Part Number"
						onChangeText={(partNumber) =>
							setMasterToolInputAction({ partNumber })
						}
						value={masterToolInput.partNumber}
						editable={true}
					/>
				</Col>
			</Row>

			<Row>
				<Col xs={12} md={6} lg={6}>
					<Input
						label="Part Description"
						placeholder="Type Part Description"
						onChangeText={(partDescription) =>
							setMasterToolInputAction({ partDescription })
						}
						value={masterToolInput.partDescription}
						editable={true}
					/>
				</Col>
				<Col xs={12} md={3}>
					<Input
						label="TS Code"
						placeholder="Type TS Code"
						onChangeText={(TSCode) => setMasterToolInputAction({ TSCode })}
						value={masterToolInput.TSCode}
						editable={true}
					/>
				</Col>
				<Col xs={12} md={3}>
					<Input
						label="Qualified Revision / F and F Code"
						placeholder="Type Qualified Revision"
						editable={true}
						onChangeText={(QualifiedRevision) =>
							setMasterToolInputAction({ QualifiedRevision })
						}
						value={masterToolInput.QualifiedRevision}
					/>
				</Col>
			</Row>
			<Row>
				<Col xs={12} md={6}>
					{/* <SelectBox
						label="Engine Variant"
						labelStyle={{
							fontFamily: "Barlow_400Regular",
							color: "#00205b",
							fontSize: 16,
						}}
						containerStyle={{
							bottomBorderWidth: 0.1,
							borderBottomWidth: 0.1,
							borderBottomColor: "grey",
							bottomBorderColor: "grey",
						}}
						options={engineVariantList.map((item) => ({
							item: item.EngineVariant,
							id: item.EngineVariantID,
						}))}
						selectedValues={selectedEngineVariants}
						onMultiSelect={(selectedItem) =>
							onSelectedItemsChange(selectedItem)
						}
						multiOptionContainerStyle={{
							alignContent: "flex-start",
							alignItems: "flex-start",
							justifyContent: "flex-start",
							padding: Platform.OS == "web" ? 10 : 0,
						}}
						optionsLabelStyle={{
							fontFamily: "Barlow_400Regular",
							color: "#AB2328",
							padding: 10,
						}}
						selectedItemStyle={{
							backgroundColor: "#AB2328",
							fontFamily: "Barlow_400Regular",
							fontSize: 16,
							color: "#fff",
						}}
						onTapClose={(selectedItem) => onSelectedItemsChange(selectedItem)}
						isMulti
					/> */}
					<SelectBox
						label="Engine Variant"
						labelStyle={{
							fontFamily: "Barlow_400Regular",
							color: "#00205b",
							fontSize: 16,
						}}
						containerStyle={{
							bottomBorderWidth: 0.1,
							borderBottomWidth: 0.1,
							borderBottomColor: "grey",
							bottomBorderColor: "grey",
						}}
						options={engineVariantList.map((item) => ({
							item: item.EngineVariant,
							id: item.EngineVariantID,
						}))}
						selectedValues={selectedEngineVariants}
						onMultiSelect={(selectedItem) =>
							onSelectedItemsChange(selectedItem)
						}
						multiOptionContainerStyle={{
							backgroundColor: "#AB2328",
							color: "#fff",
						}}
						multiOptionsLabelStyle={{
							backgroundColor: "#AB2328",
							fontFamily: "Barlow_400Regular",
							fontSize: 15,
							color: "#fff",
						}}
						optionsLabelStyle={{
							fontFamily: "Barlow_400Regular",
							color: "#AB2328",
						}}
						onTapClose={(selectedItem) => onSelectedItemsChange(selectedItem)}
						isMulti
					/>
				</Col>

				<Col xs={12} md={3}>
					<Input
						label="Superseded Replace By"
						placeholder="Type Superseded Replace By"
						onChangeText={(SupersededReplaceBy) =>
							setMasterToolInputAction({ SupersededReplaceBy })
						}
						value={masterToolInput.SupersededReplaceBy}
						editable={true}
					/>
				</Col>

				<Col xs={12} md={3}>
					<Input
						label="Overridden Replace By"
						placeholder="Type Overridden Replace By"
						onChangeText={(overrideTSPartNumber) =>
							setMasterToolInputAction({ overrideTSPartNumber })
						}
						value={masterToolInput.overrideTSPartNumber}
						editable={true}
					/>
				</Col>
			</Row>
			<Row>
				<Col xs={12} md={4.25}>
					<Input
						label="Last Revision"
						placeholder="Type Last Revision"
						onChangeText={(LastRevision) =>
							setMasterToolInputAction({ LastRevision })
						}
						value={masterToolInput.LastRevision}
						editable={true}
					/>
				</Col>

				<Col xs={12} md={5.25}>
					<Input
						label="Service Bulletin"
						placeholder="Type Service Bulletin"
						onChangeText={(ServiceBulletin) =>
							setMasterToolInputAction({ ServiceBulletin })
						}
						value={masterToolInput.ServiceBulletin}
						editable={true}
					/>
				</Col>

				<Col xs={12} md={2.5}>
					<Input
						label="Overridden Replace By TS Code"
						placeholder="Type TS Code"
						onChangeText={(overrideTSCode) =>
							setMasterToolInputAction({ overrideTSCode })
						}
						value={masterToolInput.overrideTSCode}
						editable={true}
					/>
				</Col>
				<Col>
					<Input
						label="Internal Comments"
						placeholder="Type Internal Comments"
						onChangeText={(internalComments) =>
							setMasterToolInputAction({ internalComments })
						}
						value={masterToolInput.internalComments}
						editable={true}
					/>
				</Col>
				<Col>
					<Input
						label="Comments"
						placeholder="Type Comments"
						onChangeText={(Comments) => setMasterToolInputAction({ Comments })}
						value={masterToolInput.Comments}
						editable={true}
					/>
				</Col>

				{buttons.map((button, index) => (
					<Col xs={12} md={12 / buttons.length} key={index}>
						{button}
					</Col>
				))}
			</Row>
		</Form>
	);
}

export default MasterToolForm;
