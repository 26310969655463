import { useEffect } from "react";
import Form from "../../containers/Form";
import { useStoreActions } from "easy-peasy";
import { useIsFocused } from "@react-navigation/native";
import styled from "styled-components/native";
import { vhToDp, vwToDp } from "../../../utilities/responsive";
import Page from "../../containers/Page";

const Container = styled.View`
	padding: ${vhToDp(2)}px ${vwToDp(4)}px;
`;

function EditUserPage({ route, ...props }) {
	const { getRolesThunk, getUserThunk } = useStoreActions((actions) => actions);

	const isFocused = useIsFocused();

	useEffect(() => {
		if (isFocused) {
			getRolesThunk();
			// getUserThunk(route.params.id);
		}
	}, [isFocused]);

	return (
		<Page.Protected>
			<Container>
				<Form.EditUser {...props} />
			</Container>
		</Page.Protected>
	);
}

export default EditUserPage;
