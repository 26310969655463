import Button from "../../presentational/Button";
import AllowEditUserButton from "./UserButton/AllowEditUserButton";
import DropdownButton from "./DropdownButton";
import GoToCreateUserButton from "./UserButton/GoToCreateUserButton";
import GoToUsersButton from "./UserButton/GoToUsersButton";
import LoginButton from "./AuthButton/LoginButton";
import LogoutButton from "./AuthButton/LogoutButton";
import UploadLeapMasterFileButton from "./LeapButton/UploadLeapMasterFileButton";
import UpsertUserButton from "./UserButton/UpsertUserButton";
import GoToRolesButton from "./RoleButton/GoToRolesButton";
import GoToCreateRoleButton from "./RoleButton/GoToCreateRoleButton";
import UpsertRoleButton from "./RoleButton/UpsertRoleButton";
import AllowEditRoleButton from "./RoleButton/AllowEditRoleButton";
import SaveConfigurationButton from "./ConfigurationButton/SaveConfigurationButton";

Button.Login = LoginButton;
Button.Logout = LogoutButton;

Button.Dropdown = DropdownButton;

Button.UploadLeapMasterFile = UploadLeapMasterFileButton;
// Button.UploadLeapChangeFile = UploadLeapChangeFileButton;

Button.UpsertUser = UpsertUserButton;
Button.GoToUsers = GoToUsersButton;
Button.AllowEditUser = AllowEditUserButton;
Button.GoToCreateUser = GoToCreateUserButton;

Button.GoToRoles = GoToRolesButton;
Button.GoToCreateRole = GoToCreateRoleButton;
Button.UpsertRole = UpsertRoleButton;
Button.AllowEditRole = AllowEditRoleButton;

Button.SaveConfiguration = SaveConfigurationButton;

export default Button;
