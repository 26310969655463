function formatStringForComparison(string) {
  return string.toLowerCase().trim();
}

function stringifyArrayForSequelize(array) {
  const result = array.reduce((resultString, element, index) => {
    const stringifiedElement = Object.entries(element).reduce(
      (elementString, [key, value], index) =>
        `${elementString}${index ? "," : ""}\"${key}\":${
          value ? `\"${value}\"` : '""'
        }`,
      ""
    );
    return `${resultString}${index ? "," : ""}${stringifiedElement}`;
  }, "[{");
  return `${result}}]`;
}

export { formatStringForComparison, stringifyArrayForSequelize };
