import { useStoreState } from "easy-peasy";
import RoleForm from "./RoleForm";
import Button from "../../Button";

function AddRoleForm(props) {
  const { name } = useStoreState((state) => state.role.input);

  return (
    <RoleForm
      buttons={[
        <Button.GoToRoles>Cancel</Button.GoToRoles>,
        <Button.UpsertRole disabled={!name}>Add Role</Button.UpsertRole>,
      ]}
      {...props}
    />
  );
}

export default AddRoleForm;
