import { useStoreActions } from "easy-peasy";
import DropdownButton from "../DropdownButton";

function UploadLeapMasterFileButton({ children, ...props }) {
	const { setMasterLeapFileSelectionModalVisibleAction } = useStoreActions(
		(actions) => actions
	);

	return (
		<DropdownButton
			setShowModal={() => {
				props.navigation.navigate("Home");
				setMasterLeapFileSelectionModalVisibleAction(true);
				props.navigation.closeDrawer();
			}}
			color="#0088CE"
			onPress={async () => {
				props.navigation.navigate("Home");
				setMasterLeapFileSelectionModalVisibleAction(true);
				props.navigation.closeDrawer();
			}}
		>
			{`Upload CRTL File`}
		</DropdownButton>
	);
}

export default UploadLeapMasterFileButton;
