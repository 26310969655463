import { useStoreState, useStoreActions } from "easy-peasy";
import { FontAwesome5 } from "@expo/vector-icons";
import Icon from "../../presentational/Icon";
import Card from "../../presentational/Card";
import PaginatedList from "../../presentational/PaginatedList";

function ColumnConfigurationList(props) {
  const {
    leapFileChangesList,
    isComparingColumnFlagsColumnConfig,
    chosenColumnToSortIndexColumnConfig,
    filterColumnConfigValues,
    chosenColumnConfigComparisonIndexes,
  } = useStoreState((state) => state.leap);
  const {
    getOEMHeadersListThunk,
    getTableColumnNamesThunk,
    setAddNewConfigurationModalVisibleAction,
    setFileChangesInputAction,
    setFileListAction,
    setEditHeadersIndexAction,
    setEditConfigurationModalVisibleAction,
    setIsComparingColumnFlagsColumnConfigAction,
    setChosenColumnToSortIndexColumnConfigAction,
    setFilterColumnConfigValuesAction,
    setChosenColumnConfigComparisonIndexesAction,
  } = useStoreActions((actions) => actions);

  return (
    <Card
      edit={true}
      onPress={() => {
        setAddNewConfigurationModalVisibleAction(true);
        setFileListAction(leapFileChangesList);
      }}
    >
      <PaginatedList
        chosenComparisonIndexes={chosenColumnConfigComparisonIndexes}
        setChosenComparisonIndexes={
          setChosenColumnConfigComparisonIndexesAction
        }
        filterValues={filterColumnConfigValues}
        setFilterValues={setFilterColumnConfigValuesAction}
        setChosenColumnToSortIndex={
          setChosenColumnToSortIndexColumnConfigAction
        }
        chosenColumnToSortIndex={chosenColumnToSortIndexColumnConfig}
        isComparingColumnFlags={isComparingColumnFlagsColumnConfig}
        setIsComparingColumnFlags={setIsComparingColumnFlagsColumnConfigAction}
        headers={[
          {
            columnName: "File Column Name",
            propertyName: "filecolumnName",
          },
          { columnName: "File Column ID", propertyName: "filecolumnID" },
          { columnName: "Table Column Name", propertyName: "tablecolumnName" },
          { columnName: "Legend Code", propertyName: "legendCode" },
          { columnName: "Edit", propertyName: "legendCode" },
        ]}
        list={leapFileChangesList
          .filter((item) => {
            return (
              item.tablecolumnName !== "OEM" &&
              item.tablecolumnName !== "OEM File Name" &&
              item.tablecolumnName !== "Engine Family ID"
            );
          })
          .map((role, i) => ({
            filecolumnName: role.filecolumnName,
            filecolumnID: role.filecolumnID,
            tablecolumnName: role.tablecolumnName,
            legendCode: role.legendCode,
            edit: (
              <Icon
                Component={FontAwesome5}
                name="edit"
                onPress={() => {
                  getOEMHeadersListThunk();
                  getTableColumnNamesThunk();
                  setEditHeadersIndexAction(i);
                  setEditConfigurationModalVisibleAction(true);
                  setFileChangesInputAction({
                    id: role.id,
                    OEM: role.OEM,
                    filecolumnName: role.filecolumnName,
                    filecolumnID: role.filecolumnID,
                    tablecolumnName: role.tablecolumnName,
                    legendCode: role.legendCode,
                    // get rid of all spaces
                    propertyName: role.tablecolumnName.replace(/\s/g, ""),
                  });
                }}
              />
            ),
          }))}
      />
    </Card>
  );
}

export default ColumnConfigurationList;
