import { useStoreActions, useStoreState } from "easy-peasy";
import List from "../../presentational/List";
import { FontAwesome5, Ionicons } from "@expo/vector-icons";
import Icon from "../../presentational/Icon";
import Card from "../../presentational/Card";
import { useNavigation } from "@react-navigation/native";
import { formatStringForComparison } from "../../../utilities/string";
import Text from "../../presentational/Text";
import Td from "../../presentational/Table/Td";
import { View } from "react-native";
import { CheckBox } from "@rneui/themed";
import { remToDp } from "../../../utilities/responsive";
import { getOEMNameFromList } from "../../../utilities/general";
import PaginatedList from "../../presentational/PaginatedList";

function MasterToolList({ ...props }) {
	const {
		tool: {
			masterList,
			masterListSearchInput,
			isComparingColumnFlagsMaster,
			chosenColumnToSortIndexMaster,
			filterMasterValues,
			chosenMasterComparisonIndexes,
		},
		file: { oemList, engineVariantList },
		leap: { uploadEngineModel },
	} = useStoreState((state) => state);

	const {
		setMasterToolInputAction,
		setSelectedEngineVariantsAction,
		setIsComparingColumnFlagsMasterAction,
		setChosenColumnToSortIndexMasterAction,
		setFilterMasterValuesAction,
		setChosenMasterComparisonIndexesAction,
	} = useStoreActions((actions) => actions);

	return (
		<Card>
			{/* <List */}
			<PaginatedList
				chosenComparisonIndexes={chosenMasterComparisonIndexes}
				setChosenComparisonIndexes={setChosenMasterComparisonIndexesAction}
				filterValues={filterMasterValues}
				setFilterValues={setFilterMasterValuesAction}
				setChosenColumnToSortIndex={setChosenColumnToSortIndexMasterAction}
				chosenColumnToSortIndex={chosenColumnToSortIndexMaster}
				isComparingColumnFlags={isComparingColumnFlagsMaster}
				setIsComparingColumnFlags={setIsComparingColumnFlagsMasterAction}
				pageSize={9}
				width={[
					"7%",
					"9%",
					"7%",
					"16%",
					"10%",
					"11%",
					"12%",
					"9%",
					"14%",
					"5%",
				]}
				headers={[
					{
						columnName: "OEM",
						propertyName: "OEM",
					},
					{
						columnName: "Engine",
						propertyName: "ENGINE",
					},
					{
						columnName: "Part Number",
						propertyName: "partNumber",
					},
					{
						columnName: "Part Description",
						propertyName: "partDescription",
					},
					{
						columnName: "Status Code",
						propertyName: "TSCode",
					},
					{
						columnName: "CRTL REPLACEMENT",
						propertyName: "SupersededReplacedby",
					},

					{
						columnName: "CRTL REPLACEMENT STATUS CODE",
						propertyName: "SupersededReplacedbyTSCode",
					},
					{
						columnName: "RHINESTAHL OVERRIDE",

						propertyName: "overrideTSPartNumber",
					},
					{
						columnName: "RHINESTAHL OVERRIDE STATUS CODE",
						propertyName: "overrideTSCode",
					},
					{
						columnName: "Edit",
						propertyName: "OEM",
					},
				]}
				list={masterList
					.filter(
						(user) =>
							formatStringForComparison(user.partNumber).includes(
								formatStringForComparison(masterListSearchInput)
							) ||
							formatStringForComparison(user.partDescription).includes(
								formatStringForComparison(masterListSearchInput)
							)
					)
					.map((item) => ({
						OEM: item.oem || "",
						ENGINE: item.ENGINE || "",
						partNumber: item.partNumber || "",
						partDescription: item.partDescription || "",
						TSCode: item.TSCode || "",
						SupersededReplacedby: item.SupersededReplacedby || "",
						SupersededReplacedbyTSCode: item.SupersededReplacedbyTSCode || "",
						overrideTSPartNumber: item.overrideTSPartNumber || "",
						overrideTSCode: item.overrideTSCode || "",
						editIcon: (
							<Icon
								Component={FontAwesome5}
								name="edit"
								onPress={() => {
									setMasterToolInputAction({
										id: item.id,
										OEMID: item.OEMID,
										EngineFamilyID: item.EngineFamilyID,
										partNumber: item.partNumber,
										partDescription: item.partDescription,
										TSCode: item.TSCode,
										QualifiedRevision: item.QualifiedRevision,
										SupersededReplaceBy: item.SupersededReplacedby,
										FandFCode: item.FnFCode,
										LastRevision: item.LastRevision,
										Comments: item.Comments,
										ServiceBulletin: item.ServiceBulletin,
										internalComments: item.internalComments,
										EngineVariant: item.ENGINE,
										overrideTSPartNumber: item.overrideTSPartNumber,
										overrideTSCode: item.overrideTSCode,
									});
									// setMasterToolInputAction(item);
									if (item.ENGINE) {
										const variantIds = item.ENGINE.split(",");

										const selectedVariants = engineVariantList
											.filter((item) =>
												variantIds.includes(item.EngineVariant.toString())
											)
											.map((item) => ({
												item: item.EngineVariant,
												id: item.EngineVariantID,
											}));

										setSelectedEngineVariantsAction(selectedVariants);
									}

									props.navigation.navigate("Edit Master Tool");
								}}
							/>
						),
					}))}
			/>
		</Card>
	);
}

export default MasterToolList;
