import { axios } from ".";

const integrationAxios = axios.create({
	baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}/integrationItems`,
});

function getIntegrationItemsAPICall() {
	return integrationAxios({ method: "get", url: "/get" });
}

export { integrationAxios, getIntegrationItemsAPICall };
