// import styled from "styled-components/native";
// import Text from "../Text";
// import { remToDp } from "../../../utilities/responsive";

// const Td = styled(Text)`
// flex: 1;
// text-align: center;
// padding: ${remToDp(1)}px;
// border: ${(props) => (props.border ? "1px solid #dee2e6" : "0")};
// font-size: ${remToDp(1.25)}px;
// `;

// export default Td;

import Text from "../Text";
import styled from "styled-components/native";
import { Platform } from "react-native";
import { remToDp } from "../../../utilities/responsive";

const T = styled.View`
	text-align: center;
	padding: ${remToDp(1)}px;
	border: ${(props) => (props.border ? "1px solid #dee2e6" : "0")};
	overflow: hidden;
	align-items: center;
	justify-content: center;

	flex: 1;
`;

const TouchableOpacityWrapper = styled.View`
	align-items: center;
	justify-content: center;
`;

const CellText = styled(Text)`
	font-size: ${(props) => (props.fontSize ? props.fontSize : remToDp(1.15))}px;
`;

const Td = ({ onPress, children, ...props }) => {
	return (
		<T
			border={props.border}
			{...props}
			style={{
				height: props.height || 60,
			}}
		>
			<TouchableOpacityWrapper>
				<CellText
					{...props}
					style={{
						color: props.color || "#00205b",
						fontFamily: props.fontFamily || "Barlow_400Regular",
						fontWeight: props.fontWeight || "normal",
					}}
				>
					{children}
				</CellText>
			</TouchableOpacityWrapper>
		</T>
	);
};

export default Td;
