import styled from "styled-components/native";
import { View } from "react-native";

const Card = styled(View)`
	background-color: white;
	width: 100%;
	margin: auto;
	padding: 24px;
	box-shadow: 0px 0px 10px grey;
`;

export default Card;
