import React from "react";
import { StyleSheet, ScrollView } from "react-native";
import { AntDesign, Feather } from "@expo/vector-icons";
import { useStoreActions, useStoreState } from "easy-peasy";
import { Divider } from "@rneui/themed";
import Card from "../../../presentational/Card";
import Text from "../../../presentational/Text";
import Gap from "../../../presentational/Gap";
import Col from "../../../presentational/Col";
import Row from "../../../presentational/Row";
import Input from "../../../presentational/Input";
import Button from "../../../presentational/Button";
import { View } from "react-native";
import { useNavigation } from "@react-navigation/native";
import EngineFamilyDropdown from "../../Dropdown/EngineFamilyDropdown";

const AddOEMToFamilyStepForm = ({ ...props }) => {
  const navigation = useNavigation();

  const {
    upsertEngineVariantInput,
    upsertEngineFamilyInput,
    engineVariantList,
    oemToEngineFamilyInput,
    upsertOEMInput,
    oemToFamilyCurrentStep,
    upsertOEMToFamilyInput,
    oemList,
  } = useStoreState((state) => state.file);

  const {
    upsertToolsThunk,
    upsertExtraDocumentThunk,
    setOEMToEngineFamilyInputAction,
    setUpsertOEMInputAction,
    setOemToFamilyCurrentStepAction,
    setOEMToFamilyInputAction,
    upsertOEMToFamilyThunk,
    setUploadEngineModelAction,
    setLeapEngineModelAction,
  } = useStoreActions((actions) => actions);

  const handleNextStep = () => {
    setOemToFamilyCurrentStepAction(oemToFamilyCurrentStep + 1);
  };

  const renderFormStep = () => {
    switch (oemToFamilyCurrentStep) {
      case 1:
        return (
          <>
            <Row>
              <Col xs={12} md={5}>
                <Input
                  width="99%"
                  label="Choose an OEM"
                  placeholder="OEM"
                  value={upsertOEMInput.OEM}
                  onSelect={(name) => {
                    setOEMToFamilyInputAction({
                      oemid: name.OEMID,
                    });
                    setUpsertOEMInputAction({ OEM: name.OEM });
                    setUpsertOEMInputAction({
                      OEMDescription: name.oemdescription,
                    });
                  }}
                  required
                  editable={true}
                  dropdown
                  dropdownChoices={oemList}
                  selectedItem={(item) => item.OEM}
                  rowTextForSelection={(item) => item.OEM}
                  buttonTextAfterSelection={(item) => item.OEM}
                />
              </Col>

              <Col xs={12} md={5}>
                <Input
                  label="OEM Description"
                  onChangeText={(OEMDescription) =>
                    setUpsertOEMInputAction({ OEMDescription })
                  }
                  required
                  value={upsertOEMInput.OEMDescription}
                  placeholder="Type OEM Description"
                />
              </Col>
              <Col xs={12} md={2}>
                <View style={{ flexDirection: "row", alignSelf: "center" }}>
                  <Feather name="info" size={20} color="#00205b" />
                  <Text
                    style={{
                      alignSelf: "center",
                      marginLeft: 8,
                      color: "#ab2328",

                      fontFamily: "Barlow_500Medium_Italic",
                    }}
                    fontFamily="Barlow_500Medium_Italic"
                  >
                    {" "}
                    If an OEM is not available, please add it
                  </Text>
                </View>
                <Gap />
                <Button
                  title="Add OEM"
                  fullWidth
                  color="#0088CE"
                  onPress={() => {
                    navigation.push("Add OEM");
                  }}
                />
              </Col>
            </Row>
            <Button
              fullWidth
              title={
                !upsertOEMInput.OEM || !upsertOEMInput.OEMDescription
                  ? "Fill in All Fields"
                  : "Next Step"
              }
              onPress={() => handleNextStep()}
              disabled={!upsertOEMInput.OEM || !upsertOEMInput.OEMDescription}
            />
          </>
        );

      case 2:
        return (
          <>
            <Row>
              <Col xs={12} md={10}>
                <EngineFamilyDropdown
                  value={upsertEngineFamilyInput.enginefamilydesc}
                  onSelect={(value) => {
                    setLeapEngineModelAction(value.EngineFamily);
                    setUploadEngineModelAction(value.EngineFamilyID);
                    setOEMToFamilyInputAction({
                      enginefamilyid: value.EngineFamilyID,
                      enginefamilydesc: value.EngineFamily,
                    });
                  }}
                />
              </Col>
              <Col xs={12} md={2}>
                <View style={{ flexDirection: "row", alignSelf: "center" }}>
                  <Feather name="info" size={20} color="#00205b" />
                  <Text
                    style={{
                      alignSelf: "center",
                      marginLeft: 8,
                      color: "#ab2328",
                      fontFamily: "Barlow_500Medium_Italic",
                    }}
                    fontFamily="Barlow_500Medium_Italic"
                  >
                    {" "}
                    If a Family is not available, please add it
                  </Text>
                </View>
                <Gap />
                <Button
                  title="Add New Family"
                  fullWidth
                  color="#0088CE"
                  onPress={() => navigation.push("Add Engine Family")}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  fullWidth
                  title={
                    !upsertOEMToFamilyInput.enginefamilyid
                      ? "Choose Engine Model"
                      : "Next Step"
                  }
                  onPress={handleNextStep}
                  disabled={!upsertOEMToFamilyInput.enginefamilyid}
                />
              </Col>
            </Row>
          </>
        );
      case 3:
        return (
          <>
            <Row>
              <Col xs={12} md={10}>
                <Input
                  placeholder={`Select Engine Variant`}
                  label={"Engine Variant"}
                  dropdownChoices={engineVariantList}
                  onSelect={(value) =>
                    setOEMToEngineFamilyInputAction({
                      engineVariant: value.EngineVariant,
                    })
                  }
                  dropdown
                  selectedItem={(item) => item.EngineVariant}
                  value={upsertEngineVariantInput.enginevariant}
                  rowTextForSelection={(item) => item.EngineVariant}
                  search
                />
              </Col>

              <Col xs={12} md={2}>
                <View style={{ flexDirection: "row", alignSelf: "center" }}>
                  <Feather name="info" size={20} color="#00205b" />
                  <Text
                    style={{
                      alignSelf: "center",
                      marginLeft: 8,
                      color: "#ab2328",

                      fontFamily: "Barlow_500Medium_Italic",
                    }}
                    fontFamily="Barlow_500Medium_Italic"
                  >
                    {" "}
                    If a Variant is not available, please add it
                  </Text>
                </View>
                <Gap />
                <Button
                  title="Add New Variant"
                  fullWidth
                  color="#0088CE"
                  onPress={() => navigation.push("Add Engine Variant")}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  fullWidth
                  title={
                    !upsertOEMToFamilyInput.engineVariant
                      ? "Choose Engine Variant"
                      : "Save"
                  }
                  onPress={() => upsertOEMToFamilyThunk(navigation)}
                  disabled={!upsertOEMToFamilyInput.engineVariant}
                />
              </Col>
            </Row>
          </>
        );
    }
  };

  const renderStepIndicators = () => {
    const steps = ["Name", "Engine Family", "Engine Variant"];

    return steps.map((step, index) => (
      <View
        key={index}
        onPress={() => setOemToFamilyCurrentStepAction(index + 1)}
        style={[
          styles.stepIndicator,
          oemToFamilyCurrentStep === index + 1
            ? styles.currentStepIndicator
            : oemToFamilyCurrentStep > index + 1 && {
                backgroundColor: "#cfd2d4",
              },
          ,
        ]}
      >
        <Text
          key={index}
          style={[
            styles.stepIndicator,
            oemToFamilyCurrentStep === index + 1 && styles.currentStepIndicator,
          ]}
        >
          {step}
        </Text>
        {oemToFamilyCurrentStep > index + 1 && (
          <AntDesign
            name="check"
            size={20}
            color="green"
            style={{
              alignSelf: "center",
            }}
          />
        )}
      </View>
    ));
  };

  return (
    <Card style={styles.container}>
      <ScrollView
        horizontal
        contentContainerStyle={styles.stepIndicatorsContainer}
      >
        {renderStepIndicators()}
      </ScrollView>
      <Divider />
      <Gap />
      {renderFormStep()}
    </Card>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
  },
  stepIndicatorsContainer: {
    flexDirection: "row",
    alignSelf: "center",
    marginBottom: 16,
    width: "100%",
  },
  stepIndicator: {
    fontFamily: "Barlow_600SemiBold",
    flex: 1,
    fontSize: 18,
    textAlign: "center",
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 8,
    marginRight: 8,
    flexDirection: "row",
  },
  currentStepIndicator: {
    backgroundColor: "#e8e9ea",
  },
  stepText: {
    fontSize: 20,
    marginBottom: 16,
  },
});

export default AddOEMToFamilyStepForm;
