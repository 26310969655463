import React from "react";
import { Platform, StyleSheet, TouchableOpacity, View } from "react-native";
import Table from "./Table";
import { useStoreState, useStoreActions } from "easy-peasy";
import { useState } from "react";
import { AntDesign, FontAwesome5 } from "@expo/vector-icons";

import { CheckBox } from "@rneui/themed";
import { remToDp, vhToDp } from "../../utilities/responsive";
import { formatStringForComparison } from "../../utilities/string";
import { Row } from "../presentational/Row";
import Text from "./Text";

function GroupedList({
	sortDir = "asc",
	sortCol = null,
	tableHeaders = [],
	tableRows = [],
	striped = true,
	hover = true,
	border = true,
	pageSize = 9,
	...props
}) {
	const {
		tool: {
			deltaList,
			deltaListSearchInput,
			searchInput,
			configFilter,
			reviewList,
			groupedList,
		},
		status: { currentGroupedPage },
	} = useStoreState((state) => state);
	const {
		addToolToReviewListAction,
		removeToolFromReviewListAction,
		setCurrentGroupedPage,
	} = useStoreActions((actions) => actions);
	const [sortInfo, setSortInfo] = useState({ sortCol, sortDir });
	// const [currentGroupedPage, setCurrentGroupedPage] = useState(1);
	const itemsPerPage = pageSize;

	const handleSort = (colIndex) => {
		let newSortInfo = { sortCol: colIndex, sortDir: "asc" };
		if (colIndex === sortInfo.sortCol) {
			newSortInfo.sortDir = sortInfo.sortDir === "asc" ? "desc" : "asc";
		}
		setSortInfo(newSortInfo);
	};

	const totalPages = Math.ceil(groupedList && groupedList.length / pageSize); // Use groupedList for calculating total pages

	const handleNextPage = () => {
		if (currentGroupedPage < totalPages) {
			setCurrentGroupedPage(currentGroupedPage + 1);
		}
	};

	const handlePrevPage = () => {
		if (currentGroupedPage > 1) {
			setCurrentGroupedPage(currentGroupedPage - 1);
		}
	};

	const sortedRows = [...(groupedList ?? [])]?.sort((a, b) => {
		const sortDir = sortInfo.sortDir;
		const aVal = a.partNumber || "";
		const bVal = b.partNumber || "";

		const sortVal = aVal.toString().localeCompare(bVal.toString());
		return sortDir === "asc" ? sortVal : -sortVal;
	});

	const indexOfFirstItem = (currentGroupedPage - 1) * itemsPerPage;
	const indexOfLastItem = currentGroupedPage * itemsPerPage;

	const currentItems = sortedRows.slice(indexOfFirstItem, indexOfLastItem);

	const cellHeight = (rows) => {
		if (rows > 1) {
			return 70 * rows.length;
		} else {
			return 70;
		}
	};

	const renderDetailRows = (details, partNumber) => {
		return details.map((detail, index) => (
			<Table.td
				key={`${partNumber}-detail-${index}`}
				border={border}
				index={index}
				width={props.width}
			>
				{detail.RecordType}
			</Table.td>
		));
	};

	const renderARows = (details, partNumber) => {
		return details.map((detail, index) => (
			<Table.td
				key={`${partNumber}-detail-${index}`}
				border={border}
				index={index}
				width={props.width}
			>
				{detail.Leap1A}
			</Table.td>
		));
	};

	const renderBRows = (details, partNumber) => {
		return details.map((detail, index) => (
			<Table.td
				key={`${partNumber}-detail-${index}`}
				border={border}
				index={index}
				width={props.width}
			>
				{detail.Leap1B}
			</Table.td>
		));
	};

	const renderCRows = (details, partNumber) => {
		return details.map((detail, index) => (
			<Table.td
				key={`${partNumber}-detail-${index}`}
				border={border}
				index={index}
				width={props.width}
			>
				{detail.Leap1C}
			</Table.td>
		));
	};

	const renderPartDescriptionRows = (details, partNumber) => {
		return details.map((detail, index) => (
			<Table.td
				fontSize={remToDp(1.05)}
				key={`${partNumber}-detail-${index}`}
				border={border}
				index={index}
				width={props.width}
			>
				{detail.partDescription}
			</Table.td>
		));
	};
	const renderStatusRows = (details, partNumber) => {
		return details.map((detail, index) => (
			<Table.td
				key={`${partNumber}-detail-${index}`}
				border={border}
				index={index}
				width={props.width}
			>
				{detail.PartStatusCode}
			</Table.td>
		));
	};

	const renderReplacedByRows = (details, partNumber) => {
		return (
			<Table.td border={border} width={props.width}>
				{details}
			</Table.td>
		);
	};

	const renderLastRevisionRows = (details, partNumber) => {
		return details.map((detail, index) => (
			<Table.td
				key={`${partNumber}-detail-${index}`}
				border={border}
				index={index}
				width={props.width}
			>
				{detail.LastRevision}
			</Table.td>
		));
	};

	const renderSelectRows = (item, index) => {
		const isInReviewList = reviewList.some(
			(reviewItem) => reviewItem.id === item.id
		);

		return (
			<Table.td
				key={`-detail-${index}`}
				border={border}
				index={index}
				width={props.width}
			>
				<CheckBox
					checked={isInReviewList}
					checkedColor="#007AFF"
					onPress={() => {
						if (isInReviewList) {
							removeToolFromReviewListAction(item);
						} else {
							addToolToReviewListAction(item);
						}
					}}
					checkedIcon={
						<FontAwesome5
							name="check-square"
							size={remToDp(1.5)}
							color="#007AFF"
						/>
					}
					iconType="material-community"
					uncheckedIcon={"checkbox-blank-outline"}
					containerStyle={{ backgroundColor: "none" }}
				/>
			</Table.td>
		);
	};

	const renderEngineRows = (details, partNumber) => {
		return details.map((detail, index) => (
			<Table.td
				key={`${partNumber}-detail-${index}`}
				border={border}
				index={index}
				width={props.width}
			>
				{detail.ENGINE}
			</Table.td>
		));
	};

	return (
		<View>
			<Table
				style={{
					height: `${vhToDp(56)}px`,
					maxHeight: `${vhToDp(56)}px`,
				}}
			>
				<>
					<Table.tr fontFamily="Barlow_600SemiBold">
						{tableHeaders.map((children, index) => (
							<View
								style={{
									width: props.width
										? props.width[index]
										: tableHeaders.length
										? `${100 / tableHeaders.length}%`
										: "auto",
									height: 60,
									maxHeight: 60,
								}}
								key={index}
							>
								<Table.th
									key={index}
									border={border}
									index={index}
									width={
										props.width
											? props.width[index]
											: tableHeaders.length
											? `${100 / tableHeaders.length}%`
											: "auto"
									}
									fontFamily="Barlow_600SemiBold"
								>
									{children}
									{index !== tableHeaders.length - 1 &&
										index == [0] &&
										Platform.OS == "web" && (
											<TouchableOpacity
												type="button"
												onPress={() => handleSort(index)}
												aria-label={`Sort by ${children}`}
											>
												<AntDesign
													name="caretdown"
													size={10}
													color="gray"
													style={
														Platform.OS == "web" && {
															transform:
																sortInfo.sortCol === index
																	? sortInfo.sortDir === "desc"
																		? "rotate(180deg)"
																		: "none"
																	: "rotate(180deg)",
														}
													}
												/>
											</TouchableOpacity>
										)}
								</Table.th>
							</View>
						))}
					</Table.tr>
				</>

				{currentItems &&
					currentItems.length > 0 &&
					currentItems
						.filter((user) =>
							formatStringForComparison(user.partNumber || "").includes(
								formatStringForComparison(deltaListSearchInput || "")
							)
						)
						.map((row, index) => (
							<Table.tr
								key={index}
								striped={striped && index % 2 === 0}
								hover={hover}
							>
								<View
									style={{
										width: "14%",
										height: cellHeight(row.details.length),

										overflow: "hidden",
									}}
								>
									<Table.td border={border} index={index}>
										{row.partNumber}
									</Table.td>
								</View>

								<View
									style={{
										width: "16%",

										height: cellHeight(row.details.length),
										overflow: "hidden",
									}}
								>
									{renderDetailRows(row.details, row.partNumber)}
								</View>
								{/* <View
									style={{
										width: "4%",
										height: cellHeight(row.details.length),

										overflow: "hidden",
									}}
								>
									{renderARows(row.details, row.partNumber)}
								</View>
								<View
									style={{
										width: "4%",
										height: cellHeight(row.details.length),
										overflow: "hidden",
									}}
								>
									{renderBRows(row.details, row.partNumber)}
								</View>
								<View
									style={{
										width: "4%",
										height: cellHeight(row.details.length),
										overflow: "hidden",
									}}
								>
									{renderCRows(row.details, row.partNumber)}
								</View> */}
								<View
									style={{
										width: "15%",
										height: cellHeight(row.details.length),
										overflow: "hidden",
									}}
								>
									{renderEngineRows(row.details, row.partNumber)}
								</View>

								<View
									style={{
										width: "33%",
										height: cellHeight(row.details.length),
										overflow: "hidden",
									}}
								>
									{renderPartDescriptionRows(row.details, row.partNumber)}
								</View>

								<View
									style={{
										width: "9%",
										height: cellHeight(row.details.length),
										overflow: "hidden",
									}}
								>
									{renderStatusRows(row.details, row.partNumber)}
								</View>

								<View
									style={{
										width: "10%",
										height: cellHeight(row.details.length),
										overflow: "hidden",
									}}
								>
									{renderReplacedByRows(
										row.details[0].ReplacedBy,
										row.partNumber
									)}
								</View>

								{/* <View
									style={{
										width: "10%",
										height: cellHeight(row.details.length),
										overflow: "hidden",
									}}
								>
									{renderLastRevisionRows(row.details, row.partNumber)}
								</View> */}

								<View
									style={{
										width: "4%",
									}}
								>
									{renderSelectRows(row, index)}
								</View>
							</Table.tr>
						))}
			</Table>

			<View
				style={{
					width: "100%",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "flex-end",
					padding: 10,
				}}
			>
				<TouchableOpacity
					onPress={handlePrevPage}
					disabled={currentGroupedPage === 1}
				>
					<AntDesign
						name="left"
						size={20}
						color={currentGroupedPage === 1 ? "gray" : "black"}
					/>
				</TouchableOpacity>
				<Text style={styles.pageInfo}>
					Page {currentGroupedPage} of {totalPages}
				</Text>
				<TouchableOpacity
					onPress={handleNextPage}
					disabled={currentGroupedPage === totalPages}
				>
					<AntDesign
						name="right"
						size={20}
						color={currentGroupedPage === totalPages ? "gray" : "black"}
					/>
				</TouchableOpacity>
			</View>
		</View>
	);
}

export default GroupedList;

const styles = StyleSheet.create({
	paginationContainer: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "flex-end",
		// padding: 10,
		// marginTop: 10,
		margin: 10,
		// marginTop: 100,
	},
	pageInfo: {
		marginHorizontal: 10,
	},
});
