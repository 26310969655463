import { axios } from ".";

const messagesAxios = axios.create({
	baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}/messages`,
});

function sendPushNotificationMessagesAPICall(data) {
	return messagesAxios({ method: "post", url: "/send", data });
}

export { messagesAxios, sendPushNotificationMessagesAPICall };
