import React, { useState } from 'react';
import Button from '../../presentational/Button';
import Modal from '../../presentational/Modal';
import Dropdown from '../../presentational/Dropdown';

function DropdownButton({
  choices = [],
  onPress,
  onChange,
  children,

  ...props
}) {
  const [showModal, setShowModal] = useState(false);
  return (
    <Dropdown
      {...props}
      style={{ width: '100%', justifyContent: 'space-between' }}
    >
      <Button color="transparent" onPress={onPress}>
        {children}
      </Button>

      <Dropdown.Toggle
        color="transparent"
        onPress={() =>
          props.setShowModal ? props.setShowModal() : setShowModal(true)
        }
      />

      <Modal isVisible={showModal} onClose={() => setShowModal(false)}>
        {choices.map((option, index) => (
          <Dropdown.Item
            key={index}
            onPress={() => {
              setShowModal(false);
              onChange(option);
            }}
          >
            {option}
          </Dropdown.Item>
        ))}
      </Modal>
    </Dropdown>
  );
}

export default DropdownButton;
