import { useStoreActions, useStoreState } from "easy-peasy";

function useGetTenantInfoByPartNumberModal(partNumber) {
	const {
		tool: { tenantInfoByPartNumberModalVisible, tenantInfoByPartNumberList },
	} = useStoreState((state) => state);

	const {
		setTenantInfoByPartNumberModalVisibleAction,
		getTenantInfoByPartNumberThunk,
	} = useStoreActions((actions) => actions);

	return {
		visible: tenantInfoByPartNumberModalVisible,
		list: tenantInfoByPartNumberList,
		onRequestClose: () => setTenantInfoByPartNumberModalVisibleAction(false),
		title: `Tenants with Part Number: ${partNumber}`,
	};
}

export { useGetTenantInfoByPartNumberModal };
