import { useStoreState } from "easy-peasy";
import List from "../../presentational/List";
import { FontAwesome5 } from "@expo/vector-icons";
import Icon from "../../presentational/Icon";
import Card from "../../presentational/Card";
import { useNavigation } from "@react-navigation/native";
import { formatStringForComparison } from "../../../utilities/string";

function EngineFamilyList(props) {
	const { engineFamilyList, engineFamilySearchInput } = useStoreState(
		(state) => state.file
	);
	// partNumber partDescription SupersededReplacedBy
	return (
		<Card>
			<List
				tableHeaders={["Engine Family"]}
				tableRows={engineFamilyList
					.filter((user) =>
						formatStringForComparison(user.EngineFamily).includes(
							formatStringForComparison(engineFamilySearchInput)
						)
					)
					.map((role) => [role.EngineFamily])}
				{...props}
			/>
		</Card>
	);
}

export default EngineFamilyList;
