function groupMasterToolList(list) {
  let organizedData = [];

  list.forEach((item) => {
    // Split all comma-separated properties
    const engines = item.ENGINE ? item.ENGINE.split(",") : [""];
    const ismilitaryFlags = item.ismilitaryFlag
      ? item.ismilitaryFlag.split(",")
      : [];
    const overrideTSPartNumbers = item.overridePartNumber
      ? item.overridePartNumber.split(",")
      : [];
    const overrideTSCodes = item.overrideTSCode
      ? item.overrideTSCode.split(",")
      : [];
    const overrideQualification = item.overridequalification
      ? item.overridequalification.split(",")
      : [];

    engines.forEach((engine, index) => {
      const engineVariantIds = item.enginevariantid
        ? item.enginevariantid.split(",")
        : [];

      const newItem = {
        ...item,
        ENGINE: engine.trim(),
        ismilitaryFlag: ismilitaryFlags[index] || "",
        overridePartNumber: overrideTSPartNumbers[index] || "",
        overrideTSCode: overrideTSCodes[index] || "",
        overridequalification: overrideQualification[index] || "",
        enginevariantsingularid: engineVariantIds[index],
      };

      // Attempt to find an existing group by partNumber and partDescription
      let group = organizedData.find(
        (g) =>
          g.partNumber === newItem.partNumber &&
          g.partDescription === newItem.partDescription &&
          // add engine family to the comparison
          g.EngineFamilyID === newItem.EngineFamilyID
      );
      if (!group) {
        // If no group exists, create a new one
        group = {
          id: newItem.id,
          basepartNumber: item.basepartNumber,
          OEMID: item.OEMID,
          OEM: newItem.oem,
          oemdescription: newItem.oemdescription,
          partNumber: newItem.partNumber,
          partDescription: newItem.partDescription,
          TSCode: newItem.TSCode,

          ENGINE: item.ENGINE,
          EngineFamilyID: item.EngineFamilyID,
          QualifiedRevision: item.QualifiedRevision,
          SupersededReplacedby: item.SupersededReplacedby,
          SupersededReplacedbyTSCode: item.SupersededReplacedbyTSCode,
          SupersededReplacedbyQualification:
            item.SupersededReplacedbyQualification,
          FandFCode: item.FnFCode,
          LastRevision: item.LastRevision,
          Comments: item.Comments,
          ServiceBulletin: item.ServiceBulletin,
          internalComments: item.internalComments,
          EngineVariant: item.ENGINE,
          overridePartNumber: item.overridePartNumber,
          overrideTSCode: item.overrideTSCode,
          overridequalification: item.overridequalification,
          engines: [], // This will store engine specific variations
          numofpages: item.numofpages,
        };
        organizedData.push(group);
      }

      // Check if there is already an engine group within the part number and description group
      let engineGroup = group.engines.find((e) => e.engine === engine);
      if (!engineGroup) {
        // If no engine group exists, create it
        engineGroup = {
          engine,
          ...newItem,
        };
        group.engines.push(engineGroup);
      } else {
        // If engine group exists but potentially needs updating
        engineGroup = {
          ...engineGroup,
          ...newItem,
        };
      }
    });
  });

  return organizedData;
}

export { groupMasterToolList };
