import { useStoreActions } from "easy-peasy";
import Button from "../../../presentational/Button";
import { useNavigation } from "@react-navigation/native";

function UpsertRoleButton({ children, ...props }) {
  const navigation = useNavigation();
  const { upsertRoleThunk } = useStoreActions((actions) => actions);
  return (
    <Button fullWidth onPress={() => upsertRoleThunk(navigation)} {...props}>
      {children}
    </Button>
  );
}

export default UpsertRoleButton;
