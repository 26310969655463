import Text from "../Text";

function ToolStatusDisplayText({ children, style, ...props }) {
  return (
    <Text
      style={{
        fontSize: 24,
        margin: 10,
        fontFamily: "Barlow_600SemiBold",
        ...style,
      }}
      fontFamily="Barlow_600SemiBold"
      {...props}
    >
      {children}
    </Text>
  );
}

export default ToolStatusDisplayText;
