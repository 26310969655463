import { useStoreState } from "easy-peasy";
import List from "../../presentational/List";
import { FontAwesome5 } from "@expo/vector-icons";
import Icon from "../../presentational/Icon";
import Card from "../../presentational/Card";
import { useNavigation } from "@react-navigation/native";
import { formatStringForComparison } from "../../../utilities/string";

function ExceptionToolList(props) {
	const { exceptionList, exceptionListSearchInput } = useStoreState(
		(state) => state.tool
	);
	// partNumber partDescription SupersededReplacedBy
	return (
		<Card>
			<List
				width={["20%", "60%", "20%"]}
				tableHeaders={["Part Number", "Part Description", "Replaced By"]}
				tableRows={exceptionList
					.filter(
						(user) =>
							formatStringForComparison(user.partNumber).includes(
								formatStringForComparison(exceptionListSearchInput)
							) ||
							formatStringForComparison(user.partDescription).includes(
								formatStringForComparison(exceptionListSearchInput)
							) ||
							formatStringForComparison(user.SupersededReplacedBy).includes(
								formatStringForComparison(exceptionListSearchInput)
							)
					)
					.map((role) => [
						role.partNumber,
						role.partDescription,
						role.SupersededReplacedBy,
					])}
				{...props}
			/>
		</Card>
	);
}

export default ExceptionToolList;
