import { StyleSheet, TouchableOpacity } from "react-native";
import React from "react";
import { height, width } from "../../utilities/general";

const DropdownOverlay = ({ onPress, backgroundColor }) => {
	const defaults = {
		backgroundColor: backgroundColor || "rgba(0,0,0,0.4)",
	};
	return (
		<TouchableOpacity
			activeOpacity={1}
			onPress={onPress}
			style={{
				...styles.dropdownOverlay,
				...{
					backgroundColor: defaults.backgroundColor,
				},
			}}
		/>
	);
};

export default DropdownOverlay;

const styles = StyleSheet.create({
	dropdownOverlay: {
		position: "absolute",
		height: height,
		width: width,
	},
});
