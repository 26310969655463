import { ActivityIndicator, View } from "react-native";
import Gap from "../Gap";
import ToolStatusDisplayText from "./ToolStatusDisplayText";

function ToolStatusDisplay({
  children,
  progressPercentage,
  successNum,
  failedNum,
  totalNum,
  isReconnectingToSignalR,
}) {
  const failedTextStyle = { color: "#ab2328" };
  return (
    <>
      <ToolStatusDisplayText
        style={{
          fontSize: 36,
        }}
      >
        {progressPercentage}%
      </ToolStatusDisplayText>

      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        <ToolStatusDisplayText>
          Successful:
          <ToolStatusDisplayText
            style={{
              color: "#50C878",
            }}
          >
            {successNum}
          </ToolStatusDisplayText>
        </ToolStatusDisplayText>

        <ToolStatusDisplayText>
          Failed:
          <ToolStatusDisplayText style={failedTextStyle}>
            {failedNum}
          </ToolStatusDisplayText>
        </ToolStatusDisplayText>

        <ToolStatusDisplayText>
          Remaining:{" "}
          <ToolStatusDisplayText style={{ color: "#0088ce" }}>
            {totalNum - successNum - failedNum}
          </ToolStatusDisplayText>
        </ToolStatusDisplayText>

        <ToolStatusDisplayText>
          Total: <ToolStatusDisplayText>{totalNum}</ToolStatusDisplayText>
        </ToolStatusDisplayText>
      </View>

      <Gap />
      <Gap />

      <ActivityIndicator size="large" color="#0088ce" />

      {!totalNum && (
        <>
          <Gap />
          <ToolStatusDisplayText>
            Getting total number of tools
          </ToolStatusDisplayText>
        </>
      )}

      {isReconnectingToSignalR && (
        <>
          <Gap />
          <ToolStatusDisplayText style={failedTextStyle}>
            Reconnecting to Azure SignalR. Please wait.
          </ToolStatusDisplayText>
        </>
      )}

      {children}
    </>
  );
}
export default ToolStatusDisplay;
