import { useStoreActions, useStoreState } from 'easy-peasy';
import List from '../../presentational/List';
import { FontAwesome5, Ionicons } from '@expo/vector-icons';
import Icon from '../../presentational/Icon';
import Card from '../../presentational/Card';
import { useNavigation } from '@react-navigation/native';
import { formatStringForComparison } from '../../../utilities/string';
import Text from '../../presentational/Text';
import Td from '../../presentational/Table/Td';
import { View } from 'react-native';
import { CheckBox } from '@rneui/themed';
import { remToDp } from '../../../utilities/responsive';

function LeapList(props) {
  const { list, searchInput, configFilter, reviewList } = useStoreState(
    (state) => state.tool
  );
  const { addToolToReviewListAction, removeToolFromReviewListAction } =
    useStoreActions((actions) => actions);

  const navigation = useNavigation();

  return (
    <Card>
      <List
        pageSize={7}
        width={['10%', '12%', '45%', '10%', '13%', '10%']}
        tableHeaders={[
          'Engine',
          'Part Number',
          'Part Description',
          'Status Code',
          'Replaced By',
          'Actions',
        ]}
        tableRows={
          configFilter !== 'All'
            ? list
                .filter((tool) =>
                  formatStringForComparison(tool.ENGINE).includes(
                    formatStringForComparison(configFilter || searchInput)
                  ) ||
                  formatStringForComparison(tool.partNumber).includes(
                    formatStringForComparison(configFilter || searchInput)
                  ) ||
                  formatStringForComparison(tool.partDescription).includes(
                    formatStringForComparison(configFilter || searchInput)
                  ) ||
                  formatStringForComparison(tool.PartStatusCode).includes(
                    formatStringForComparison(configFilter || searchInput)
                  ) ||
                  formatStringForComparison(tool.ReplacedBy).includes(
                    formatStringForComparison(configFilter || searchInput)
                  )
                    ? tool
                    : null
                )
                .map((item) => [
                  <Text
                    style={{
                      fontWeight: 'bold',
                      color:
                        item.ColorCode === 'R'
                          ? '#ab2328'
                          : item.ColorCode === 'Y'
                          ? '#c0b000'
                          : 'green',
                    }}
                  >
                    {item.ENGINE}
                  </Text>,
                  item.partNumber,
                  item.partDescription,
                  item.PartStatusCode,
                  item.ReplacedBy,
                  <View
                    style={{
                      flex: 1,
                      flexDirection: 'row',
                      alignContent: 'center',
                      justifyContent: 'center',
                      alignItems: 'center',
                      bottom: 10,
                    }}
                  >
                    <CheckBox
                      checked={reviewList.includes(item)}
                      checkedColor="#007AFF"
                      onPress={() => {
                        if (reviewList && reviewList.includes(item)) {
                          removeToolFromReviewListAction(item);
                        } else {
                          addToolToReviewListAction(item);
                        }
                      }}
                      checkedIcon={
                        <FontAwesome5
                          name="check-square"
                          size={remToDp(1.5)}
                          color="#007AFF"
                        />
                      }
                      iconType="material-community"
                      uncheckedIcon={'checkbox-blank-outline'}
                      containerStyle={{
                        backgroundColor: 'none',
                        alignSelf: 'center',
                      }}
                    />
                  </View>,
                ])
            : list.map((item) => [
                <Text
                  style={{
                    fontWeight: 'bold',
                    color:
                      item.ColorCode === 'R'
                        ? 'red'
                        : item.ColorCode === 'Y'
                        ? '#c0b000'
                        : 'green',
                  }}
                >
                  {item.ENGINE}
                </Text>,
                item.partNumber,
                item.partDescription,
                item.PartStatusCode,
                item.ReplacedBy,
                <View style={{ flex: 1, flexDirection: 'row', bottom: 10 }}>
                  <CheckBox
                    checked={reviewList && reviewList.includes(item)}
                    checkedColor="#007AFF"
                    onPress={() => {
                      if (reviewList && reviewList.includes(item)) {
                        removeToolFromReviewListAction(item);
                      } else {
                        addToolToReviewListAction(item);
                      }
                    }}
                    checkedIcon={
                      <FontAwesome5
                        name="check-square"
                        size={remToDp(1.5)}
                        color="#007AFF"
                      />
                    }
                    iconType="material-community"
                    uncheckedIcon={'checkbox-blank-outline'}
                    containerStyle={{ backgroundColor: 'none' }}
                  />
                </View>,
              ])
        }
        {...props}
      />
    </Card>
  );
}

export default LeapList;
