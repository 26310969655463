import { thunk } from "easy-peasy";
import {
	getMasterTableColumnsAPICall,
	uploadLeapChangeFileApiCall,
	uploadLeapMasterFileApiCall,
	upsertFileDifferencesApiCall,
} from "../../api/leap";
import {
	getFileMappingAPICall,
	getFileVersionNumberAPICall,
	uploadMasterToolFileToAzureBlobStorage,
} from "../../api/file";
import { connectToSignalRHub } from "../../utilities/azure";
import { ApiError, DefaultError } from "@cfbs/js-utility";
import moment from "moment";
import Papa from "papaparse";
import { Platform } from "react-native";

const exportFailedToolsDataToCSV = (data = []) => {
	const csv = Papa.unparse(data);

	if (Platform.OS === "web") {
		const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
		const url = URL.createObjectURL(blob);
		const link = document.createElement("a");
		link.href = url;
		link.setAttribute(
			"download",
			`${moment(new Date()).format("MM-DD-YYYY hh:mm:ss")}FAILED_TOOLS.csv`
		);
		document.body.appendChild(link);
		link.click();
		link.parentNode.removeChild(link);
	}
};

const thunks = {
	uploadLeapMasterFileThunk: thunk(async (actions, file, helpers) => {
		actions.setCRTLImportingAction(true);

		const connection = await connectToSignalRHub(
			process.env.REACT_APP_BACKEND_BASE_URL,
			helpers.getState().user.authenticatedUser.email,
			{
				onreconnecting: (error) =>
					actions.setIsReconnectingToAzureSignalRAction(true),
				onreconnected: (connectionId) =>
					actions.setIsReconnectingToAzureSignalRAction(false),
			}
		);

		try {
			const response = await uploadMasterToolFileToAzureBlobStorage({
				base64: file.base64,
				filename: file.name,
				mimeType:
					"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
				sheetName: helpers.getState().leap.engineModel,
			});
			actions.setTotalCRTLToolsToImportAction(response.data);

			actions.setIsPreparingControlFileAction(true);
			const v = await getFileVersionNumberAPICall({
				OEMID: helpers.getState().leap.uploadType,
				OEMFileName: file.name,
				EngineFamilyID: helpers.getState().leap.uploadEngineModel,
			});
			actions.setIsPreparingControlFileAction(false);

			connection.on("controlFileUpload", (processedTool) => {
				actions.processCRTLImportAction(processedTool);
			});

			let currentRowParsingIndex = 1;
			let hasFinishedUploading = false;
			let totalToolsImported = 0;
			let totalToolsFailed = 0;

			while (hasFinishedUploading === false) {
				const res = await uploadLeapMasterFileApiCall({
					type: helpers
						.getState()
						.file.oemList.find(
							(e) => e.OEMID == helpers.getState().leap.uploadType
						).OEM,
					mimeType:
						"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
					name: file.name,
					engineModel: helpers.getState().leap.engineModel,
					OEMID: helpers.getState().leap.uploadType,
					EngineFamilyID: helpers.getState().leap.uploadEngineModel,
					signalRMethodName: "controlFileUpload",
					currentRowParsingIndex,
					numToolsPerBatch: 100,
					sheetName: helpers.getState().leap.engineModel,
					versionNumber: v.data,
					totalToolsImported,
					totalToolsFailed,
					totalTools: response.data,
				});
				currentRowParsingIndex = res.data.currentRowParsingIndex;
				hasFinishedUploading = res.data.hasFinishedUploading;
				totalToolsImported = res.data.totalToolsImported;
				totalToolsFailed = res.data.totalToolsFailed;
			}

			if (totalToolsFailed > 0) {
				exportFailedToolsDataToCSV(
					helpers.getState().leap.failedCRTLImportedToolsList
				);
			}

			actions.setMasterLeapFileSelectionModalVisibleAction(false);
			await actions.upsertLeapFileDifferencesThunk(
				helpers.getState().leap.uploadType
			);
			file.navigation.navigate("Tool Delta");
			await actions.getToolListDeltasThunk(helpers.getState().leap.uploadType);
			await actions.getMissingToolsThunk();

			actions.setAlertThunk({
				type: "success",
				message: "File uploaded successfully",
			});
		} catch (err) {
			console.error(err);

			let error = new DefaultError(DefaultError.message(err));

			if (ApiError.isApiError(err))
				error = new ApiError(ApiError.default(err), err.response.status);

			actions.setAlertThunk({
				type: "error",
				message:
					error.message ||
					"An error occurred while uploading master tools from csv file",
			});
		}
		actions.setReviewListAction([]);
		actions.resetCRTLImportAction();
		actions.setCRTLImportingAction(false);

		if (connection) {
			connection.stop();
		}
	}),

	upsertLeapFileDifferencesThunk: thunk(async (actions, file, helpers) => {
		actions.setLoadingAction(true);

		try {
			// let r = await upsertFileDifferencesApiCall({
			// 	OEM: file,
			// 	EngineModel: helpers.getState().leap.engineModel,
			// });

			await upsertFileDifferencesApiCall({
				OEMID: file,
				EngineFamilyID: helpers.getState().leap.uploadEngineModel,
			});

			actions.setAlertThunk({
				type: "success",
				message: "File differences uploaded successfully",
			});

			await actions.getToolListDeltasThunk(file);
			await actions.getMissingToolsThunk();
		} catch (err) {
			actions.setAlertThunk({
				type: "error",
				message: err.response.data,
			});
		}

		actions.setLoadingAction(false);
	}),

	getLeapFileChangesThunk: thunk(async (actions, file, helpers) => {
		actions.setLoadingAction(true);

		try {
			let r = await getFileMappingAPICall({
				OEMID: helpers.getState().file.fileChangesInput.OEMID,
				engFamilyID: helpers.getState().file.fileChangesInput.engFamilyID,
			});
			actions.setLeapFileChangesListAction(r.data);
		} catch (err) {
			console.log(err);
			actions.setAlertThunk({
				type: "error",
				message: err.response.data,
			});
		}

		actions.setLoadingAction(false);
	}),

	getTableColumnNamesThunk: thunk(async (actions, file, helpers) => {
		actions.setLoadingAction(true);

		try {
			let r = await getMasterTableColumnsAPICall();
			actions.setTableColumnNamesListAction(r.data);
		} catch (err) {
			console.log(err);
			actions.setAlertThunk({
				type: "error",
				message: err.response.data,
			});
		}

		actions.setLoadingAction(false);
	}),
};

export default thunks;
