import { useStoreState } from "easy-peasy";
import { ActivityIndicator, ScrollView } from "react-native";
import Center from "../../presentational/Center";
import { StatusBar } from "expo-status-bar";
import Alerts from "../Alerts";
import { useEffect, useState } from "react";
import Text from "../../presentational/Text";

function Page({ children, ...props }) {
	const [visible, setVisible] = useState(false);
	const { isMaster } = props;
	const { loading } = useStoreState((state) => state.status);
	// const loading = true;
	const [showLoadingMessage, setShowLoadingMessage] = useState(false);
	const [loadingStartTime, setLoadingStartTime] = useState(null);

	useEffect(() => {
		if (loading) {
			setLoadingStartTime(new Date());
		} else {
			setLoadingStartTime(null);
			setShowLoadingMessage(false);
		}
	}, [loading]);

	useEffect(() => {
		let timer;
		if (loadingStartTime) {
			timer = setTimeout(() => {
				// if (isMaster == true) {
				// 	setVisible(true);
				// } else {
				setShowLoadingMessage(true);
				// }
			}, 5000);
		}

		return () => clearTimeout(timer);
	}, [loadingStartTime, visible]);

	// step 1 of the dynamic loading. If isMaster is true, then the loading is for the master list and we
	// want to show a dynamic loading screen. Otherwise show what we have now.

	return (
		<ScrollView {...props}>
			<StatusBar style="auto" />
			<Alerts />
			{loading ? (
				<Center.Screen>
					{showLoadingMessage && (
						<Text
							style={{
								fontFamily: "Barlow_600SemiBold",
								fontSize: 30,
								position: "absolute",
								top: "35%",
							}}
						>
							This could take a few moments...
						</Text>
					)}

					<ActivityIndicator size="large" color="#0088ce" />
				</Center.Screen>
			) : (
				children
			)}
		</ScrollView>
	);
}

export default Page;
