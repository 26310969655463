import { useStoreActions, useStoreState } from "easy-peasy";
import CustomModal from "../../presentational/Modal";
import Button from "../Button";
import Col from "../../presentational/Col";
import * as DocumentPicker from "expo-document-picker";
import Input from "../../presentational/Input";

export default function UploadSelectionModal({ ...props }) {
  const {
    leap: {
      masterLeapFileSelectionModalVisible,
      uploadType,
      uploadEngineModel,
    },
    file: { oemList, engineFamilyList },
  } = useStoreState((state) => state);
  const {
    setMasterLeapFileSelectionModalVisibleAction,
    setLeapEngineModelAction,
    uploadLeapMasterFileThunk,
    setUploadTypeAction,
    setUploadEngineModelAction,
  } = useStoreActions((actions) => actions);

  return (
    <CustomModal
      style={{ width: 500 }}
      width="50%"
      height="50%"
      title="Upload CRTL File"
      onClose={() => setMasterLeapFileSelectionModalVisibleAction(false)}
      isVisible={masterLeapFileSelectionModalVisible}
    >
      <Col>
        <Input
          width="99%"
          label="OEM"
          placeholder="Enter OEM"
          onSelect={(name) => setUploadTypeAction(name.OEMID)}
          value={
            oemList.find((e) => e.OEMID == uploadType)?.OEM !== undefined
              ? oemList.find((e) => e.OEMID == uploadType).OEM
              : ""
          }
          // uploadType}
          required
          editable={true}
          dropdown
          // dropdownChoices={["GE", "SN"]}
          dropdownChoices={oemList}
          selectedItem={(item) => item.OEM}
          rowTextForSelection={(item) => item.OEM}
          buttonTextAfterSelection={(item) => item.OEM}
        />
      </Col>

      <Col>
        <Input
          width="99%"
          label="Engine Model"
          placeholder="Enter Engine Model"
          onSelect={(name) => {
            setUploadEngineModelAction(name.EngineFamilyID);
            setLeapEngineModelAction(name.EngineFamily);
          }}
          value={
            engineFamilyList.find((e) => e.EngineFamilyID == uploadEngineModel)
              ?.EngineFamily !== undefined
              ? engineFamilyList.find(
                  (e) => e.EngineFamilyID == uploadEngineModel
                ).EngineFamily
              : ""
          }
          // uploadEngineModel}
          required
          editable={true}
          dropdown
          dropdownChoices={engineFamilyList}
          selectedItem={(item) => item.EngineFamily}
          rowTextForSelection={(item) => item.EngineFamily}
        />
      </Col>
      <Col>
        <Button
          color="#00205B"
          fullWidth
          disabled={uploadType == ""}
          style={{ marginTop: 20 }}
          onPress={async () => {
            const document = await DocumentPicker.getDocumentAsync({
              type: "json/csv",
            });

            const b64 = document.uri.split(",")[1];

            uploadLeapMasterFileThunk({
              ...document,
              base64: b64,
              navigation: props.navigation,
            });
            props.navigation.closeDrawer();
          }}
        >
          Upload
        </Button>
      </Col>
    </CustomModal>
  );
}
