import { StoreProvider } from "easy-peasy";
import AppDrawerNavigator from "./src/components/navigation/AppDrawerNavigator";
import store from "./src/store";
import "react-native-gesture-handler";
import { useFonts } from "expo-font";
import {
	Barlow_100Thin,
	Barlow_100Thin_Italic,
	Barlow_200ExtraLight,
	Barlow_200ExtraLight_Italic,
	Barlow_300Light,
	Barlow_300Light_Italic,
	Barlow_400Regular,
	Barlow_400Regular_Italic,
	Barlow_500Medium,
	Barlow_500Medium_Italic,
	Barlow_600SemiBold,
	Barlow_600SemiBold_Italic,
	Barlow_700Bold,
	Barlow_700Bold_Italic,
	Barlow_800ExtraBold,
	Barlow_800ExtraBold_Italic,
	Barlow_900Black,
	Barlow_900Black_Italic,
} from "@expo-google-fonts/barlow";
import { axiosInterceptor } from "./src/utilities/api";
import { authAxios } from "./src/api/auth";
import { userAxios } from "./src/api/user";
import { leapAxios } from "./src/api/leap";
import axios from "axios";
import { roleAxios } from "./src/api/role";
import { toolAxios } from "./src/api/tools";
import { fileAxios } from "./src/api/file";
import { integrationAxios } from "./src/api/integration";
import { messagesAxios } from "./src/api/messages";
import { workscopeAxios } from "./src/api/workscope";

export default function App() {
	const [fontsLoaded] = useFonts({
		Barlow_100Thin,
		Barlow_100Thin_Italic,
		Barlow_200ExtraLight,
		Barlow_200ExtraLight_Italic,
		Barlow_300Light,
		Barlow_300Light_Italic,
		Barlow_400Regular,
		Barlow_400Regular_Italic,
		Barlow_500Medium,
		Barlow_500Medium_Italic,
		Barlow_600SemiBold,
		Barlow_600SemiBold_Italic,
		Barlow_700Bold,
		Barlow_700Bold_Italic,
		Barlow_800ExtraBold,
		Barlow_800ExtraBold_Italic,
		Barlow_900Black,
		Barlow_900Black_Italic,
	});

	axiosInterceptor(axios, store);
	axiosInterceptor(authAxios, store);
	axiosInterceptor(userAxios, store);
	axiosInterceptor(leapAxios, store);
	axiosInterceptor(roleAxios, store);
	axiosInterceptor(toolAxios, store);
	axiosInterceptor(fileAxios, store);
	axiosInterceptor(integrationAxios, store);
	axiosInterceptor(messagesAxios, store);
	axiosInterceptor(workscopeAxios, store);
	return (
		<StoreProvider store={store}>
			{fontsLoaded && <AppDrawerNavigator />}
		</StoreProvider>
	);
}
