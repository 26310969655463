import { useStoreActions, useStoreState } from "easy-peasy";
import CustomModal from "../../presentational/Modal";
import Button from "../Button";
import Row from "../../presentational/Row";
import Col from "../../presentational/Col";
import Input from "../../presentational/Input";

export default function EditMasterToolEngineModal({ index }) {
  const {
    tool: {
      editMasterToolEngineModalVisible,
      updateMasterToolEngineInput,
      selectedMasterTool,
    },
    user: { authenticatedUser },
  } = useStoreState((state) => state);

  const {
    setEditMasterToolEngineModalVisibleAction,
    setUpdateMasterToolEngineInputAction,
    adjustEngineItemAction,
    removeEngineItemAction,
  } = useStoreActions((actions) => actions);

  return (
    <CustomModal
      style={{ width: 800 }}
      width="50%"
      height="50%"
      title="Edit Engine(s)"
      onClose={() => setEditMasterToolEngineModalVisibleAction(false)}
      isVisible={editMasterToolEngineModalVisible}
    >
      <Col>
        <Input
          width="99%"
          label="Engine Model"
          placeholder="Enter Engine Model"
          onChangeText={(engine) =>
            setUpdateMasterToolEngineInputAction({ engine })
          }
          value={updateMasterToolEngineInput.engine}
          editable={true}
        />
      </Col>
      <Col>
        <Input
          width="99%"
          label="Override Part Number"
          placeholder="Enter Part Number  "
          onChangeText={(overrideTSPartNumber) =>
            setUpdateMasterToolEngineInputAction({ overrideTSPartNumber })
          }
          value={updateMasterToolEngineInput.overrideTSPartNumber}
          editable={true}
        />
      </Col>
      <Col>
        <Input
          width="99%"
          label="Override TSCode"
          placeholder="Enter Override TSCode"
          onChangeText={(overrideTSCode) =>
            setUpdateMasterToolEngineInputAction({ overrideTSCode })
          }
          value={updateMasterToolEngineInput.overrideTSCode}
          editable={true}
        />
      </Col>
      <Row>
        {authenticatedUser.role === "admin" && (
          <Col xs={12} md={6}>
            <Button
              color="#ab2328"
              fullWidth
              style={{ marginTop: 20 }}
              onPress={async () => {
                setEditMasterToolEngineModalVisibleAction(false);
                removeEngineItemAction({
                  index: index,
                  updates: { ...updateMasterToolEngineInput },
                });
              }}
              disabled={
                selectedMasterTool.engines.length === 1 &&
                !selectedMasterTool.engines[0].ENGINE
              }
            >
              Remove
            </Button>
          </Col>
        )}
        <Col xs={12} md={authenticatedUser.role === "admin" ? 6 : 12}>
          <Button
            color="#0088CE"
            fullWidth
            style={{ marginTop: 20 }}
            onPress={async () => {
              setEditMasterToolEngineModalVisibleAction(false);
              adjustEngineItemAction({
                index: index,
                updates: { ...updateMasterToolEngineInput },
              });
            }}
          >
            Save
          </Button>
        </Col>
      </Row>
    </CustomModal>
  );
}
