import Col from "../../../presentational/Col";
import Row from "../../../presentational/Row";
import Input from "../../../presentational/Input";
import { useStoreActions, useStoreState } from "easy-peasy";
import Switch from "../../../presentational/Switch";
import Form from "../Form";

function AddEngineFamilyForm({ buttons = [], ...props }) {
	const {
		file: { upsertEngineFamilyInput },
	} = useStoreState((state) => state);

	const { setUpsertEngineFamilyInputAction } = useStoreActions(
		(actions) => actions
	);

	return (
		<Form {...props}>
			<Row>
				<Col>
					<Input
						label="Engine Family Name"
						placeholder="Type Engine Family Name"
						onChangeText={(enginefamilydesc) =>
							setUpsertEngineFamilyInputAction({ enginefamilydesc })
						}
						value={upsertEngineFamilyInput.enginefamilydesc}
						required
					/>
				</Col>

				{buttons.map((button, index) => (
					<Col xs={12} md={12 / buttons.length} key={index}>
						{button}
					</Col>
				))}
			</Row>
		</Form>
	);
}

export default AddEngineFamilyForm;
