import { View, Platform } from "react-native";
import styled from "styled-components/native";
import Row from "../../presentational/Row";
import Button from "../../containers/Button";
import Col from "../../presentational/Col";
import Page from "../../containers/Page";
import SearchBar from "../../presentational/SearchBar";
import React, { useState, useEffect } from "react";
import Gap from "../../presentational/Gap";
import { useIsFocused } from "@react-navigation/native";
import { useStoreActions, useStoreState } from "easy-peasy";
import * as DocumentPicker from "expo-document-picker";
import moment from "moment";
import Papa from "papaparse";
import { connectToSignalRHub } from "../../../utilities/azure";
import {
  completeMasterToolFileUpload,
  uploadMasterToolFileToAzureBlobStorage,
} from "../../../api/file";
import ToolStatusDisplay from "../../presentational/ToolStatusDisplay";
import { ApiError, DefaultError } from "@cfbs/js-utility";
import List from "../../functional/List";
import Card from "../../presentational/Card";
import { useMasterToolListControlForm } from "../../../hooks/master-tool";
import Input from "../../presentational/Input";

const Container = styled(View)`
  padding: 2vh 4vw;
`;

const SRow = styled(Row)`
  align-items: center;
`;

function LeapMasterListPage({ navigation, ...props }) {
  const {
    engineModelInput,
    oemInput,
    button: { children: buttonChildren, ...buttonProps },
    searchBar,
    ...inputProps
  } = useMasterToolListControlForm(navigation);

  const {
    user: { authenticatedUser },
    azure: { isReconnectingToSignalR },
  } = useStoreState((state) => state);

  const {
    getMasterToolListThunk,
    getEngineFamilyListThunk,
    setIsReconnectingToAzureSignalRAction,
    setAlertThunk,
    exportMasterToolsToCSVThunk,
    getUserAccessControlThunk,
  } = useStoreActions((actions) => actions);

  const isFocused = useIsFocused();
  const [importing, setImporting] = useState(false);
  const [finished, setFinished] = useState(false);
  const [successfulImports, setSuccessfulImports] = useState(0);
  const [failedImports, setFailedImports] = useState(0);
  const [failedImportedToolsList, setFailedImportedToolsList] = useState([]);
  const [totalToolsToImport, setTotalToolsToImport] = useState(0); // Set this based on your initial data
  const [totalToolsProcessed, setTotalToolsProcessed] = useState(0);

  useEffect(() => {
    if (isFocused) {
      getMasterToolListThunk({});
      getEngineFamilyListThunk();
      getUserAccessControlThunk();
    }
  }, [isFocused]);

  const exportFailedToolsDataToCSV = (data = []) => {
    const csv = Papa.unparse(data);

    if (Platform.OS === "web") {
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${moment(new Date()).format("MM-DD-YYYY hh:mm:ss")}FAILED_TOOLS.csv`
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
  };

  const processEvents = (event) => {
    // Update upload status
    setSuccessfulImports(event.totalToolsImported);
    setFailedImports(event.totalToolsFailed);
    setTotalToolsProcessed(event.totalToolsImported + event.totalToolsFailed);

    if (!event.success) {
      setFailedImportedToolsList((prev) => [...prev, event.tool]);
    }
  };
  useEffect(() => {
    if (finished && failedImportedToolsList.length > 0) {
      exportFailedToolsDataToCSV(failedImportedToolsList);
    }
  }, [failedImportedToolsList, finished]);

  // Calculate progress as a percentage
  const progressPercentage = (totalToolsProcessed / totalToolsToImport) * 100;

  // Ensure to check for division by zero when calculating progressPercentage
  const safeProgressPercentage = isFinite(progressPercentage)
    ? progressPercentage.toFixed(2)
    : 0;

  const resetImporting = () => {
    setSuccessfulImports(0);
    setFailedImports(0);
    setTotalToolsToImport(0);
    setTotalToolsProcessed(0);
  };

  return (
    <>
      {importing ? (
        <ToolStatusDisplay.Container>
          <ToolStatusDisplay
            progressPercentage={safeProgressPercentage}
            successNum={successfulImports}
            failedNum={failedImports}
            totalNum={totalToolsToImport}
            isReconnectingToSignalR={isReconnectingToSignalR}
          />
        </ToolStatusDisplay.Container>
      ) : (
        <>
          <Page.Protected {...props}>
            <Container>
              <SRow>
                <Col xs={12} md={2}>
                  <Input {...inputProps} {...engineModelInput} />
                </Col>
                <Col xs={12} sm={2}>
                  <Input {...inputProps} {...oemInput} />
                </Col>
                <Col xs={12} sm={2}>
                  <Button {...buttonProps}>{buttonChildren}</Button>
                </Col>
                <Col xs={12} sm={6}>
                  <SearchBar {...searchBar} />
                </Col>
              </SRow>

              <Gap />

              <Card>
                <List.MasterTool />
              </Card>

              <Gap />
            </Container>
          </Page.Protected>

          <View
            style={{
              position: "absolute",
              right: 20,
              bottom: 20,
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <Button
              style={{
                backgroundColor: "#e8e9ea",
              }}
              color="#0088CE"
              onPress={async () => {
                try {
                  const document = await DocumentPicker.getDocumentAsync({
                    type: "text/csv",
                  });
                  const b64 = document.uri.split(",")[1];

                  setImporting(true);

                  const connection = await connectToSignalRHub(
                    process.env.REACT_APP_BACKEND_BASE_URL,
                    authenticatedUser.email,
                    {
                      onreconnecting: (error) =>
                        setIsReconnectingToAzureSignalRAction(true),
                      onreconnected: (connectionId) =>
                        setIsReconnectingToAzureSignalRAction(false),
                    }
                  );

                  connection.on("masterToolCsv", (uploadData) => {
                    processEvents(uploadData);
                  });

                  const uploadResponse =
                    await uploadMasterToolFileToAzureBlobStorage({
                      base64: b64,
                      filename: document.name,
                      mimeType: "text/csv",
                    });
                  setTotalToolsToImport(uploadResponse.data);

                  let currentRowParsingIndex = 1;
                  let hasFinishedUploading = false;
                  let totalToolsImported = 0;
                  let totalToolsFailed = 0;

                  while (hasFinishedUploading === false) {
                    const res = await completeMasterToolFileUpload({
                      name: document.name,
                      signalRMethodName: "masterToolCsv",
                      currentRowParsingIndex,
                      numToolsPerBatch: 100,
                      totalToolsImported,
                      totalToolsFailed,
                    });

                    hasFinishedUploading = res.data.hasFinishedUploading;
                    currentRowParsingIndex = res.data.currentRowParsingIndex;
                    totalToolsImported = res.data.totalToolsImported;
                    totalToolsFailed = res.data.totalToolsFailed;

                    const { data } = res;
                    if (data.hasFinishedUploading === true) {
                      setFinished(true);
                      if (failedImportedToolsList.length > 0) {
                        exportFailedToolsDataToCSV(failedImportedToolsList);
                      }
                      if (connection) {
                        await connection.stop();
                      }
                      break;
                    }
                  }

                  getMasterToolListThunk({});
                  setAlertThunk({
                    type: "success",
                    message: "Tools uploaded successfully",
                  });
                } catch (err) {
                  console.error(err);

                  let error = new DefaultError(DefaultError.message(err));

                  if (ApiError.isApiError(err))
                    error = new ApiError(
                      ApiError.default(err),
                      err.response.status
                    );

                  setAlertThunk({
                    type: "error",
                    message:
                      error.message ||
                      "An error occurred while uploading master tools from csv file",
                  });
                }

                setImporting(false);
                resetImporting();
              }}
            >
              Import Tool CSV
            </Button>
            <Gap />
            <Button onPress={() => exportMasterToolsToCSVThunk()}>
              Generate Tool CSV
            </Button>
          </View>
        </>
      )}
    </>
  );
}

export default LeapMasterListPage;
