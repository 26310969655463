import { useStoreActions } from "easy-peasy";
import Button from "../../../presentational/Button";

function SaveConfigurationButton({ children = "Review", ...props }) {
	const { setReviewListModalActive } = useStoreActions((actions) => actions);
	return (
		<Button 
		
		onPress={() => setReviewListModalActive(true)} fullWidth {...props}>
			{children}
		</Button>
	);
}

export default SaveConfigurationButton;
