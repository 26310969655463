import styled from "styled-components/native";
import { TouchableOpacity } from "react-native";

const DropdownItem = styled(TouchableOpacity)`
  justify-content: center;
  flex-direction: row;
  padding: 16px;
  width: 100%;
  border-bottom-width: 1px;
`;

export default DropdownItem;
