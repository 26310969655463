import { useStoreState } from "easy-peasy";
import List from "../../presentational/List";
import { FontAwesome5 } from "@expo/vector-icons";
import Icon from "../../presentational/Icon";
import Card from "../../presentational/Card";
import { useNavigation } from "@react-navigation/native";
import { formatStringForComparison } from "../../../utilities/string";
import Text from "../../presentational/Text";

function WorkScopeList(props) {
	const { list, searchInput } = useStoreState((state) => state.role);

	const navigation = useNavigation();

	return (
		<Card>
			{/* <List
        tableHeaders={['OldTool1', 'NewTool1', 'Change Reason Text']}
        tableRows={[['oldTool', 'newTool', 'bad tool']]}
        {...props}
      /> */}
			<Text style={{ textAlign: "center", fontSize: 20, fontWeight: "bold" }}>
				WORK IN PROGRESS
			</Text>
		</Card>
	);
}

export default WorkScopeList;
