import { action } from "easy-peasy";

const actions = {
	setLoadingAction: action((state, loading) => {
		state.status.loading = loading;
	}),
	addAlertAction: action((state, alert) => {
		state.status.alerts = [...state.status.alerts, alert];
	}),
	removeAlertAction: action((state, id) => {
		state.status.alerts = state.status.alerts.filter(
			(alert) => alert.id !== id
		);
	}),
	setAreYouSureModalVisibleAction: action((state, visible) => {
		state.status.areYouSureModalVisible = visible;
	}),
	setCurrentPage: action((state, page) => {
		state.status.currentPage = page;
	}),
	setCurrentGroupedPage: action((state, page) => {
		state.status.currentGroupedPage = page;
	}),
};

export default actions;
