import { action } from "easy-peasy";
import { v4 as uuidv4 } from "uuid"; // make sure to install 'uuid'

const actions = {
  setFileListAction: action((state, payload) => {
    state.file.list = payload;
  }),
  setFileChangesInputAction: action((state, payload) => {
    state.file.fileChangesInput = {
      ...state.file.fileChangesInput,
      ...payload,
    };
  }),
  setOEMListAction: action((state, payload) => {
    state.file.oemList = payload;
  }),
  setFileExitConfirmationModalVisibleAction: action((state, payload) => {
    state.file.fileExitWarningModalVisible = payload;
  }),

  addToolToMissingToolReviewListAction: action((state, tool) => {
    if (typeof tool === "object" && tool !== null) {
      // tool.id = state.tool.reviewList.length + 1;
      tool.id = uuidv4();
      state.file.missingReviewList.push(tool);
    } else {
      console.error('Expected "tool" to be an object, but got:', tool);
    }
  }),

  setUpsertOEMHeadersInputAction: action((state, payload) => {
    state.file.upsertOEMHeadersInput = {
      ...state.file.upsertOEMHeadersInput,
      ...payload,
    };
  }),

  removeToolFromMissingToolReviewListAction: action((state, tool) => {
    state.file.missingReviewList = state.file.missingReviewList.filter(
      (item) => item.id !== tool.id
    );
  }),
  setEngineFamilyListAction: action((state, payload) => {
    state.file.engineFamilyList = payload;
  }),
  setEngineVariantListAction: action((state, payload) => {
    state.file.engineVariantList = payload;
  }),

  setEngineFamilySearchInputAction: action((state, payload) => {
    state.file.engineFamilySearchInput = payload;
  }),
  setEngineVariantSearchInputAction: action((state, payload) => {
    state.file.engineVariantSearchInput = payload;
  }),
  setOEMToEngineFamilyListAction: action((state, payload) => {
    state.file.oemToEngineFamilyList = payload;
  }),
  setOEMToEngineFamilySearchInputAction: action((state, payload) => {
    state.file.oemToEngineFamilySearchInput = payload;
  }),

  setOEMToEngineFamilyInputAction: action((state, payload) => {
    state.file.oemToEngineFamilyInput = {
      ...state.file.oemToEngineFamilyInput,
      ...payload,
    };
  }),

  setUpsertOEMInputAction: action((state, payload) => {
    state.file.upsertOEMInput = {
      ...state.file.upsertOEMInput,
      ...payload,
    };
  }),

  setOemToFamilyCurrentStepAction: action((state, payload) => {
    state.file.oemToFamilyCurrentStep = payload;
  }),
  setEditOemToFamilyCurrentStepAction: action((state, payload) => {
    state.file.oemToFamilyEditCurrentStep = payload;
  }),

  setUpsertEngineFamilyInputAction: action((state, payload) => {
    state.file.upsertEngineFamilyInput = {
      ...state.file.upsertEngineFamilyInput,
      ...payload,
    };
  }),

  setUpsertEngineVariantInputAction: action((state, payload) => {
    state.file.upsertEngineVariantInput = {
      ...state.file.upsertEngineVariantInput,
      ...payload,
    };
  }),

  setOEMToFamilyInputAction: action((state, payload) => {
    state.file.upsertOEMToFamilyInput = {
      ...state.file.upsertOEMToFamilyInput,
      ...payload,
    };
  }),

  setUpdateOEMToEngineFamilyInputAction: action((state, payload) => {
    state.file.updateOEMToEngineFamilyInput = {
      ...state.file.updateEngineFamilyInput,
      ...payload,
    };
  }),

  setOEMFileHeadersAction: action((state, payload) => {
    state.file.oemFileHeaders = payload;
  }),

  setEditHeadersIndexAction: action((state, payload) => {
    state.file.editHeadersIndex = payload;
  }),

  setMergeAllAreYouSureModalVisibleAction: action((state, payload) => {
    state.file.mergeAllAreYouSureModalVisible = payload;
  }),

  setDeleteFileChangesRowAreYouSureModalVisibleAction: action(
    (state, payload) => {
      state.file.deleteFileChangesRowAreYouSureModalVisible = payload;
    }
  ),
  //

  setIsComparingColumnFlagsOemEngineAction: action((state, flags) => {
    state.file.isComparingColumnFlagsOemEngine = flags;
  }),
  setChosenColumnToSortIndexOemEngineAction: action((state, index) => {
    state.file.chosenColumnToSortIndexOemEngine = index;
  }),
  setFilteredOemEngineListAction: action((state, list) => {
    state.file.filteredOemEngineList = list;
  }),

  setFilterOemEngineValuesAction: action((state, list) => {
    state.file.filterOemEngineValues = list;
  }),

  setChosenOemEngineComparisonIndexesAction: action((state, list) => {
    state.file.chosenOemEngineComparisonIndexes = list;
  }),
  setIsUploadingFileAction: action((state, isUploading) => {
    state.file.isUploadingFile = isUploading;
  }),
};

export default actions;
