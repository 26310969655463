import Page from "../../containers/Page";
import React from "react";

import { View } from "react-native";
import styled from "styled-components/native";

import Row from "../../presentational/Row";

import AddOEMToFamilyStepForm from "../../containers/Form/EngineFamilyForm/AddOEMStepForm";

const Container = styled(View)`
	padding: 2vh 4vw;
`;

const SRow = styled(Row)`
	align-items: center;
`;

function AddOEMFamilyManagementPage({ ...props }) {
	return (
		<Page.Protected>
			<Container>
				<AddOEMToFamilyStepForm />
			</Container>
		</Page.Protected>
	);
}

export default AddOEMFamilyManagementPage;
