const firstPageNum = 1;

function useTableFilter({
  headers,
  list,
  updateHeaderFunction,
  reloadListByPageNumberFunction,
  currPageNumber,
  setPageNumberFunction,
}) {
  function sortList(list) {
    let sortedList = list.slice();

    function sort(firstValue, secondValue) {
      function valueForComparison(value) {
        return (value || "").trim().toLowerCase();
      }
      return valueForComparison(firstValue).localeCompare(
        valueForComparison(secondValue)
      );
    }

    const headerWithSort = headers
      .flatMap((header) => header.subheaders || [header])
      .find((header) => header.isSortAscending || header.isSortDescending);

    if (headerWithSort) {
      const { propertyName, isSortAscending } = headerWithSort;
      sortedList.sort((itemA, itemB) =>
        isSortAscending
          ? sort(itemA[propertyName], itemB[propertyName])
          : sort(itemB[propertyName], itemA[propertyName])
      );
    }

    return sortedList;
  }

  const totalPageNum = list.length ? list[0].numofpages : firstPageNum;

  return {
    filteredList: sortList(list),
    headers,
    pageNumber: currPageNumber,
    totalPageNum,
    filterEvents: {
      onShowMenu: (parentHeaderIndex, subheaderIndex) => () =>
        updateHeaderFunction({
          updatedTableHeader: {
            showMenu: true,
          },
          parentHeaderIndex,
          subheaderIndex,
        }),
      onHideMenu: (parentHeaderIndex, subheaderIndex) => () =>
        updateHeaderFunction({
          updatedTableHeader: {
            showMenu: false,
          },
          parentHeaderIndex,
          subheaderIndex,
        }),
      onPressClearFilter: (parentHeaderIndex, subheaderIndex) => () => {
        updateHeaderFunction({
          updatedTableHeader: {
            showMenu: false,
            isSortAscending: false,
            isSortDescending: false,
            filterValue: "",
            isCheckingEqual: false,
            pickedEqualCheck: false,
            isCheckingContains: false,
            pickedContainsCheck: false,
          },
          parentHeaderIndex,
          subheaderIndex,
        });
        reloadListByPageNumberFunction(1);
      },
      onPressSortAscending: (parentHeaderIndex, subheaderIndex) => () => {
        updateHeaderFunction({
          updatedTableHeader: {
            isSortAscending: true,
            isSortDescending: false,
            showMenu: false,
          },
          parentHeaderIndex,
          subheaderIndex,
        });
        reloadListByPageNumberFunction(currPageNumber);
      },
      onPressSortDescending: (parentHeaderIndex, subheaderIndex) => () => {
        updateHeaderFunction({
          updatedTableHeader: {
            isSortAscending: false,
            isSortDescending: true,
            showMenu: false,
          },
          parentHeaderIndex,
          subheaderIndex,
        });
        reloadListByPageNumberFunction(currPageNumber);
      },
      onPressEquals: (parentHeaderIndex, subheaderIndex) => () =>
        updateHeaderFunction({
          updatedTableHeader: {
            pickedEqualCheck: true,
            pickedContainsCheck: false,
          },
          parentHeaderIndex,
          subheaderIndex,
        }),
      onPressContains: (parentHeaderIndex, subheaderIndex) => () =>
        updateHeaderFunction({
          updatedTableHeader: {
            pickedEqualCheck: false,
            pickedContainsCheck: true,
          },
          parentHeaderIndex,
          subheaderIndex,
        }),
      onChangeFilterInput: (parentHeaderIndex, subheaderIndex) => (text) =>
        updateHeaderFunction({
          updatedTableHeader: {
            filterValue: text,
          },
          parentHeaderIndex,
          subheaderIndex,
        }),
      onPressOk: (header, parentHeaderIndex, subheaderIndex) => () => {
        updateHeaderFunction({
          updatedTableHeader: {
            isCheckingEqual: header.pickedEqualCheck,
            isCheckingContains: header.pickedContainsCheck,
            showMenu: false,
          },
          parentHeaderIndex,
          subheaderIndex,
        });
        reloadListByPageNumberFunction(1);
      },
    },
    onPressFirstPage: () => reloadListByPageNumberFunction(firstPageNum),
    onPressPreviousPage: () =>
      reloadListByPageNumberFunction(currPageNumber - 1),
    onPressNextPage: () => reloadListByPageNumberFunction(currPageNumber + 1),
    onPressLastPage: () => reloadListByPageNumberFunction(totalPageNum),
    onChangePageNum: (text) => {
      const currPageNum = Math.min(
        parseInt(text) || firstPageNum,
        totalPageNum
      );
      setPageNumberFunction(Math.max(firstPageNum, currPageNum));
    },
    onEnterPageNum: () => reloadListByPageNumberFunction(currPageNumber),
  };
}

export { useTableFilter };
