import { useStoreActions } from "easy-peasy";
import Button from "../../../presentational/Button";

function LoginButton({ children = "Login", ...props }) {
  const { loginThunk } = useStoreActions((actions) => actions);

  return (
    <Button onPress={() => loginThunk()} fullWidth {...props}>
      {children}
    </Button>
  );
}

export default LoginButton;
