import Page from "../../containers/Page";
import React, { useEffect } from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import ToolDeltaList from "../../containers/List/ToolDeltaList";
import Button from "../../containers/Button/index";
import ReviewToolsModal from "../../containers/Modal/ReviewToolsModal";
import { useIsFocused } from "@react-navigation/native";
import { useStoreActions, useStoreState } from "easy-peasy";
import Row from "../../presentational/Row";
import MissingToolsList from "../../containers/List/MissingToolsList";
import Switch from "../../presentational/Switch";
import Gap from "../../presentational/Gap";
import Input from "../../presentational/Input";
import SearchBar from "../../presentational/SearchBar";
import Col from "../../presentational/Col";
import AreYouSureModal from "../../containers/Modal/AreYouSureModal";

const Container = styled(View)`
  padding: 2vh 4vw;
`;

const SRow = styled(Row)`
  align-items: center;
`;

function ToolDeltaListPage(props) {
  const {
    leap: { uploadEngineModel, uploadType },
    tool: {
      areYouSureReviewModalVisible,
      deltaListSearchInput,
      missingListSearchInput,
      areYouSureRemoveModalVisible,
      areYouSureAddModalVisible,
      reviewList,
      showMissingList,
      groupedList,
    },
    file: { oemList, engineFamilyList, mergeAllAreYouSureModalVisible },
  } = useStoreState((state) => state);
  const {
    getToolListDeltasThunk,
    getMissingToolsThunk,
    setDeltaToolListSearchInputAction,
    setAreYouSureReviewModalVisibleAction,
    setAreYouSureRemoveModalVisibleAction,
    setAreYouSureReviewAddVisibleAction,
    removeToolFromDeltaListThunk,
    addToolToMasterListThunk,
    mergeToolToMasterThunk,
    setReviewListModalActive,
    setMissingToolListSearchInputAction,
    setLeapEngineModelAction,
    setUploadEngineModelAction,
    setUploadTypeAction,
    setMergeAllAreYouSureModalVisibleAction,
    mergeAllToolDeltasToMasterThunk,
    setShowMissingListAction,
  } = useStoreActions((actions) => actions);
  const isFocused = useIsFocused();

  useEffect(() => {
    if (isFocused) {
      showMissingList &&
        getMissingToolsThunk(uploadType == "All" ? "" : uploadType);
      getToolListDeltasThunk(uploadType == "All" ? "" : uploadType);
    }
  }, [isFocused, uploadType, uploadEngineModel, showMissingList]);

  return (
    <Page.Protected {...props}>
      <Container>
        <ReviewToolsModal />
        <AreYouSureModal
          confirmationPhrase={"Are you sure you want to merge this tool?"}
          onClose={() => {
            setAreYouSureReviewModalVisibleAction(false);
          }}
          isVisible={areYouSureReviewModalVisible}
          onSubmit={() => {
            mergeToolToMasterThunk();
          }}
        />
        <AreYouSureModal
          confirmationPhrase={"Are you sure you want to remove this tool?"}
          onClose={() => {
            setAreYouSureRemoveModalVisibleAction(false);
          }}
          onSubmit={() => {
            removeToolFromDeltaListThunk();
            setAreYouSureRemoveModalVisibleAction(false);
          }}
          isVisible={areYouSureRemoveModalVisible}
        />
        <AreYouSureModal
          confirmationPhrase={"Are you sure you want to add this tool?"}
          onSubmit={() => {
            addToolToMasterListThunk();
            setAreYouSureReviewAddVisibleAction(false);
          }}
          onClose={() => {
            setAreYouSureReviewAddVisibleAction(false);
          }}
          isVisible={areYouSureAddModalVisible}
        />

        <AreYouSureModal
          confirmationPhrase={
            "Are you sure you want to merge all of these tool?"
          }
          onSubmit={() => {
            // mergeAllToMasterThunk();
            mergeAllToolDeltasToMasterThunk();
            setMergeAllAreYouSureModalVisibleAction(false);
          }}
          onClose={() => {
            setMergeAllAreYouSureModalVisibleAction(false);
          }}
          isVisible={mergeAllAreYouSureModalVisible}
        />
        <SRow>
          <Col xs={12} sm={3}>
            <Input
              width="99%"
              label="OEM"
              placeholder="Enter Leap Type"
              onSelect={(name) => {
                setUploadTypeAction(name.OEMID);
              }}
              value={
                oemList.find((e) => e.OEMID == uploadType)?.OEM !== undefined
                  ? oemList.find((e) => e.OEMID == uploadType).OEM
                  : ""
              }
              required
              editable={true}
              dropdown
              dropdownChoices={[...oemList]}
              selectedItem={(item) => item.OEM}
              rowTextForSelection={(item) => item.OEM}
            />
          </Col>
          <Col xs={12} sm={3}>
            <Input
              width="99%"
              label="Engine Model"
              placeholder="Enter Engine Model"
              onSelect={(name) => {
                setLeapEngineModelAction(name.EngineFamily);
                setUploadEngineModelAction(name.EngineFamilyID);
              }}
              value={
                engineFamilyList.find(
                  (e) => e.EngineFamilyID == uploadEngineModel
                )?.EngineFamily !== undefined
                  ? engineFamilyList.find(
                      (e) => e.EngineFamilyID == uploadEngineModel
                    ).EngineFamily
                  : ""
              }
              required
              editable={true}
              dropdown
              dropdownChoices={engineFamilyList}
              selectedItem={(item) => item.EngineFamily}
              rowTextForSelection={(item) => item.EngineFamily}
            />
          </Col>
          <Col xs={12} sm={3}>
            <Switch
              label="Show Missing Tools"
              value={showMissingList}
              onValueChange={(active) => setShowMissingListAction(active)}
            />
          </Col>
          <Col xs={12} sm={3}>
            <SearchBar
              onChangeText={(searchInput) => {
                if (!showMissingList) {
                  setDeltaToolListSearchInputAction(searchInput);
                } else {
                  setMissingToolListSearchInputAction(searchInput);
                }
              }}
              value={
                !showMissingList ? deltaListSearchInput : missingListSearchInput
              }
              placeholder="Search tools by name"
            />
          </Col>
        </SRow>
        <Gap />
        {!showMissingList ? <ToolDeltaList /> : <MissingToolsList />}
        <Gap />
        <Row>
          <Col xs={12} sm={!showMissingList ? 8 : 12}>
            <Button
              disabled={reviewList && reviewList.length === 0}
              onPress={() => setReviewListModalActive(true)}
              fullWidth
              {...props}
            >
              Review
            </Button>
          </Col>
          {!showMissingList && (
            <Col xs={12} sm={4}>
              <Button
                color="#0088CE"
                disabled={groupedList.length === 0}
                onPress={() => setMergeAllAreYouSureModalVisibleAction(true)}
                fullWidth
                {...props}
              >
                Merge All
              </Button>
            </Col>
          )}
        </Row>
      </Container>
    </Page.Protected>
  );
}

export default ToolDeltaListPage;
