import ClientConfigToolList from "./ClientConfigToolList";
import GroupedList from "./GroupedList";
import List from "./List";
import MasterToolList from "./MasterToolList";
import PushNotificationList from "./PushNotificationList";

List.Grouped = GroupedList;
List.ClientConfigTool = ClientConfigToolList;
List.MasterTool = MasterToolList;
List.PushNotification = PushNotificationList;

export default List;
