import Col from "../../../presentational/Col";
import Row from "../../../presentational/Row";
import Input from "../../../presentational/Input";
import { useStoreActions, useStoreState } from "easy-peasy";
import Form from "../Form";
import Button from "../../../presentational/Button";
import MasterToolEngineVariantList from "../../List/MasterToolEngineVariantList";
import { useNavigation } from "@react-navigation/native";

function EditMasterToolForm({ buttons = [], ...props }) {
  const {
    tool: { masterToolInput },
    file: { oemList, engineFamilyList },
  } = useStoreState((state) => state);

  const {
    setMasterToolInputAction,
    upsertMasterToolThunk,
    removeEngineFromMasterToolThunk,
  } = useStoreActions((actions) => actions);
  const navigation = useNavigation();

  return (
    <Form {...props}>
      <Row>
        <Col xs={12} md={6} lg={2.4}>
          <Input
            width="99%"
            label="OEM"
            placeholder="Enter OEM"
            onSelect={(name) =>
              setMasterToolInputAction({
                OEMID: name.OEMID,
              })
            }
            value={
              oemList.find((item) => item.OEMID == masterToolInput.OEMID)?.OEM
            }
            required
            editable={true}
            dropdown
            dropdownChoices={oemList}
            selectedItem={(item) => item.OEM}
            rowTextForSelection={(item) => item.OEM}
            buttonTextAfterSelection={(item) => item.OEM}
          />
        </Col>

        <Col xs={12} md={6} lg={2.4}>
          <Input
            width="99%"
            label="Engine Family"
            placeholder="Enter Engine Family"
            onSelect={(name) =>
              setMasterToolInputAction({
                EngineFamilyID: name.EngineFamilyID,
              })
            }
            value={
              engineFamilyList.find(
                (item) => item.EngineFamilyID == masterToolInput.EngineFamilyID
              )?.EngineFamily
            }
            required
            editable={true}
            dropdown
            dropdownChoices={engineFamilyList}
            selectedItem={(item) => item.EngineFamily}
            rowTextForSelection={(item) => item.EngineFamily}
            buttonTextAfterSelection={(item) => item.EngineFamily}
          />
        </Col>

        <Col xs={12} md={6} lg={2.4}>
          <Input
            label="Part Number"
            placeholder="Type Part Number"
            onChangeText={(partNumber) =>
              setMasterToolInputAction({ partNumber })
            }
            value={masterToolInput.partNumber}
            editable={true}
          />
        </Col>

        <Col xs={12} md={6} lg={2.4}>
          <Input
            label="TS Code"
            placeholder="Type TS Code"
            onChangeText={(TSCode) => setMasterToolInputAction({ TSCode })}
            value={masterToolInput.TSCode}
            editable={true}
          />
        </Col>
        <Col xs={12} md={6} lg={2.4}>
          <Input
            label="Qualified Revision / F and F Code"
            placeholder="Type Qualified Revision"
            editable={true}
            onChangeText={(QualifiedRevision) =>
              setMasterToolInputAction({ QualifiedRevision })
            }
            value={masterToolInput.QualifiedRevision}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={6} lg={6}>
          <Input
            label="Part Description"
            placeholder="Type Part Description"
            onChangeText={(partDescription) =>
              setMasterToolInputAction({ partDescription })
            }
            value={masterToolInput.partDescription}
            editable={true}
          />
        </Col>

        <Col xs={12} md={6} lg={3}>
          <Input
            label="Base Part Number"
            placeholder="Type Base Part Number"
            onChangeText={(basepartNumber) =>
              setMasterToolInputAction({ basepartNumber })
            }
            value={masterToolInput.basepartNumber}
            editable={true}
          />
        </Col>

        <Col xs={12} md={2.5} lg={3}>
          <Input
            label="Superseded Replace By"
            placeholder="Type Superseded Replace By"
            onChangeText={(SupersededReplaceBy) =>
              setMasterToolInputAction({ SupersededReplaceBy })
            }
            value={masterToolInput.SupersededReplaceBy}
            editable={true}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={6}>
          <Input
            label="Last Revision"
            placeholder="Type Last Revision"
            onChangeText={(LastRevision) =>
              setMasterToolInputAction({ LastRevision })
            }
            value={masterToolInput.LastRevision}
            editable={true}
          />
        </Col>

        <Col xs={12} md={6}>
          <Input
            label="Service Bulletin"
            placeholder="Type Service Bulletin"
            onChangeText={(ServiceBulletin) =>
              setMasterToolInputAction({ ServiceBulletin })
            }
            value={masterToolInput.ServiceBulletin}
            editable={true}
          />
        </Col>

        <Col>
          <Input
            label="Internal Comments"
            placeholder="Type Internal Comments"
            onChangeText={(internalComments) =>
              setMasterToolInputAction({ internalComments })
            }
            value={masterToolInput.internalComments}
            editable={true}
          />
        </Col>
        <Col>
          <Input
            label="Comments"
            placeholder="Type Comments"
            onChangeText={(Comments) => setMasterToolInputAction({ Comments })}
            value={masterToolInput.Comments}
            editable={true}
          />
        </Col>
        <Col>
          <MasterToolEngineVariantList />
        </Col>
        <Col>
          <Button
            fullWidth
            onPress={async () => {
              await upsertMasterToolThunk({ navigation });
              await removeEngineFromMasterToolThunk();
              navigation.goBack();
            }}
          >
            Save Changes
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default EditMasterToolForm;
