import Input from "../../presentational/Input";
import { useStoreState } from "easy-peasy";

function EngineFamilyDropdown({ onSelect, includeAll, value, ...props }) {
	const {
		leap: { uploadEngineModel },
		file: { engineFamilyList },
	} = useStoreState((state) => state);

	const dropdownChoices = includeAll
		? [{ EngineFamilyID: "all", EngineFamily: "All" }, ...engineFamilyList]
		: engineFamilyList;

	let defaultValue = "";
	if (value !== undefined) {
		defaultValue = dropdownChoices.find(
			(item) => item.EngineFamilyID === value
		);
	} else if (includeAll && uploadEngineModel === "") {
		defaultValue = "All";
	} else {
		const foundItem = dropdownChoices.find(
			(e) => e.EngineFamilyID === uploadEngineModel
		);
		defaultValue = foundItem ? foundItem.EngineFamily : "";
	}

	return (
		<Input
			width="99%"
			label="Engine Model"
			onSelect={onSelect}
			value={value ?? defaultValue}
			required
			editable={true}
			dropdown
			dropdownChoices={dropdownChoices}
			selectedItem={(item) => item.EngineFamily}
			rowTextForSelection={(item) => item.EngineFamily}
		/>
	);
}

export default EngineFamilyDropdown;
