import { createFilterHeaders } from "../../utilities/filter";

const defaultWorkScopeInput = {
  id: null,
  workscopedescription: "",
  engine: "",
  enginemodel: "",
  technicalmanualreference: "",
  workpackagedescription: "",
  partnumber: "",
  toolnomenclature: "",
  quantity: "",
  wiptool: "",
  usewith: "",
  toolcode: "",
  qualifiedrevision: "",
  ceukca: "",
  toolsfunction: "",
  line: "",
  module: "",
  shop: "",
  repair: "",
  lmtother: "",
  duplicate: "",
  tpmcomments: "",
  dimensions: "",
  weight: "",
  loadtest: "",
  calibration: "",
  wsfilename: "",
};

const state = {
  workscope: {
    input: defaultWorkScopeInput,
    removeFileMappingAsAdminAreYouSureModalVisible: false,
    list: [],
    showUploadWorkScopeFileModal: false,
    workScopeMasterToolListSearchInput: "",
    workScopeMasterToolHeaders: createFilterHeaders([
      {
        columnName: "Technical Workscope",
        propertyName: "workscopedescription",
      },
      {
        columnName: "Part Number",
        propertyName: "partnumber",
      },
      {
        columnName: "Work Package Description",
        propertyName: "workpackagedescription",
        freeze: true,
      },
      {
        columnName: "Engine",
        propertyName: "engine",
      },
      {
        columnName: "Engine Model",
        propertyName: "enginemodel",
      },
      {
        columnName: "Technical Manual Reference",
        propertyName: "technicalmanualreference",
      },
      {
        columnName: "Tool Nomenclature",
        propertyName: "toolnomenclature",
      },
      {
        columnName: "Quantity",
        propertyName: "quantity",
      },
      {
        columnName: "Tool Code",
        propertyName: "toolcode",
      },
      {
        columnName: "Qual. / Validation Status",
        propertyName: "qualifiedrevision",
      },
      {
        columnName: "Replaced By Part Number",
        propertyName: "replacedbypartnumber",
      },
      {
        columnName: "Replaced By Status Code",
        propertyName: "replacedbystatuscode",
      },
      {
        columnName: "Replaced By Qualification Code",
        propertyName: "replacedByqualificationcode",
      },
      {
        columnName: "Date Modified",
        propertyName: "datemodified",
      },
    ]),
    currWorkScopeMasterToolListPageNumber: 1,
    selectionOptions: [],
    engineInput: "",
    engineModelInput: "",
    workScopeNameInput: "",
  },
};

export default state;
