import { useEffect } from "react";
import Form from "../../containers/Form";
import { useStoreActions } from "easy-peasy";
import { useIsFocused } from "@react-navigation/native";
import styled from "styled-components/native";
import { vhToDp, vwToDp } from "../../../utilities/responsive";
import Page from "../../containers/Page";

const Container = styled.View`
	padding: ${vhToDp(2)}px ${vwToDp(4)}px;
`;

function EditWorkScopePage(props) {
	return (
		<Page.Protected>
			<Container>
				<Form.EditWorkScope {...props} />
			</Container>
		</Page.Protected>
	);
}

export default EditWorkScopePage;
