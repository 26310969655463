import { useStoreActions, useStoreState } from "easy-peasy";
import SearchBar from "../../presentational/SearchBar";

function RoleSearchBar(props) {
	const { searchInput } = useStoreState((state) => state.role);
	const { setRoleSearchInputAction } = useStoreActions((actions) => actions);
	return (
		<SearchBar
			onChangeText={(searchInput) => {
				setRoleSearchInputAction(searchInput);
			}}
			value={searchInput}
			placeholder="Search roles by name"
			{...props}
		/>
	);
}

export default RoleSearchBar;
